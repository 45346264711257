import React, { useState, useEffect } from "react"
import "./App.css"
import Header from "./components/header/Header.jsx"
import SideBar from "./components/sidebar/SideBar.jsx"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Summary from "./pages/summary/Summary.jsx"
import Sequencing from "./pages/sequencing/Sequencing.jsx"
import Home from "./pages/home/Home.jsx"
import DataQuality from "./pages/data_quality/DataQuality.jsx"
import AdminPanel from "./pages/admin_panel/AdminPanel.jsx"
import ComparisionScreen from "./pages/comparision_screen/ComparisionScreen"
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  MsalAuthenticationTemplate,
} from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser"
import { loginRequest } from "./authConfig.js"

function App() {
  // const isAuthenticated = useIsAuthenticated()
  // const { instance, inProgress } = useMsal()

  // useEffect(() => {
  //   const itemKey = "msal.interaction.status"
  //   if (sessionStorage.getItem(itemKey)) {
  //     sessionStorage.removeItem(itemKey)
  //   }
  // }, [])

  // useEffect(() => {
  //   const account = instance.getActiveAccount()
  //   console.log("account", account)
  // }, [instance])

  // const { instance, accounts } = useMsal()

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     console.log("fetchToken", fetchToken)
  //     try {
  //       if (accounts.length > 0) {
  //         console.log("account", accounts[0])
  //         let request = {
  //           scopes: ["User.Read"],
  //           account: accounts[0],
  //         }

  //         const response = await instance.acquireTokenSilent(request)
  //         document.cookie = `swire_access_token=${response.accessToken}`
  //         // document.cookie = `swire_user_email=${accounts[0].username}`;
  //       }
  //     } catch (error) {
  //       // acquireTokenSilent can fail for a number of reasons, fallback to interaction
  //       if (error instanceof InteractionRequiredAuthError) {
  //         try {
  //           let request = {
  //             scopes: ["User.Read"],
  //             account: accounts[0],
  //           }
  //           const response = await instance.acquireTokenPopup(request)
  //           document.cookie = `swire_access_token=${response.accessToken}`
  //           // document.cookie = `swire_user_email=${accounts[0].username}`;
  //         } catch (popupError) {
  //           console.error("Error acquiring token:", popupError)
  //         }
  //       } else {
  //         console.error("Error acquiring token:", error)
  //       }
  //     }
  //   }

  //   fetchToken()
  // }, [instance, accounts])

  return (
    <Router>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Routes>
          <Route exact path="/" element={<Home></Home>}></Route>
          <Route path="/summary" element={<Summary></Summary>}></Route>
          <Route path="/sequencing" element={<Sequencing></Sequencing>}></Route>
          <Route
            path="/data_quality"
            element={<DataQuality></DataQuality>}
          ></Route>
          <Route path="/admin_panel" element={<AdminPanel />}></Route>
          <Route path="/comparison_screen" element={<ComparisionScreen/>}></Route>
        </Routes>
      </MsalAuthenticationTemplate>
    </Router>
  )
}

export default App
