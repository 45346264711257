import React, { useState, useEffect } from "react"
import "./SideBar.css"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"
import home_icon from "../../assets/images/home.png"
import summary_icon from "../../assets/images/summary.png"
import sequencing_icon from "../../assets/images/sequencing.png"
import home_active_icon from "../../assets/images/home_active_icon.png"
import summary_active_icon from "../../assets/images/summary_active_icon.png"
import sequencing_active_icon from "../../assets/images/sequencing_active_icon.png"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { sideBarOpen } from "../../reducers/sideBarToggle"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import { setSequencingTableIsUpdated } from "../../reducers/sequencingTableData"
import ChecklistIcon from "@mui/icons-material/Checklist"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { getadminList } from "../../reducers/adminScreen"
import { getCookie } from "../../helper/getCookie"
import { useMsal } from "@azure/msal-react"
import CompareIcon from "@mui/icons-material/Compare"

const SideBar = () => {
  const open = useSelector((state) => state.sidebar.open)
  const [isAdmin, setIsAdmin] = useState(false)
  const isUpdated = useSelector(
    (state) => state.sequencingTable.sequencingTableData.isUpdated,
  )
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toggleSideBar = (bool) => {
    dispatch(sideBarOpen(bool))
    console.log("Open", open)
  }
  const admins = useSelector((state) => state.adminScreen.admin.data)
  const { accounts } = useMsal()
  const userName = accounts[0].username

  useEffect(() => {
    dispatch(getadminList({}))
  }, [])

  useEffect(() => {
    if (userName && admins.length > 0 && admins.includes(userName)) {
      setIsAdmin(true)
    }
    // console.log("userName", userName)
    // console.log("admins", admins)
    // console.log("admins.include(userName) ", admins.includes(userName))
  }, [admins, userName])

  const checkIfSequencingIsUpdated = (page) => {
    // if (!isUpdated) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "",
    //     confirmButtonColor: "#E21824",
    //     showCancelButton: true,
    //     text: "Do you want to save the changes?",
    //   }).then((result) => {
    //     if (!result.isConfirmed) {
    //       dispatch(setSequencingTableIsUpdated(true))
    //       navigate(`/${mappingLinks[page]}`)
    //     }
    //   })
    // } else {
      navigate(`/${mappingLinks[page]}`)
    // }
  }
  useEffect(() => {
    let path = location.pathname.replace("/", "")
    setActive(path)
  }, [location])
  const [active, setActive] = useState("")
  const pages = [
    "Home",
    "Summary",
    "Sequencing",
    "Data Quality",
    "Admin",
    "Comparison",
  ]
  const pages_icon = {
    Home: home_icon,
    Summary: summary_icon,
    Sequencing: sequencing_icon,
    "Data Quality": home_icon,
    "Admin": home_icon,
    "Comparison": home_icon,
  }
  const mappingLinks = {
    Home: "",
    Summary: "summary",
    Sequencing: "sequencing",
    "Data Quality": "data_quality",
    "Admin": "admin_panel",
    "Comparison": "comparison_screen",
  }
  const active_pages_icon = {
    Home: home_active_icon,
    Summary: summary_active_icon,
    Sequencing: sequencing_active_icon,
    "Data Quality": home_active_icon,
    "Admin": home_active_icon,
    "Comparison": home_active_icon,
  }

  return (
    <>
      {open && (
        <div className="sidebar-container">
          <CloseIcon
            onClick={() => toggleSideBar(false)}
            style={{ marginLeft: "auto" }}
          ></CloseIcon>
          <ul style={{ width: "100%", padding: "0" }}>
            {pages.map(
              (page) =>
                (page == "Admin" ? (isAdmin ? true : false) : true) && (
                  <li
                    key={page}
                    onClick={() => checkIfSequencingIsUpdated(page)}
                    className={
                      active === mappingLinks[page] ? "active" : "not_active"
                    }
                  >
                    {page !== "Data Quality" &&
                      page !== "Admin" &&
                      page !=="Comparison" && (
                          <img
                            src={
                              active === mappingLinks[page]
                                ? active_pages_icon[page]
                                : pages_icon[page]
                            }
                            alt={page}
                          ></img>
                        )}
                    {page == "Data Quality" && <ChecklistIcon></ChecklistIcon>}
                    {page == "Admin" && (
                      <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                    )}
                    {page == "Comparison" && <CompareIcon></CompareIcon>}
                    {page}
                  </li>
                ),
            )}
          </ul>
        </div>
      )}
      {!open && (
        <div className="toggle-button" onClick={() => toggleSideBar(true)}>
          <ChevronRightIcon style={{ height: "4vh" }}></ChevronRightIcon>
        </div>
      )}
    </>
  )
}

export default SideBar
