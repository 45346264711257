import React, { useEffect, useState } from "react"
import { Input, Checkbox, Tooltip, Button } from "antd"
import DropDown from "../dropdown/DropDown.jsx"
import retail_plan from "../../assets/images/retail_plan.png"
import DownloadIcon from "@mui/icons-material/Download"
import "./RetailPlanTable.css"
import { cloneDeep } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { updateRetailPlan } from "../../reducers/summaryTableDataUpdate.js"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { visibleCategoryEvenWhenCollapsed } from "../../helper/static.js"

function RetailPlanTable({ version, retailPlanToolTipTitles,promosChecked,changePromosVisibility,showHiddenPromos }) {
  const fullData = useSelector(
    (state) => state.summarytable.retailPlanData.data,
  )
  const metaData = useSelector(
    (state) => state.summarytable.retailPlanMetaPlan.data,
  )
  const guardrail = metaData.guadrail
  const py_margin = metaData.py_margin
  const invoice_price_new = metaData.invoice_price_new
  const invoice_price_current = metaData.invoice_price_current
  const sortedRows = ["New", "Current", "EDV"]
  const promo_multiple = Object.keys(metaData.price_multiple)
  const allowancesDropSDownValues = ["All", "Promo"]
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(true)
  const categoryHidddenWhenVersionIsCustomer = [
    "Trade Allowance per case",
    "Other",
  ]
  const categoryHidddenWhenVersionIsSwire = []
  const legendMapping = [
    "edv",
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
  ]
  const InitialSelection = [
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
  ]
  const backgroundColorsForLegends = {
    P1_A: "#27AF8D",
    P1_B: "#27AF8D",
    P1_C: "#27AF8D",
    P1_D: "#27AF8D",
    P2_A: "#494949",
    P2_B: "#494949",
    P2_C: "#494949",
    P2_D: "#494949",
  }
  const columns = [
    "Category",
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
    "Total",
  ]
 

  const isEditable = {
    "White Tag": true,
    "Retail Multiple": true,
    "Take Rate": true,
    "Volume/Event (Cases)": true,
    "Event Frequency (Wks)": true,
    "Trade Allowance per case": true,
    Other: true,
  }
  const percentOrDollar = {
    "white tag": "($)",
    "retail multiple": "($)",
    price: "($)",
    multiple: "",
    "take rate": "(%)",
    "discount vs edv": "(%)",
    "effective retail": "($)",
    "trade allowance per case": "($)",
    other: "($)",
    invoice: "($)",
    "net invoice cost": "($)",
    "net unit cost": "($)",
    "invoice cost @ 100% take rate": "($)",
    "customer margin ($/unit)": "($)",
    "customer margin": "(%)",
  }
  const checkBoxBgColor = {
    current: "#E2ECFF",
    new: "#FFEFEF",
    edv: "#C6F6EB",
  }
  const sectionsAvailable = {
    "Effective Retail": true,
    "Event Frequency (Wks)": true,
    Other: true,
    "Invoice Cost @ 100% Take Rate": true,
  }

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
    var str = ""

    for (var i = 0; i < array.length; i++) {
      var line = ""
      for (var index in array[i]) {
        if (line !== "") line += ","

        line += array[i][index]
      }

      str += line + "\r\n"
    }
    console.log("str", str)
    return str
  }

  const isGuardrailValueLogic = (row, col) => {
    if (row.Category.toLowerCase() === "retail multiple") {
      let white_tage_data = fullData.find((row) => row.Category === "White Tag")
      if (
        (Number(row[col]) < Number(guardrail) ||
          Number(row[col]) > Number(white_tage_data[col])) &&
        row[col] !== 0
      ) {
        return true
      }
    } else if (row.Category.toLowerCase() == "event frequency (wks)") {
      let retail_multiple = fullData.find(
        (r) => r.Category == "Retail Multiple",
      )
      if (
        Number(retail_multiple[col]) < Number(guardrail) &&
        Number(retail_multiple[col]) !== 0
      ) {
        return Number(metaData.frequency_guardrail) < Number(row[col])
      }
    }
    return false
  }

  function exportCSVFile(headers, items, fileTitle) {
    let dataArray = cloneDeep(items)
    if (version == "Customer") {
      dataArray = dataArray.filter(
        (v) => !categoryHidddenWhenVersionIsCustomer.includes(v.Category),
      )
    }
    dataArray = dataArray.map((it) => {
      let valuesArray = []
      headers.map((h) => valuesArray.push(it[h]))
      return valuesArray
    })
    if (headers) {
      dataArray.unshift(headers)
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(dataArray)

    var csv = convertToCSV(jsonObject)

    var exportedFilenmae = fileTitle + ".csv" || "export.csv"

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      var link = document.createElement("a")
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilenmae)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const onSelectionChange = (e, category, col, index) => {
    let checked = e.target.checked
    let temp_data = cloneDeep(fullData)
    let invoiceIndex = temp_data.findIndex((v) => v.Category === "Invoice")
    let current_ = fullData.find((row) => row.Category === "Current")
    let edv_ = fullData.find((row) => row.Category === "EDV")
    let edvIndex = fullData.findIndex((row) => row.Category === "EDV")
    let isEdvCheckedForCurrent = InitialSelection.find(
      (v) => current_[v] === "true" && edv_[v] === "true",
    )
    let isEdvCheckedForNew = InitialSelection.find(
      (v) => current_[v] === "false" && edv_[v] === "true",
    )
    if (checked) {
      if (category === "EDV") {
        if (isEdvCheckedForCurrent && current_[col] === "true") {
          temp_data[index][isEdvCheckedForCurrent] = "false"
        } else if (isEdvCheckedForNew && current_[col] === "false") {
          temp_data[index][isEdvCheckedForNew] = "false"
        }
        temp_data[index][col] = "true"
      } else if (
        category.toLowerCase() === "current" &&
        isEdvCheckedForCurrent &&
        edv_[col] == "true"
      ) {
        temp_data[index][col] = "true"
        temp_data[edvIndex][col] = "false"
      } else {
        temp_data[index][col] = "true"
      }
      if (category.toLowerCase() === "current") {
        temp_data[invoiceIndex][col] = invoice_price_current
      } else if (category.toLowerCase() === "new") {
        temp_data[invoiceIndex][col] = invoice_price_new
      }
      dispatch(updateRetailPlan(temp_data))
    } else if (!checked) {
      temp_data[index][col] = "false"
      temp_data[invoiceIndex][col] = invoice_price_new
      dispatch(updateRetailPlan(temp_data))
    }
    console.log("category==>", category)
    console.log("col==>", col)
  }

  const customerMarginComparision = (val) => {
    return Number(val.replace("%", "")) < py_margin
  }

  const currentOrNewDisable = (category, col) => {
    if (category === "Current") {
      let new_ = fullData.find((row) => row.Category === "New")
      return new_[col] === "true" ? true : false
    } else if (category === "New") {
      let new_ = fullData.find((row) => row.Category === "Current")
      return new_[col] === "true" ? true : false
    } else if (category === "EDV") {
      let edv_ = fullData.find((row) => row.Category === "EDV")
      let current_ = fullData.find((row) => row.Category === "Current")
      let new_ = fullData.find((row) => row.Category === "Current")
      let isTrue = InitialSelection.find((v) => edv_[v] === "true")
    }
  }

  const setExpandandCollapse = () => {
    let bool = isExpanded
    setIsExpanded(!bool)
  }

  const displayText = [
    "EDV",
    "Current",
    "New",
    "Multiple",
    "Allowances (All/Promo)",
  ]
  const onInputChange = (e, index, col) => {
    let value = e.target.value
    let temp_data = cloneDeep(fullData)
    const regex = /^-?\d*\.?\d*$/
    temp_data[index][col] =
      value == "" ? 0 : regex.test(value) ? value.replace(/^0+(?=\d)/, "") : 0
    dispatch(updateRetailPlan(temp_data))
    console.log("fullData", fullData)
  }

  const checkIfDivisable = (value) => {
    let remainder = (value * 100) % metaData.unit_per_case
    return remainder == 0 ? true : false
  }

  
  const isEveryPromoVisible = () => {
    let temp_promo_checked = cloneDeep(promosChecked)
    for (let key in temp_promo_checked) {
      if (!temp_promo_checked[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    dispatch(updateRetailPlan(fullData))
  }, [fullData])
  return (
    <div
      style={{
        borderRadius: "4px",
        backgroundColor: "white",
        padding: "0.4vw 1vw  1vw 1vw",
      }}
    >
      <div className="retail_plan_container">
        <img
          className="retail_table_header_image"
          src={retail_plan}
          alt="retail_plan"
        />
        <span className="retail_plan_header">Retail Plan</span>
        <div className="legend_container">
          <div className="legend shallow"></div>
          <span>
            <Tooltip title="modest/moderate discount vs. EDV" overlayStyle={{ fontSize: "11px" }}>Shallow</Tooltip>
          </span>
          <div className="legend deep"></div>
          <span>
            <Tooltip title="signifigant discount vs. EDV" overlayStyle={{ fontSize: "11px" }}>Deep</Tooltip>
          </span>
          <Tooltip title="Download">
            <DownloadIcon
              onClick={() => exportCSVFile(columns, fullData, "retail_plan")}
              style={{ color: "#E81C0E", fontSize: "3.9vh" }}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip
           overlayStyle={{ fontSize: "11px" }}
            title={
              isEveryPromoVisible()
                ? "All promos are visible"
                : "Show hidden promos"
            }
          >
            {isEveryPromoVisible() ? (
              <VisibilityIcon
                style={{ color: "#E81C0E", fontSize: "1.5vw" }}
                onClick={showHiddenPromos}
              />
            ) : (
              <VisibilityOffIcon
                style={{ color: "#E81C0E", fontSize: "1.5vw" }}
                onClick={showHiddenPromos}
              />
            )}
          </Tooltip>
          <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
            {isExpanded ? (
              <ExpandLessIcon
                onClick={setExpandandCollapse}
                style={{
                  color: "#E81C0E",
                  marginLeft: "0.5vw",
                  fontSize: "3.9vh",
                }}
              ></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon
                onClick={setExpandandCollapse}
                style={{
                  color: "#E81C0E",
                  marginLeft: "0.5vw",
                  fontSize: "3.9vh",
                }}
              ></ExpandMoreIcon>
            )}
          </Tooltip>
        </div>
      </div>
      {
        <div style={{ overflow: "auto" }}>
          <table
            className="retail_plan_table"
            style={{ width: "100%", height: "100%" }}
          >
            <thead>
              <tr>
                {columns.map(
                  (v, i) =>
                    (promosChecked[v] || v == "Category" || v == "Total") && (
                      <th
                        style={{ borderTop: "4px solid #E6ECF0" }}
                        className={
                          v === "Category" ? "category_label" : "header"
                        }
                        key={v + i}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            justifyCotent: "center",
                            alignItems: "center",
                          }}
                        >
                          {promosChecked[v] && (
                            <VisibilityIcon
                              style={{ color: "#E81C0E", fontSize: "1vw" }}
                              onClick={(e) => {
                                changePromosVisibility(e, v)
                              }}
                            />
                          )}
                          {legendMapping.includes(v.toUpperCase()) && (
                            <>
                              <div
                                style={{
                                  width: "28px",
                                  height: "4px",
                                  borderRadius: "2px",
                                  backgroundColor:
                                    backgroundColorsForLegends[v.toUpperCase()],
                                }}
                              ></div>
                            </>
                          )}
                          <div>{v}</div>
                        </div>
                      </th>
                    ),
                )}
                <th className="guardrall-gap"></th>
                <th className="guardrail_header">Guardrail</th>
              </tr>
            </thead>
            <tbody>
              {fullData.map(
                (row, i) =>
                  (!isExpanded
                    ? visibleCategoryEvenWhenCollapsed.includes(row.Category)
                    : version === "Customer"
                    ? !categoryHidddenWhenVersionIsCustomer.includes(
                        row.Category,
                      )
                    : !categoryHidddenWhenVersionIsSwire.includes(
                        row.Category,
                      )) && (
                    <tr
                      style={{
                        backgroundColor:
                          i === fullData.length - 1 ? "#C2D5FE" : "white",
                      }}
                      key={row.Category + i}
                    >
                      {columns.map(
                        (col, j) =>
                          (promosChecked[col] ||
                            col == "Category" ||
                            col == "Total") && (
                            <td
                              style={{
                                fontWeight:
                                  sortedRows[row.Category] !== -1 &&
                                  col === "Category"
                                    ? "bold"
                                    : i == fullData.length - 1
                                    ? "600"
                                    : "400",
                                backgroundColor:
                                  col === "Total"
                                    ? "#F0F5F8"
                                    : isEditable[row.Category] &&
                                      col !== "Category"
                                    ? "#F4F4F4"
                                    : sortedRows[row.Category] !== -1
                                    ? checkBoxBgColor[
                                        row.Category.toLowerCase()
                                      ]
                                    : "",
                                borderBottom:
                                  i === fullData.length - 1
                                    ? "3px solid #9DB3FF"
                                    : sectionsAvailable[row.Category]
                                    ? "4px solid #9DB3FF"
                                    : version === "Customer" &&
                                      row.Category == "Allowances (All/Promo)"
                                    ? "4px solid #9DB3FF"
                                    : "",
                                color:
                                  row.Category.toLowerCase() ===
                                    "customer margin %" &&
                                  col == "Total" &&
                                  customerMarginComparision(row[col])
                                    ? "#E81C0E"
                                    : i === fullData.length - 1
                                    ? "blue"
                                    : "",
                              }}
                              key={col + i + j}
                            >
                              {sortedRows.indexOf(row.Category) !== -1 &&
                                col !== "Total" &&
                                col !== "Category" && (
                                  <Checkbox
                                    checked={
                                      row[col] === "false" ? false : true
                                    }
                                    // disabled={currentOrNewDisable(row.Category, col)}
                                    onChange={(e) =>
                                      onSelectionChange(e, row.Category, col, i)
                                    }
                                  ></Checkbox>
                                )}
                              {(row.Category === "Multiple" ||
                                row.Category === "Allowances (All/Promo)") &&
                                col !== "Total" &&
                                col !== "Category" && (
                                  <DropDown
                                    promoMultiple={
                                      row.Category === "Multiple"
                                        ? promo_multiple
                                        : allowancesDropSDownValues
                                    }
                                    fullData={fullData}
                                    defaultVal={row[col]}
                                    index={i}
                                    col={col}
                                  ></DropDown>
                                )}
                              {(displayText.indexOf(row.Category) === -1 ||
                                col === "Total" ||
                                col === "Category") &&
                                (!isEditable[row.Category] ||
                                  col === "Total") &&
                                (row.Category.toLowerCase()
                                  .trim()
                                  .includes("volume") && col !== "Category" ? (
                                  Number(row[col]).toLocaleString("en-US")
                                ) : (
                                  <Tooltip
                                    overlayStyle={{
                                      fontSize: "11px",
                                      whiteSpace: "pre-line",
                                      maxWidth: "400px",
                                    }}
                                    title={retailPlanToolTipTitles[row[col]]}
                                  >
                                    {row[col]}
                                  </Tooltip>
                                ))}
                              {!isEditable[row.Category] &&
                                col === "Category" &&
                                percentOrDollar[row[col].toLowerCase()]}
                              {isEditable[row.Category] &&
                                col === "Category" && (
                                  <Tooltip
                                    overlayStyle={{
                                      fontSize: "11px",
                                      whiteSpace: "pre-line",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    {row[col]}
                                  </Tooltip>
                                )}
                              {isEditable[row.Category] &&
                                col !== "Total" &&
                                col !== "Category" && (
                                  <Input
                                    style={{
                                      color: isGuardrailValueLogic(row, col)
                                        ? "#E81C0E"
                                        : col !== "Category" &&
                                          (row.Category == "InVoice" ||
                                            row.Category ==
                                              "Trade Allowance per case") &&
                                          !checkIfDivisable(row[col])
                                        ? "#E81C0E"
                                        : "",
                                      fontWeight: isGuardrailValueLogic(
                                        row,
                                        col,
                                      )
                                        ? "bold"
                                        : "400",
                                    }}
                                    className="input-field"
                                    value={fullData[i][col]}
                                    onChange={(e) => onInputChange(e, i, col)}
                                  ></Input>
                                )}
                              {isEditable[row.Category] &&
                                col === "Category" &&
                                percentOrDollar[row[col].toLowerCase()]}
                            </td>
                          ),
                      )}
                      <td className="guardrall-gap"></td>
                      <td
                        style={{
                          backgroundColor:
                            row.Category.toLowerCase() ===
                            "customer margin ($/unit)"
                              ? "#F4F4F4"
                              : "white",
                          borderBottom:
                            sectionsAvailable[row.Category] &&
                            row.Category.toLowerCase() !==
                              "invoice cost @ 100% take rate"
                              ? "4px solid #9DB3FF"
                              : row.Category.toLowerCase() ===
                                "invoice cost @ 100% take rate"
                              ? "4px solid #E6ECF0"
                              : "",
                          width: "45px",
                          fontWeight:
                            row.Category.toLowerCase() !==
                            "invoice cost @ 100% take rate"
                              ? "600"
                              : "",
                        }}
                      >
                        {row.Category.toLowerCase() === "retail multiple"
                          ? guardrail
                            ? "$" + guardrail
                            : ""
                          : row.Category.toLowerCase() ===
                            "customer margin ($/unit)"
                          ? "PY Margin"
                          : row.Category.toLowerCase() === "customer margin %"
                          ? py_margin || py_margin == 0
                            ? py_margin + "%"
                            : ""
                          : row.Category.toLowerCase() ===
                            "event frequency (wks)"
                          ? metaData.frequency_guardrail
                          : ""}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}
export default RetailPlanTable
