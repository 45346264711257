import React, { useState } from "react"
import { Input, Modal, Button } from "antd"
import "./SequencingPopUp.css"
import Swal from "sweetalert2"
import { callApi } from "../../dataService"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import { setSequencingTableData } from "../../reducers/sequencingTableData"

const SequencingPopUp = ({
  data,
  popUpOpen,
  setPopUpOpen,
  createEventJson,
  setCreateLoading,
}) => {
  const [scenarioName, setScenarioName] = useState("")
  const dispatch = useDispatch()
  const onScenarioNameChange = (event) => {
    let term = event.target.value
    setScenarioName(term)
  }
  const saveScenario = () => {
    let payload = createEventJson
    payload.new_scenario_name = scenarioName
    setCreateLoading(true)
    callApi("api/v1/scenario/create_scenario", payload)
      .then((res) => {
        console.log("sucessfull updated", res)
        if (res.data.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Scenario is successfully Created",
          }).then(() => {
            let temp_data = cloneDeep(data)
            let pack_scenario_list =
              temp_data.scenario_list[createEventJson.package]
            pack_scenario_list.push(scenarioName)
            temp_data.scenario_list[createEventJson.package] =
              pack_scenario_list
            dispatch(setSequencingTableData(temp_data))
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: "Error Occured!",
        })
      })
    setCreateLoading(false)
    setPopUpOpen(false)
    setScenarioName("")
  }
  const onCancelSave = () => {
    setPopUpOpen(false)
    setScenarioName("")
  }
  return (
    <Modal
      className="sequencing-save"
      title="Save Scenario"
      centered
      open={popUpOpen}
      onOk={(e) => saveScenario(e)}
      onCancel={onCancelSave}
      width={"60%"}
      height={200}
    >
      <p>Please Enter the Scenario Name:</p>
      <Input
        value={scenarioName}
        onChange={(e) => onScenarioNameChange(e)}
      ></Input>
    </Modal>
  )
}

export default SequencingPopUp
