import { createSlice} from "@reduxjs/toolkit"

const initialState = {open:false}
const sideBarToggle = createSlice({
  name: "sidebar",
  initialState,
  reducers:{
   sideBarOpen:(state,action)=>{
    state.open=action.payload
   }
  }
})

export const {sideBarOpen}= sideBarToggle.actions
export default  sideBarToggle.reducer