import React, { useEffect, useState, forwardRef } from "react"
import DropDown from "../sequencing_drop_down/DropDown.jsx"
import { Tooltip, Button, Checkbox } from "antd"
import edit_icon from "../../assets/images/edit_icon.png"
import save_icon from "../../assets/images/save_icon.png"
import package_icon from "../../assets/images/package_icon.png"
import SequencingPopUp from "../sequencing_pop_up/SequencingPopUp.jsx"
import "./SequencingTable.css"
import cloneDeep from "lodash/cloneDeep"
import InfoIcon from "@mui/icons-material/Info"
import DataFillPopUp from "../sequencing_data_fill_pop_up/DataFillPopUp.jsx"
import DownloadIcon from "@mui/icons-material/Download"
import { useSelector, useDispatch } from "react-redux"
import {
  setUpdatedPackage,
  setSequencingTableData,
  setDynamicCountOfValues,
  setSequencingTableIsUpdated,
} from "../../reducers/sequencingTableData.js"
import Swal from "sweetalert2"
import { callApi } from "../../dataService.js"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TuneIcon from "@mui/icons-material/Tune"
import UpdateIcon from "@mui/icons-material/Update"
import { getCookie } from "../../helper/getCookie.js"

const SequencingTable = forwardRef(
  (
    {
      data,
      timeFrame,
      versionName,
      handleScroll,
      current_week,
      setCreateLoading,
      setUpdateLoading,
      setIsExpanded,
      isExpanded,
    },
    ref,
  ) => {
    const package_list = data.scenario_list
    const packages = Object.keys(data.active_scenario)
    const versionCondition = {
      Volume: false,
      Promotion: true,
      RetailPrice: false,
    }
    const [dataFillPopUpOpen, setDataFillPopUpOpen] = useState(false)
    const [popUpForSelectedPackage, setPopUpForSelectedPackage] = useState("")
    const timeFrameConfigurations = {
      "Full Year": "full year",
      Q1: { start: 0, end: 12 },
      Q2: { start: 13, end: 25 },
      Q3: { start: 26, end: 38 },
      Q4: { start: 39, end: 52 },
    }
    const active_scenario = data.active_scenario
    const [activeScenario, setActiveScenario] = useState(active_scenario)
    const [createEventJson, setCreateEventJson] = useState({})
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [actualCount, setActualCount] = useState({})
    const [effectiveRetail, setEffectiveRetail] = useState({})
    const [multiple, setMultiple] = useState({})
    const [errorPopUp, setErrorPopUp] = useState({
      error: false,
      error_message: "",
    })
    const [checkedPackagesForAnaplan, setCheckedPackagesForAnaplan] = useState(
      {},
    )
    let payload = {}
    const dynamicCountOfValues = useSelector(
      (state) => state.sequencingTable.dynamicCountOfValues,
    )
    const sequencingSelectedOptions = useSelector(
      (state) => state.sequencingScreenDropDown.sequencingSelectedOptions,
    )
    const summarySelectedOptions = useSelector(
      (state) => state.summmaryScreenDropDown.selectedOptions,
    )
    const dispatch = useDispatch()
    // const filterFullData = (col) => {
    //   if (timeFrame !== "Full Year" && timeFrameConfigurations[timeFrame]) {
    //     let filteredValues = data[col].slice(
    //       timeFrameConfigurations[timeFrame].start,
    //       timeFrameConfigurations[timeFrame].end + 1,
    //     )
    //     return filteredValues
    //   } else return data[col]
    // }

    useEffect(() => {
      setCheckedPackagesForAnaplan({})
    }, [data])

    useEffect(()=>{
      let scrollDistance=current_week*80
      handleScroll(scrollDistance)
    },[current_week])

    const setPackagesForAnaplan = (e, pack) => {
      let checked = e.target.checked
      console.log("checked------>", checked)
      let temp_packages_data = cloneDeep(checkedPackagesForAnaplan)
      if (checked) {
        temp_packages_data[pack] = data.active_scenario[pack]
      } else {
        delete temp_packages_data[pack]
      }
      console.log("checkedPackagesForAnaplan------>", checkedPackagesForAnaplan)
      setCheckedPackagesForAnaplan(temp_packages_data)
    }

    const onInputChange = (e, pack, index) => {
      dispatch(setSequencingTableIsUpdated(false))
      let value = e.target.value
      let temp_data = cloneDeep(data)
      // let startingIndex =
      //   timeFrame === "Full Year" || !timeFrameConfigurations[timeFrame]
      //     ? 0
      //     : timeFrameConfigurations[timeFrame].start
      temp_data[pack][index] = value.toUpperCase()
      dispatch(setSequencingTableData(temp_data))
      dispatch(setDynamicCountOfValues(pack))
      calculateActualCount()
      console.log("temp_data", temp_data)
    }

    const createPromoArrayForPayload = (pack) => {
      let temp_data = data[pack]
      let promos = data[pack + "_multiple"]
      let promoPayload = temp_data.map(
        (v) => promos[v.toLowerCase()] || promos[v.toUpperCase()] || "",
      )
      return promoPayload
    }

    const calculateActualCount = () => {
      let countObject = {}
      packages.forEach((pack) => {
        let pack_count = {}
        let temp_pack_count = cloneDeep(
          data[pack + "_count"] ? data[pack + "_count"][0] : {},
        )
        delete temp_pack_count.index
        delete temp_pack_count.label
        for (let key in temp_pack_count) {
          pack_count[key.toUpperCase()] = temp_pack_count[key]
        }
        countObject[pack] = pack_count
      })
      setActualCount(countObject)
      console.log("actualCount", countObject)
    }

    const setEffectiveRetailInUpperCase = () => {
      let countObject = {}
      packages.forEach((pack) => {
        let pack_count = {}
        let temp_pack_count = cloneDeep(
          data[pack + "_effective_retail"]
            ? data[pack + "_effective_retail"][0]
            : {},
        )
        delete temp_pack_count.index
        delete temp_pack_count.label
        for (let key in temp_pack_count) {
          pack_count[key.toUpperCase()] = temp_pack_count[key]
        }
        countObject[pack] = pack_count
      })
      setEffectiveRetail(countObject)
    }

    const setMultipleInUpperCase = () => {
      let countObject = {}
      packages.forEach((pack) => {
        let pack_count = {}
        let temp_pack_count = cloneDeep(
          data[pack + "_multiple"] ? data[pack + "_multiple"][0] : {},
        )
        delete temp_pack_count.index
        delete temp_pack_count.label
        for (let key in temp_pack_count) {
          pack_count[key.toUpperCase()] = temp_pack_count[key]
        }
        countObject[pack] = pack_count
      })
      setMultiple(countObject)
    }

    const visibilityAccordingToTimeFrame = (i) => {
      if (timeFrame) {
        let returnedValue = false
        if (timeFrame.includes("Full Year")) {
          console.log("full true")
          returnedValue = true
        } else {
          timeFrame.forEach((frame) => {
            if (!timeFrameConfigurations[frame]) {
              if (data["period"][i] == frame) {
                returnedValue = true
              }
            } else if (timeFrameConfigurations[frame]) {
              if (
                i >= timeFrameConfigurations[frame].start &&
                i <= timeFrameConfigurations[frame].end
              ) {
                returnedValue = true
              }
            }
          })
        }
        return returnedValue
      }
    }

    const displayActualCounts = (packageName) => {
      let actual_count = actualCount[packageName]
      let displayText = ""
      for (let key in actual_count) {
        if (actual_count[key] !== 0) {
          let str = `${key.toUpperCase()}: ${actual_count[key]} \n`
          displayText = displayText + str
        }
      }
      return displayText
    }

    const downloadAnaplanData = () => {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = summarySelectedOptions.Year
      temp_payload.customer = summarySelectedOptions.Customer
      temp_payload.region = summarySelectedOptions.Region
      temp_payload["market_unit"] = summarySelectedOptions["Market Unit"]
      temp_payload.category = sequencingSelectedOptions.Category
      temp_payload.packages = checkedPackagesForAnaplan
      callApi("api/v1/sequencing/download_anaplan_data", temp_payload)
        .then((res) => {
          if (res.status == 200) {
            let csvData = res.data
            console.log("sucessfull updated", csvData)
            let blob = new Blob(["\ufeff" + csvData], {
              type: "text/csv;charset=utf-8;",
            })
            let dwldLink = document.createElement("a")
            let url = URL.createObjectURL(blob)
            let isSafariBrowser =
              navigator.userAgent.indexOf("Safari") != -1 &&
              navigator.userAgent.indexOf("Chrome") == -1
            if (isSafariBrowser) {
              //if Safari open in new window to save file with random filename.
              dwldLink.setAttribute("target", "_blank")
            }
            dwldLink.setAttribute("href", url)
            dwldLink.setAttribute("download", "anaplan_file1" + ".csv")
            dwldLink.style.visibility = "hidden"
            document.body.appendChild(dwldLink)
            dwldLink.click()
            document.body.removeChild(dwldLink)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
        })
      callApi("api/v1/sequencing/download_anaplan_data_accruals", temp_payload)
        .then((res) => {
          if (res.status == 200) {
            console.log("re----", res)
            let csvData = res.data
            console.log("sucessfull updated", csvData)
            let blob = new Blob(["\ufeff" + csvData], {
              type: "text/csv;charset=utf-8;",
            })
            let dwldLink = document.createElement("a")
            let url = URL.createObjectURL(blob)
            let isSafariBrowser =
              navigator.userAgent.indexOf("Safari") != -1 &&
              navigator.userAgent.indexOf("Chrome") == -1
            if (isSafariBrowser) {
              //if Safari open in new window to save file with random filename.
              dwldLink.setAttribute("target", "_blank")
            }
            dwldLink.setAttribute("href", url)
            dwldLink.setAttribute("download", "anaplan_file2" + ".csv")
            dwldLink.style.visibility = "hidden"
            document.body.appendChild(dwldLink)
            dwldLink.click()
            document.body.removeChild(dwldLink)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
        })
    }

    useEffect(() => {
      if (
        versionCondition &&
        versionCondition[versionName] === true &&
        data.period.length > 0
      ) {
        dispatch(setDynamicCountOfValues("all"))
        calculateActualCount(data)
        setEffectiveRetailInUpperCase()
        setMultipleInUpperCase()
      }
    }, [data])

    const titleForVal = (packageName, val, j) => {
      let term = val.toLowerCase()
      let countOfPromos = actualCount[packageName]
      let effective_retail = effectiveRetail[packageName]
      let textToDisplay = ""
      for (let key in countOfPromos) {
        if (countOfPromos[key] > 0) {
          textToDisplay =
            textToDisplay +
            `\n${key.toUpperCase()}: ${
              dynamicCountOfValues[packageName][key] || 0
            } of ${countOfPromos[key]} - $${effective_retail[key] || 0}`
        }
      }
      return `Promo:${
        multiple[packageName] && multiple[packageName][term]
          ? multiple[packageName][term]
          : ""
      }${textToDisplay} `
    }

    const checkForValidation = (pack) => {
      console.log("actual_count=====>,", actualCount)
      let values = Object.keys(dynamicCountOfValues[pack])
      let error = { error: false, error_message: "" }
      values.forEach((val) => {
        if (!actualCount[pack][val.toUpperCase()]) {
          error.error = true
          if(val.length==0){
            error.error_message = `Promo count does not match`
          }
          else error.error_message =  `${val.toUpperCase()} promo does not exist in this
          particular package`
          console.log("no count present")
        } else if (
          Number(actualCount[pack][val.toUpperCase()]) !==
          Number(dynamicCountOfValues[pack][val])
        ) {
          console.log("not equal")
          error.error = true
          error.error_message = `${val.toUpperCase()} actual count is
              ${actualCount[pack][val.toUpperCase()]} and given
              count is ${dynamicCountOfValues[pack][val]}`
        }
      })
      // let isValid = true
      // let actual_count = findNoZeroActualCountPromo(pack)
      // let actualTotalCount = Object.values(actualCount[pack])
      //   .map((val) => Number(val))
      //   .reduce((a, b) => a + b)
      // if (actualTotalCount == 52) {
      //   let countPresent = dynamicCountOfValues[pack]
      //   let countOfVals = Object.keys(countPresent)
      //   let actualCountObj = actualCount[pack]
      //   countOfVals.map((p) => {
      //     let index = actual_count.findIndex((c) => c == p)
      //     if (index == -1 && p !== "") {
      //       isValid = false
      //     }
      //   })
      //   actual_count.map((p) => {
      //     if (actualCountObj[p] < countPresent[p]) {
      //       isValid = false
      //     }
      //   })
      // } else {
      //   isValid = false
      // }
      // return isValid
      return error
    }

    const fillDataInPackage = (pack) => {
      setPopUpForSelectedPackage(pack)
      setDataFillPopUpOpen(true)
      console.log("dataFillPopUpOpen***", dataFillPopUpOpen)
    }

    const convertToCSV = (objArray) => {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
      var str = ""

      for (var i = 0; i < array.length; i++) {
        var line = ""
        for (var index in array[i]) {
          if (line != "") line += ","

          line += array[i][index]
        }

        str += line + "\r\n"
      }
      console.log("str", str)
      return str
    }

    function exportCSVFile(data, fileTitle) {
      let arrayForExport = []
      packages.forEach((pack) => {
        let row = []
        row.push(pack.replace(/_/g, " ").toUpperCase())
        row.push(data.active_scenario[pack])
        row.push(data[pack])
        arrayForExport.push(row)
      })
      let headers2 = [" ", " "]
      headers2.push(data.period)
      arrayForExport.unshift(headers2)
      let headers1 = ["Package", "Scenarios"]
      headers1.push(data.week)
      arrayForExport.unshift(headers1)
      // Convert Object to JSON
      var jsonObject = JSON.stringify(arrayForExport)

      var csv = convertToCSV(jsonObject)

      var exportedFilenmae = fileTitle + ".csv" || "export.csv"

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilenmae)
          link.style.visibility = "hidden"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }

    const setExpandandCollapse = () => {
      let bool = isExpanded
      setIsExpanded(!bool)
    }

    const isCountSameAsActualCount = (pack) => {
      let promos_count = cloneDeep(dynamicCountOfValues[pack])
      let actual_count = actualCount[pack]
      let nonZeroActualCount = findNoZeroActualCountPromo(pack)
      let bool = true
      nonZeroActualCount.forEach((p, i) => {
        if (Number(actual_count[p]) !== Number(promos_count[p])) {
          bool = false
        }
      })
      return bool
    }

    const findNoZeroActualCountPromo = (pack) => {
      let actualCountPromos = []
      let actual_count = cloneDeep(actualCount[pack])
      for (let key in actual_count) {
        if (actual_count[key] !== 0) {
          actualCountPromos.push(key)
        }
      }
      return actualCountPromos
    }
    const addRemainingActualCountVals = (pack) => {
      let packageData = cloneDeep(data[pack])
      let setValues = !isCountSameAsActualCount(pack)
      if (setValues) {
        let actualCountPromos = findNoZeroActualCountPromo(pack)
        actualCountPromos.map((promo) => {
          let count =
            cloneDeep(actualCount[pack][promo]) -
            (cloneDeep(dynamicCountOfValues[pack][promo]) || 0)
          console.log("promo!!!!", promo)
          console.log("count!!!!", count)
          while (count > 0) {
            const emptyPackageIndex = packageData.findIndex(
              (d) => d.length === 0,
            )
            if (emptyPackageIndex !== -1) {
              packageData[emptyPackageIndex] = promo
              count--
            }
          }
        })
        console.log("packageData", packageData)
        return packageData
      } else {
        return [""]
      }
    }

    const updateOrcreateScenario = (pack, type) => {
      let valid = checkForValidation(pack)
      console.log("error valid", valid)
      if (!valid.error) {
        let temp_payload = cloneDeep(payload)
        temp_payload.year = summarySelectedOptions.Year
        temp_payload.customer = summarySelectedOptions.Customer
        temp_payload.region = summarySelectedOptions.Region
        temp_payload["market_unit"] = summarySelectedOptions["Market Unit"]
        temp_payload.version = sequencingSelectedOptions.Version
        temp_payload.scenario = data.active_scenario[pack]
        temp_payload.package = pack
        temp_payload.promos = data[pack]
        temp_payload.multiple = createPromoArrayForPayload(pack)
        // temp_payload.temp_promos = addRemainingActualCountVals(pack)
        temp_payload.temp_promos = [""]
        if (type === "update") {
          setUpdateLoading(true)
          delete temp_payload.create_type
          temp_payload.update_type = "sequencing"
          callApi("api/v1/scenario/update_scenario", temp_payload)
            .then((res) => {
              console.log("sucessfull updated", res)
              if (res.data.status == 200) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Scenario is successfully updated",
                })
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  confirmButtonColor: "#E21824",
                  text: "Error Occured!",
                })
              }
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#E21824",
                text: "Error Occured!",
              })
            })
          setUpdateLoading(false)
          dispatch(setSequencingTableIsUpdated(true))
          dispatch(setUpdatedPackage(pack))
        } else {
          delete temp_payload.update_type
          temp_payload.create_type = "sequencing"
          setPopUpOpen(true)
          setCreateEventJson(temp_payload)
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: valid.error_message,
        })
      }
    }

    return (
      <div>
        {data.period.length > 0 && (
          <div
            className="sequencing_table"
            style={{
              borderRadius: "4px",
              backgroundColor: "white",
              padding: isExpanded ? "5px" : "15px",
              height: isExpanded ? "82.5vh" : "63vh",
            }}
          >
            <div
              style={{
                borderBottom: "2px solid #dcdada",
                marginBottom: isExpanded ? "5px" : "10px",
                fontSize: "14px",
                paddingBottom: isExpanded ? "5px" : "10px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              {console.log("timeframe", timeFrame)}{" "}
              <img src={package_icon} style={{ marginRight: "10px" }} alt="" />
              Sequencing
              <Tooltip title="Download">
                <DownloadIcon
                  onClick={() => exportCSVFile(data, "sequencing")}
                  style={{ color: "#E81C0E", marginLeft: "auto" }}
                />
              </Tooltip>
              <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
                {isExpanded ? (
                  <ExpandLessIcon
                    onClick={setExpandandCollapse}
                    style={{ color: "#E81C0E", marginLeft: "10px" }}
                  ></ExpandLessIcon>
                ) : (
                  <ExpandMoreIcon
                    onClick={setExpandandCollapse}
                    style={{ color: "#E81C0E", marginLeft: "10px" }}
                  ></ExpandMoreIcon>
                )}
              </Tooltip>
            </div>
            <div
              style={{
                width: "100%",
                height: isExpanded ? "85%" : "78%",
                overflow: "auto",
              }}
              onScroll={(e) => handleScroll(e.target.scrollLeft)}
              ref={ref}
            >
              <table
                className={isExpanded ? "expanded" : "not_expanded"}
                style={{ width: "100%", height: "100%" }}
              >
                <thead
                  style={{
                    position: "sticky",
                    zIndex: "5",
                  }}
                >
                  <tr>
                    <th className="static">Action</th>
                    <th className="static">Package</th>
                    <th className="static">Scenarios</th>
                    <th className="static"></th>
                    {data["period"].map(
                      (m, i) =>
                        visibilityAccordingToTimeFrame(i) && (
                          <th key={m + i}>{m}</th>
                        ),
                    )}
                  </tr>
                  <tr className={`month-row ${isExpanded ? "expanded" : ""}`}>
                    <th className="static"></th>
                    <th className="static"></th>
                    <th className="static"></th>
                    <th className="static"></th>
                    {data["week"].map(
                      (p, i) =>
                        visibilityAccordingToTimeFrame(i) && (
                          <th
                            style={{ width: "180px" }}
                            key={p + i}
                            className="border"
                          >
                            {p}
                          </th>
                        ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {packages.map((pack, i) => (
                    <tr className={isExpanded ? "expanded" : ""} key={pack + i}>
                      <td className="static">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip>
                            <Checkbox
                              checked={checkedPackagesForAnaplan[pack]}
                              onChange={(e) => {
                                setPackagesForAnaplan(e, pack)
                              }}
                              style={{
                                marginRight: "10px",
                              }}
                            ></Checkbox>
                          </Tooltip>
                          <Tooltip title="Update">
                            <UpdateIcon
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px",
                                color: "#E81C0E",
                                cursor:
                                  versionCondition[versionName] === false
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              src={edit_icon}
                              alt=""
                              onClick={(e) =>
                                versionCondition[versionName] === false
                                  ? null
                                  : updateOrcreateScenario(pack, "update")
                              }
                            />
                          </Tooltip>

                          <Tooltip title="Save as new">
                            <img
                              src={save_icon}
                              alt=""
                              style={{
                                height: "13px",
                                width: "13px",
                                cursor:
                                  versionCondition[versionName] === false
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              disabled={versionCondition[versionName] === false}
                              onClick={(e) =>
                                versionCondition[versionName] === false
                                  ? null
                                  : updateOrcreateScenario(pack, "create")
                              }
                            />
                          </Tooltip>
                        </div>
                      </td>
                      {versionCondition &&
                        versionCondition[versionName] === true && (
                          <Tooltip
                            title={
                              <div style={{ whiteSpace: "pre-line" }}>
                                {displayActualCounts(pack)}
                              </div>
                            }
                          >
                            <td className="static">
                              {pack.replace(/_/g, " ").toUpperCase()}
                            </td>
                          </Tooltip>
                        )}
                      {versionCondition &&
                        versionCondition[versionName] === false && (
                          <Tooltip>
                            <td className="static">
                              {pack.replace(/_/g, " ").toUpperCase()}
                            </td>
                          </Tooltip>
                        )}
                      <td className="static">
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <DropDown
                            data={data}
                            scenarioList={package_list[pack]}
                            activeScenario={activeScenario}
                            packageName={pack}
                            year={summarySelectedOptions.Year}
                            marketUnit={summarySelectedOptions["Market Unit"]}
                            customer={summarySelectedOptions.Customer}
                            region={summarySelectedOptions.Region}
                            disabledBasedOnVersion={
                              versionCondition[versionName]
                            }
                            setActiveScenario={setActiveScenario}
                          />
                        </div>
                      </td>
                      <td className="static">
                        <TuneIcon
                          key={pack + i}
                          onClick={() => fillDataInPackage(pack)}
                          style={{
                            color: "#E81C0E",
                            cursor:
                              versionCondition[versionName] === false
                                ? "not-allowed"
                                : "pointer",
                          }}
                        />
                        <div>
                          {popUpForSelectedPackage === pack && (
                            <DataFillPopUp
                              dataFillPopUpOpen={dataFillPopUpOpen}
                              setDataFillPopUpOpen={setDataFillPopUpOpen}
                              fullData={data}
                              packageName={pack}
                              current_week={current_week}
                            ></DataFillPopUp>
                          )}
                        </div>
                      </td>
                      {data[pack].map(
                        (val, j) =>
                          visibilityAccordingToTimeFrame(j) && (
                            <td
                              style={{
                                backgroundColor:
                                  val.toLowerCase() === "edv"
                                    ? "white"
                                    : "#FFEFEF",
                              }}
                              key={pack + i + j}
                            >
                              {versionCondition &&
                                versionCondition[versionName] === true && (
                                  <Tooltip
                                    title={
                                      j < current_week-1?"":<div style={{ whiteSpace: "pre-line" }}>
                                        {titleForVal(pack, val, j)}
                                      </div>
                                    }
                                  >
                                    <input
                                      style={{
                                        backgroundColor:
                                          j < current_week-1
                                            ? "rgba(232, 28, 14, 0.2)"
                                            : val.toLowerCase() === "edv"
                                            ? "white"
                                            : "#FFEFEF",
                                      }}
                                      className="input-field"
                                      type="text"
                                      value={val}
                                      disabled={j < current_week-1}
                                      onChange={(e) =>
                                        onInputChange(e, pack, j)
                                      }
                                      placeholder={j < current_week-1?"X":""}
                                    ></input>
                                  </Tooltip>
                                )}
                              {versionCondition[versionName] === false && (
                                <div style={{ textAlign: "center" }}>
                                  {versionName.toLowerCase().includes("volume")
                                    ? Number(val).toLocaleString("en-US")
                                    : val}
                                </div>
                              )}
                            </td>
                          ),
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="download_anaplan_container">
              <div
                style={{
                  fontSize: "0.9vw",
                  color: "#E81C0E",
                  fontWeight: "500",
                }}
              >
                *Only the selected packages will be downloaded for Anaplan.
                Please be sure to select the correct scenario for the
                corresponding package.
              </div>
              <Button
                onClick={downloadAnaplanData}
                className="download_anaplan"
              >
                Download for anaplan
              </Button>
            </div>
            <SequencingPopUp
              data={data}
              popUpOpen={popUpOpen}
              setPopUpOpen={setPopUpOpen}
              setCreateLoading={setCreateLoading}
              createEventJson={createEventJson}
            />
          </div>
        )}
        {data.period.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#E81C0E",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            There are no packages available for the given customer in the
            selected category.
          </div>
        )}
      </div>
    )
  },
)
export default SequencingTable
