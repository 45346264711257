import React, { useEffect, useState, useRef } from "react"
import { Table, Input, Space, Button } from "antd"
import Header from "../../components/header/Header"
import SideBar from "../../components/sidebar/SideBar"
import { useSelector, useDispatch } from "react-redux"
import { getUserList, getYearList } from "../../reducers/adminScreen"
import { getCookie } from "../../helper/getCookie"
import AdminPanelModal from "../../components/admin_panel_modal/AdminPanelModal"
import "./AdminPanel.css"
import SearchIcon from "@mui/icons-material/Search"

const AdminPanel = () => {
  const side_bar_open = useSelector((state) => state.sidebar.open)
  const userList = useSelector((state) => state.adminScreen.users.data)
  const year = useSelector((state) => state.adminScreen.years.data)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const dispatch = useDispatch()
  const [users, setUsers] = useState(userList)
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")

  useEffect(() => {
    setUsers(userList)
  }, [userList])

  useEffect(() => {
    dispatch(getYearList({}))
  }, [])

  useEffect(() => {
    dispatch(getUserList({}))
  }, [])

  const setModalOpen = (record) => {
    setSelectedUser(record.email)
    setIsModalOpen(true)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm, selectedKeys, dataIndex) => {
    clearFilters()
    setSearchText("")
    handleSearch(selectedKeys, confirm, dataIndex)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          {/* <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchIcon />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button> */}
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, confirm, selectedKeys, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchIcon
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const columns = [
    {
      title: "User",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Channels",
      key: "id",
      dataIndex: "id",
      render: (text, record) => (
        <button
          style={{
            border: "none",
            backgroundColor: "#E81C0E",
            height: "25px",
            color: "white",
            borderRadius: "15px",
          }}
          onClick={() => setModalOpen(record)}
        >
          Click to view permissions
        </button>
      ),
    },
  ]
  return (
    <div>
      <Header></Header>
      <SideBar></SideBar>
      <div
        className="admin_table"
        style={{
          width: side_bar_open ? "72%" : "93.5%",
          left: side_bar_open ? "12%" : "0",
          position: "relative",
          margin: "auto",
          paddingTop: "15vh",
          marginBottom: "5vh",
          display: "flex",
          gap: "5vw",
        }}
      >
        {userList && userList.length > 0 && (
          <>
            <Table
              style={{ width: "100%" }}
              dataSource={users}
              columns={columns}
              size="small"
            />
            <AdminPanelModal
              selectedUser={selectedUser}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              year={year}
            ></AdminPanelModal>
          </>
        )}
      </div>
    </div>
  )
}

export default AdminPanel
