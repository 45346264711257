import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"

const initialState = {
  customers: { data: [], status: "", error: null },
  dQMarketUnits: { data: [], status: "", error: null },
  years: { data: [], status: "", error: null },
  regions: { data: [], status: "", error: null },
  tableData:{data: [], status: "", error: null },
  available:["0","1"],
  dataQualitySelectedOptions: {
    Customer: null,
    "Market Unit": null,
    Region:null,
    Year: null,
    Available:"0"
  },
}

export const getCustomers = createAsyncThunk("getCustomers", async (payload) =>
  callApi("api/v1/data_quality/get_customer", payload),
)

export const getDQMarketUnits = createAsyncThunk(
  "getDQMarketUnits",
  async (payload) => callApi("api/v1/data_quality/get_market_unit", payload),
)
export const getDQRegions = createAsyncThunk(
  "getDQRegions",
  async (payload) => callApi("api/v1/data_quality/get_region", payload),
)

export const getDQYearsList = createAsyncThunk("getYears", async (payload) =>
  callApi("api/v1/scenario/get_year", payload),
)

export const getDataQualityData = createAsyncThunk("getDataQualityData", async (payload) =>
  callApi("api/v1/data_quality/get_data", payload),
)

const dataQuality = createSlice({
  name: "retailplan",
  initialState,
  reducers: {
    setDataQualitySelectedOption: (state, action) => {
      state.dataQualitySelectedOptions[action.payload.label] = action.payload.option
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.customers.status = "pending"
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.customers.status = "fulfilled"
          state.customers.data = action.payload.data.data.customer
          state.dataQualitySelectedOptions.Customer = action.payload.data.data.customer[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting customers",
          })
        }
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.customers.status = "rejected"
        state.customers.error = action.error.message
      })
      .addCase(getDQMarketUnits.pending, (state) => {
        state.dQMarketUnits.status = "pending"
      })
      .addCase(getDQMarketUnits.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.dQMarketUnits.status = "fulfilled"
          state.dQMarketUnits.data = action.payload.data.data.market_unit
          state.dataQualitySelectedOptions["Market Unit"] = action.payload.data.data.market_unit[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting market units",
          })
        }
      })
      .addCase(getDQMarketUnits.rejected, (state, action) => {
        state.dQMarketUnits.status = "rejected"
        state.dQMarketUnits.error = action.error.message
      })
      .addCase(getDQYearsList.pending, (state) => {
        state.years.status = "pending"
      })
      .addCase(getDQYearsList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
            state.years.status = "fulfilled"
            state.years.data = action.payload.data.data
            let keys=Object.keys(action.payload.data.data)
            console.log("keys",keys)
            state.dataQualitySelectedOptions.Year =keys[0]
          } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting years",
          })
        }
      })
      .addCase(getDQYearsList.rejected, (state, action) => {
        state.years.status = "rejected"
        state.years.error = action.error.message
      })
      .addCase(getDataQualityData.pending, (state) => {
        state.tableData.status = "pending"
      })
      .addCase(getDataQualityData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.tableData.status = "fulfilled"
          state.tableData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting quality data",
          })
        }
      })
      .addCase(getDataQualityData.rejected, (state, action) => {
        state.tableData.status = "rejected"
        state.tableData.error = action.error.message
      })
      .addCase(getDQRegions.pending, (state) => {
        state.regions.status = "pending"
      })
      .addCase(getDQRegions.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.regions.status = "fulfilled"
          state.regions.data = action.payload.data.data.region
          state.dataQualitySelectedOptions.Region=action.payload.data.data.region[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting regions",
          })
        }
      })
      .addCase(getDQRegions.rejected, (state, action) => {
        state.regions.status = "rejected"
        state.regions.error = action.error.message
      })
  },
})

export const { setDataQualitySelectedOption}= dataQuality.actions
export default dataQuality.reducer
