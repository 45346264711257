import React, { useState } from "react"
import heading_logo from "../../assets/images/heading_logo.png"

import "./header.css"

const Header = () => {
  const text = "Revenue Optimization and Marketing Engine"

  return (
    <div className="header_container">
      <div className="red-border"></div>
      <div className="center-component">
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <b className="animated-text">R</b>evenue
          <b style={{ marginRight: "6px" }}></b>
          <b className="animated-text">O</b>
          ptimization and
          <b style={{ marginRight: "6px" }}></b>
          <b className="animated-text">M</b>arketing{" "}
          <b style={{ marginRight: "6px" }}></b>
          <b className="animated-text">E</b>ngine
        </div>
        <img
          style={{ width: "10.2vw", height: "36p6.5vh", marginLeft: "auto" }}
          src={heading_logo}
          alt="Swire logo"
        ></img>
      </div>
      <div className="red-border"></div>
    </div>
  )
}

export default Header
