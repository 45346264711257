import React from "react"
import { Select } from "antd"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import "./DropDown.css"
import { setSequencingTableData,changeSequencingScenario,setpackageName } from "../../reducers/sequencingTableData.js"
import { cloneDeep } from "lodash"
import { useDispatch,useSelector} from "react-redux"

const DropDown = ({
  data,
  scenarioList,
  activeScenario,
  year,
  customer,
  region,
  packageName,
  marketUnit,
  disabledBasedOnVersion,
}) => {
  const options = scenarioList.map((sce) => {
    let obj = {}
    obj.label = sce
    obj.value = sce
    return obj
  })
  const onSelectionChange = (e) => {
    let temp_data = cloneDeep(data)
    temp_data.active_scenario[packageName] = e
    dispatch(setSequencingTableData(temp_data))
    dispatch(setpackageName(packageName))
    let temp_payload={}
    temp_payload.package=packageName
    temp_payload.year=year
    temp_payload.customer=customer
    temp_payload.region =region
    temp_payload["market_unit"] = marketUnit
    temp_payload.scenario=e
    dispatch(changeSequencingScenario(temp_payload))
  }
  const sequencingData=useSelector(state=>state.sequencingTable.sequencingTableData.data)
  const dispatch = useDispatch()
  return (
    <div className="sequencing_drop_down">
      <Select
        disabled={!disabledBasedOnVersion}
        style={{ width: "100%" }}
        suffixIcon={<ArrowDropDownIcon />}
        defaultValue={sequencingData['active_scenario'][packageName]}
        options={options}
        onChange={(e) => onSelectionChange(e)}
      ></Select>
    </div>
  )
}

export default DropDown
