import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"

const initialState = {
  channels:{data: [], status: "", error: null},
  customers: { data: [], status: "", error: null },
  marketUnits: { data: [], status: "", error: null },
  category: { data: [], status: "", error: null },
  packages: { data: [], status: "", error: null },
  scenarios: { data: [], status: "", error: null },
  versions: { data: ["Swire", "Customer"] },
  checkbook: { data: ["Yes", "No"] },
  years:{data: [], status: "", error: null},
  regions:{data:[],status:"",error:null},
  previousCustomer: null,
  previousMarketUnit: "",
  selectedOptions: {
    Year:null,
    Channel:null,
    Customer: null,
    Region:null,
    "Market Unit": null,
    Category:null,
    Package: null,
    Scenario: null,
    Version: null,
    Checkbook: null,
  },
}

export const getChannelList = createAsyncThunk(
  "getChannelList",
  async (payload) => callApi("api/v1/summary/get_super_channel", payload),
)

export const getCustomersList = createAsyncThunk(
  "fetchCustomersList",
  async (payload) => callApi("api/v1/summary/get_customers", payload),
)

export const getMarketUnits = createAsyncThunk(
  "getMarketUnits",
  async (payload) => callApi("api/v1/summary/get_market_units", payload),
)

export const getPackageList = createAsyncThunk(
  "fetchPackageList",
  async (payload) => callApi("api/v1/summary/get_packages", payload),
)

export const getScenarioList = createAsyncThunk(
  "fetchScenarioList",
  async (payload) => callApi("api/v1/summary/get_scenario", payload),
)

export const getCategoryList = createAsyncThunk(
  "getCategoryList",
  async (payload) => callApi("api/v1/summary/get_catagories", payload),
)

export const getYearList = createAsyncThunk(
  "getYearList",
  async (payload) => callApi("api/v1/scenario/get_year", payload),
)

export const getRegions = createAsyncThunk(
  "getRegions",
  async (payload) => callApi("api/v1/summary/get_customer_regions", payload),
)


const summaryScreenDropDown = createSlice({
  name: "retailplan",
  initialState,
  reducers: {
    setSelectedVersionAndCheckbook: (state) => {
      state.selectedOptions.Version = "Swire"
      state.selectedOptions.Checkbook = "Yes"
    },
    setSelectedOptions: (state, action) => {
      state.selectedOptions[action.payload.label] = action.payload.option
      if (action.payload.label === "Customer") {
        state.previousCustomer = action.payload.option
      }
    },
    setPreviousCustomer: (state, action) => {
      state.previousCustomer = action.payload
    },
    setPreviousMarketUnit: (state, action) => {
      state.previousMarketUnit = action.payload
    },
    clearPackageListAndSelectedOption:(state)=>{
      state.packages.data=[]
      state.selectedOptions.Package=null
    },
    clearScenarioListAndSelectedOption:(state)=>{
       state.scenarios.data=[]
       state.selectedOptions.Scenario=null
    },
    clearCategoryListAndSelectedOption:(state)=>{
      state.category.data=[]
      state.selectedOptions.Category=null  
    },
    clearMarketUnitandSelectedOption:(state)=>{
      state.marketUnits.data=[]
      state.selectedOptions["Market Unit"]=null  
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersList.pending, (state) => {
        state.customers.loading = "pending"
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        //state.customers.status = "fulfilled"
        // state.customers.data = action.payload.data.data
        // state.selectedOptions.Customer = action.payload.data.data[0]
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          return {
            ...state,
            customers: {
              status: "fulfilled",
              data: filteredData,
            },
            selectedOptions: {
              ...state.selectedOptions,
              Customer: action.payload.data.data[0],
            },
            previousCustomer: state.selectedOptions.Customer,
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting customer list",
          })
        }
      })
      .addCase(getCustomersList.rejected, (state, action) => {
        state.customers.status = "rejected"
        state.customers.error = action.error.message
      })
      .addCase(getPackageList.pending, (state) => {
        state.packages.status = "pending"
      })
      .addCase(getPackageList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let packages_array = action.payload.data.data
          if (packages_array.length > 0) {
            let filteredData = action.payload.data.data.filter(
              (v) => v.length !== 0,
            )
            state.packages.status = "fulfilled"
            state.packages.data = filteredData
            state.selectedOptions.Package = filteredData[0]
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "There are no package for the selected market unit and category combination",
            })
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting package list",
          })
        }
      })
      .addCase(getPackageList.rejected, (state, action) => {
        state.packages.status = "rejected"
        state.packages.error = action.error.message
      })
      .addCase(getScenarioList.pending, (state) => {
        state.scenarios.status = "pending"
      })
      .addCase(getScenarioList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          state.scenarios.status = "fulfilled"
          state.scenarios.data = filteredData
          state.selectedOptions.Scenario =
            state.selectedOptions.Scenario !== null &&
            action.payload.data.data.includes(state.selectedOptions.Scenario)
              ? state.selectedOptions.Scenario
              : filteredData[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting scenario list",
          })
        }
      })
      .addCase(getScenarioList.rejected, (state, action) => {
        state.scenarios.status = "rejected"
        state.scenarios.error = action.error.message
      })
      .addCase(getMarketUnits.pending, (state) => {
        state.marketUnits.status = "pending"
      })
      .addCase(getMarketUnits.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          state.marketUnits.status = "fulfilled"
          state.marketUnits.data = filteredData
          state.selectedOptions["Market Unit"] = filteredData[0]
          state.previousMarketUnit=state.selectedOptions["Market Unit"]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting scenario list",
          })
        }
      })
      .addCase(getMarketUnits.rejected, (state, action) => {
        state.scenarios.status = "rejected"
        state.scenarios.error = action.error.message
      })
      .addCase(getCategoryList.pending, (state) => {
        state.category.status = "pending"
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.categories.filter(
            (v) => v.length !== 0,
          )
          state.category.status = "fulfilled"
          state.category.data = filteredData
          state.selectedOptions.Category = filteredData[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.category.status = "rejected"
        state.category.error = action.error.message
      })
      .addCase(getYearList.pending, (state) => {
        state.years.status = "pending"
      })
      .addCase(getYearList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.years.status = "fulfilled"
          state.years.data = action.payload.data.data
          let keys=Object.keys(action.payload.data.data)
          state.selectedOptions.Year =keys[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(getYearList.rejected, (state, action) => {
        state.years.status = "rejected"
        state.years.error = action.error.message
      })
      .addCase(getChannelList.pending, (state) => {
        state.channels.status = "pending"
      })
      .addCase(getChannelList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.channels.status = "fulfilled"
          state.channels.data = action.payload.data.data.channels
          state.selectedOptions.Channel =action.payload.data.data.channels[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(getChannelList.rejected, (state, action) => {
        state.channels.status = "rejected"
        state.channels.error = action.error.message
      })
      .addCase(getRegions.pending, (state) => {
        state.regions.status = "pending"
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          state.regions.status = "fulfilled"
          state.regions.data = filteredData
          state.selectedOptions.Region = filteredData[0]
            
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting scenario list",
          })
        }
      })
      .addCase(getRegions.rejected, (state, action) => {
        state.scenarios.status = "rejected"
        state.scenarios.error = action.error.message
      })
  },
})

export const {
  setSelectedOptions,
  setSelectedVersionAndCheckbook,
  setPreviousCustomer,
  clearPackageListAndSelectedOption,
  clearCategoryListAndSelectedOption,
  setPreviousMarketUnit,
  clearMarketUnitandSelectedOption,
  clearScenarioListAndSelectedOption
} = summaryScreenDropDown.actions
export default summaryScreenDropDown.reducer
