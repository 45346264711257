import axios from "axios"
import { msalInstance,account } from "./main.jsx"
import { msalConfig } from "./authConfig"
import { cloneDeep } from "lodash"

const api_url = import.meta.env.VITE_APP_API_URL

async function getToken() {
  const currentAccount = msalInstance.getActiveAccount()
  const accessTokenRequest = {
    scopes: ["User.Read"],
    account: currentAccount,
  }
  if (currentAccount) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest,
    )
    return accessTokenResponse.accessToken
  }
  return null
}

export const callApi = async (endpoint, payload) => {
  console.log("")
  let tokenResponse = await getToken()
  console.log("tokenResponse", tokenResponse)
  let temp_payload=cloneDeep(payload)
  temp_payload.token=tokenResponse
  temp_payload.username=account.username.split("@")[0]
  let response = await axios.post(api_url + endpoint, temp_payload)
  console.log("reponse", response.data)
  return response
}
