import React, { useEffect, useRef, useState } from "react"
import { Button } from "antd"
import "./Home.css"
import { Link } from "react-router-dom"
import summary_dashboard from "../../assets/images/summary_dashboard.png"
import sequencing_dashboard from "../../assets/images/sequencing_dashboard.png"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import rome_home from "../../assets/images/rome_home.png"

const Home = () => {
  const videoRef = useRef(null)
  const [gifVisible, setGifVisisble] = useState(true)
  // const buttonsHeaders = [
  //   "PPP Swire Summary",
  //   "PPP Customer Summary",
  //   "PPP Sequencing Retail Single",
  //   "PPP Sequencing Promotion",
  //   "PPP Sequencing Volume",
  // ]
  // const navigateTo = [
  //   "/summary?Version=Swire",
  //   "/summary?Version=Customer",
  //   "/sequencing?Version=RetailPrice",
  //   "/sequencing?Version=Promotion",
  //   "/sequencing?Version=Volume",
  // ]
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
      setGifVisisble(true)
      if (videoRef.current.offsetHeight >  window.innerHeight) {
        // Apply negative margin only when the video overflows
        videoRef.current.style.marginTop = `-${
          (videoRef.current.offsetHeight -window.innerHeight+(window.innerHeight)/6.8)
        }px`
      } else {
        // Remove negative margin when there is no overflow
        videoRef.current.style.marginTop = "0"
      }
      // console.log("videoRef", videoRef)
      // console.log(
      //   "videoRef.current.scrollHeight",
      //   videoRef.current.offsetHeight,
      // )
      // console.log("videoRef.current.clientHeight", window.innerHeight)
      // console.log(
      //   "videoRef.current.scrollHeight > videoRef.current.clientHeight",
      //   videoRef.current.offsetHeight - window.innerHeight,
      // )
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.pause()
          setGifVisisble(false)
        }
      }, 8200)
    }
  }, [])
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: gifVisible ? `` : `url(${rome_home})`,
        // overflow: "hidden",
        position: "relative",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {gifVisible && (
        // <img style={{ width: "100%",height:"100vh" }} alt="ROME" src={Rome_gif} loading="lazy"/>
        <video
          ref={videoRef}
          width="100vw"
          height="auto"
          muted
          autoPlay
          style={{
            width: "100vw",
            height: "auto",
            display: "block",
            objectFit: "cover",
           
            // marginTop:"50px"
            // marginTop:"-30vh"
          }}
        >
          <source
            src="https://swireeradevtpoappstorage.blob.core.windows.net/homescreenvideo/home_swire_final.mp4?sp=r&st=2023-12-18T06:11:31Z&se=2026-05-05T14:11:31Z&spr=https&sv=2022-11-02&sr=b&sig=%2BAewL8sItVQSFrHywjkGDraAV5o1CJCt92cge%2BZqil8%3D"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}
      {/* <div className="button_container">
        {buttonsHeaders.map((btn, i) => (
          <Link to={`${navigateTo[i]}`}>
            <Button>{btn}</Button>
          </Link>
        ))}
      </div> */}
      {!gifVisible && (
        <div className="box_container">
          <div
            style={{
              color: "#E81C0E",
              fontSize: "1.8vw",
              fontWeight: "bold",
            }}
          >
            REVENUE OPTIMIZATION AND MARKETING ENGINE
          </div>
          <div style={{ display: "flex", gap: "6vw" }}>
            <Link style={{ textDecoration: "none" }} to="/summary">
              <div className="dashboard_container">
                <div className="label">
                  Summary
                  <ChevronRightIcon
                    style={{ marginLeft: "10px" }}
                  ></ChevronRightIcon>
                </div>
                <img src={summary_dashboard}></img>
                <div className="text_container">
                  Define Price/Promotion Scenarios
                </div>
              </div>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/sequencing">
              <div className="dashboard_container">
                <div className="label">
                  Sequencing
                  <ChevronRightIcon
                    style={{ marginLeft: "10px" }}
                  ></ChevronRightIcon>
                </div>
                <img src={sequencing_dashboard}></img>
                <div className="text_container">Assign scenarios to weeks</div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default Home
