import React from "react";
import { Select } from "antd";
import "./DropDown.css";
import {useDispatch } from 'react-redux';
import {updateRetailPlan} from "../../reducers/summaryTableDataUpdate.js"
import { cloneDeep } from "lodash";

const DropDown = ({
  promoMultiple,
  fullData,
  defaultVal,
  index,
  col
}) => {
  const options=promoMultiple.map(p=>{
    let obj={}
    obj.value=p
    obj.label=p
    return obj
  })
  const onSelectionChange = (e) => {
    console.log("e",e)
    let temp_data=cloneDeep(fullData)
    temp_data[index][col]=e
    dispatch(updateRetailPlan(temp_data))
  };
  const dispatch=useDispatch()
  return (
    <div className="drop_down" style={{display:"flex",justifyContent:"center"}}>
      <Select style={{width:"200px",display:"flex",justifyContent:"center"}}
        options={options}
        value={fullData[index][col].length==0?"-":fullData[index][col]}
        onChange={(e) => onSelectionChange(e)}
        popupMatchSelectWidth={false}
      ></Select>
    </div>
  );
};

export default DropDown;
