import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"

const initialState = {
  timePeriodTableData: { data: [], status: "", error: null },
  sequencingTableData: { data: {}, status: "", error: null, isUpdated: true },
  dynamicCountOfValues: {},
  updateScenarioStatus: { msg: {}, status: "" },
  changeScenarioDetails: { pack: "" },
  updatedPackage:""
}

export const getTimePeriodTableData = createAsyncThunk(
  "getTimePeriodTableData",
  async (payload) => callApi("api/v1/sequencing/get_holiday_data", payload),
)

export const getSequencingTableData = createAsyncThunk(
  "getSequencingTableData",
  async (payload) => callApi("api/v1/sequencing/get_sequencing_data", payload),
)

export const updateSequencingScenario = createAsyncThunk(
  "updateSequencingScenario",
  async (payload) => callApi("api/v1/scenario/update_scenario", payload),
)

export const changeSequencingScenario = createAsyncThunk(
  "changeSequencingScenario",
  async (payload) => callApi("api/v1/scenario/change_scenario", payload),
)

const sequencingTableData = createSlice({
  name: "sequencingTable",
  initialState,
  reducers: {
    setSequencingTableData: (state, action) => {
      state.sequencingTableData.data = action.payload
    },
    setpackageName: (state, action) => {
      state.changeScenarioDetails.pack = action.payload
    },
    setSequencingTableIsUpdated: (state, action) => {
      state.sequencingTableData.isUpdated = action.payload
    },
    setUpdatedPackage: (state, action) => {
      state.updatedPackage = action.payload
    },
    setSequencingTableEmpty: (state, action) => {
      state.sequencingTableData = {}
    },
    setDynamicCountOfValues: (state, action) => {
      let pack = action.payload
      let data = state.sequencingTableData.data
      let packages = Object.keys(data.active_scenario)
      let current_week=state.timePeriodTableData.data.current_week-1
      if (pack == "all") {
        let countObject = {}
        if (pack === "all") {
          packages.forEach((pack) => {
            let obj = {}
            data[pack].forEach((value,index) => {
              if(index>=current_week){
              let upperCaseValue=value.toUpperCase()
              if (obj[upperCaseValue]) obj[upperCaseValue] = obj[upperCaseValue] + 1
              else obj[upperCaseValue] = 1
              }
            })
            countObject[pack] = obj
          })
          state.dynamicCountOfValues = countObject
        } else {
          let obj = {}
          data[pack].forEach((value,index) => {
            if(index>=current_week){
              console.log("index",index)
            let upperCaseValue=value.toUpperCase()
            if (obj[upperCaseValue]) obj[upperCaseValue] = obj[upperCaseValue] + 1
            else obj[upperCaseValue] = 1
            }
          })
          countObject[pack] = obj
          state.dynamicCountOfValues = countObject
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimePeriodTableData.pending, (state) => {
        state.timePeriodTableData.status = "pending"
      })
      .addCase(getTimePeriodTableData.fulfilled, (state, action) => {
        state.timePeriodTableData.status = "fulfilled"
        state.timePeriodTableData.data = action.payload.data.data
      })
      .addCase(getTimePeriodTableData.rejected, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.timePeriodTableData.status = "rejected"
          state.timePeriodTableData.error = action.error.message
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting time period table data",
          })
        }
      })
      .addCase(getSequencingTableData.pending, (state) => {
        state.sequencingTableData.status = "pending"
      })
      .addCase(getSequencingTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.sequencingTableData.status = "fulfilled"
          state.sequencingTableData.data = action.payload.data.data
          state.updatedPackage=""
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting sequencing table data",
          })
        }
      })
      .addCase(getSequencingTableData.rejected, (state, action) => {
        state.sequencingTableData.status = "rejected"
        state.sequencingTableData.error = action.error.message
      })
      .addCase(changeSequencingScenario.pending, (state) => {
        state.sequencingTableData.status = "pending"
      })
      .addCase(changeSequencingScenario.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let packageName = state.changeScenarioDetails.pack
          let updatedData = state.sequencingTableData.data
          updatedData[packageName] = action.payload.data.data[packageName]
          updatedData[packageName + "_count"] =
            action.payload.data.data[packageName + "_count"]
          updatedData[packageName + "_multiple"] =
            action.payload.data.data[packageName + "_multiple"]
          updatedData[packageName + "_effective_retail"] =
            action.payload.data.data[packageName + "_effective_retail"]
          state.sequencingTableData.data = updatedData
          state.sequencingTableData.status = "fulfilled"
          Swal.fire({
            title: "Success",
            text: "Scenario Changed Successfully",
            icon: "success",
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while changing scenrio in sequencing table",
          })
        }
      })

      .addCase(changeSequencingScenario.rejected, (state, action) => {
        state.sequencingTableData.status = "rejected"
        state.sequencingTableData.error = action.payload
      })
      .addCase(updateSequencingScenario.pending, (state) => {
        state.updateScenarioStatus.status = "pending"
      })
      .addCase(updateSequencingScenario.fulfilled, (state, action) => {
        let status=action.payload.data.status
        if(status==200){
        state.updateScenarioStatus.status = "fulfilled"
        state.updateScenarioStatus.msg = action.payload.data.data
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while updating scenario",
          })
        }
      })
      .addCase(updateSequencingScenario.rejected, (state, action) => {
        state.updateScenarioStatus.status = "rejected"
        state.updateScenarioStatus.msg = action.payload
      })
  },
})

export const {
  setSequencingTableData,
  setDynamicCountOfValues,
  setSequencingTableIsUpdated,
  setpackageName,
  setUpdatedPackage,
  setSequencingTableEmpty
} = sequencingTableData.actions
export default sequencingTableData.reducer
