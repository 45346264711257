import React, { useState, forwardRef } from "react"
import DownloadIcon from "@mui/icons-material/Download"
import { Tooltip } from "antd"
import "./TimePeriodTable.css"

const TimePeriodTable = forwardRef((props, ref) => {
  const data = props.data
  const timeFrame = props.timeFrame
  const handleScroll = props.handleScroll
  const timeFrameConfigurations = {
    "Full Year": "full year",
    Q1: { start: 0, end: 12 },
    Q2: { start: 13, end: 25 },
    Q3: { start: 26, end: 38 },
    Q4: { start: 39, end: 52 },
  }
  const headers = ["period", "week"]
  const headersMapping = { period: "Period", week: "Week" }
  const colsSortBy = ["swire_week", "add_break", "holiday"]
  const colLabelMapping = {
    swire_week: "Swire Wk End",
    add_break: "Ad Break",
    holiday: "Holiday",
  }
  const tableDataColMapping = ["swire_week", "add_break", "holiday"]
  // const columnsWithoutWeekAndMonths = columns
  //   .filter((col) => col !== "week" && col !== "month" && col !== "retailer")
  //   .sort((a, b) => colsSortBy.indexOf(a) - colsSortBy.indexOf(b))
  // const FilterByTimeFrame = () => {
  //   if (timeFrame !== "Full Year" && timeFrameConfigurations[timeFrame]) {
  //     let filteredValues = data.slice(
  //       timeFrameConfigurations[timeFrame].start,
  //       timeFrameConfigurations[timeFrame].end + 1,
  //     )
  //     return filteredValues
  //   } else return data
  // }

  const visibilityAccordingToTimeFrame = (i) => {
    if (timeFrame) {
      let returnedValue = false
      if (timeFrame.includes("Full Year")) {
        console.log("full true")
        returnedValue = true
      } else {
        timeFrame.forEach((frame) => {
          if (!timeFrameConfigurations[frame]) {
            if (data[i]["period"]== frame) {
              console.log("period true")
              returnedValue = true
            }
          } else if (timeFrameConfigurations[frame]) {
            if (
              i >= timeFrameConfigurations[frame].start &&
              i <= timeFrameConfigurations[frame].end
            ) {
              console.log("i", i)
              console.log(
                "timeFrameConfigurations[frame].start",
                timeFrameConfigurations[frame].start,
              )
              console.log("q true")
              returnedValue = true
            }
          }
        })
      }
      return returnedValue
    }
  }

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
    var str = ""

    for (var i = 0; i < array.length; i++) {
      var line = ""
      for (var index in array[i]) {
        if (line !== "") line += ","

        line += array[i][index]
      }

      str += line + "\r\n"
    }
    console.log("str", str)
    return str
  }

  function exportCSVFile(data, fileTitle) {
    let headers1 = ["Period"]
    let headers2 = ["Week"]
    let col1 = [colsSortBy[0]]
    let col2 = [colsSortBy[1]]
    let col3 = [colsSortBy[2]]
    let arrayForExport = []
    data.forEach((row) => {
      headers1.push(row[headers1[0].toLowerCase()])
      headers2.push(row[headers2[0].toLowerCase()])
      col1.push(row[col1[0]])
      col2.push(row[col2[0]])
      col3.push(row[col3[0]])
    })
    arrayForExport.unshift(col1, col2, col3)
    arrayForExport.unshift(headers1, headers2)
    var jsonObject = JSON.stringify(arrayForExport)
    var csv = convertToCSV(jsonObject)

    var exportedFilenmae = fileTitle + ".csv" || "export.csv"

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      var link = document.createElement("a")
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilenmae)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  return (
    <div
      className="time_period_table"
      style={{
        borderRadius: "4px",
        backgroundColor: "white",
        padding: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingBottom: "10px",
        }}
      >
        <Tooltip title="Download">
          <DownloadIcon
            onClick={() => exportCSVFile(data, "calendar")}
            style={{ color: "#E81C0E", marginLeft: "auto" }}
          />
        </Tooltip>
      </div>
      <div
        style={{
          maxWidth: "100%",
          height: "36vh",
          overflow: "auto",
        }}
        onScroll={(e) => handleScroll(e.target.scrollLeft)}
        ref={ref}
      >
        <table style={{ width: "100%", height: "100%" }}>
          <thead>
            {headers.map((head, i) => (
              <tr key={i}>
                <th
                  className="sticky_col"
                  style={{
                    width: "378px",
                  }}
                >
                  {headersMapping[head]}
                </th>
                {data.map(
                  (row, j) =>
                    visibilityAccordingToTimeFrame(j) && (
                      <th key={j} style={{ width: "75px" }}>
                        {row[head]}
                      </th>
                    ),
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableDataColMapping.map((col, i) => (
              <tr key={i}>
                <td
                  className="sticky_col"
                  style={{
                    backgroundColor: "#C2D5FE",
                    borderBottom: "2px solid white",
                    width: "360px",
                  }}
                >
                  {colLabelMapping[col]}
                </td>
                {data.map(
                  (row, i) =>
                    visibilityAccordingToTimeFrame(i) && <td>{row[col]}</td>,
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
})
export default TimePeriodTable
