import React, { useState, useRef, useEffect, useMemo } from "react"
import TimePeriodTable from "../../components/sequencingScreenTable1/TimePeriodTable"
import SequencingTable from "../../components/sequencingPackageTable/SequencingTable"
import { useSelector, useDispatch } from "react-redux"
import { Spin } from "antd"
import MenuBox from "../../components/menubox/MenuBox"
import {
  getChannelList,
  getCustomersList,
  setPreviousMarketUnit,
  setPreviousCustomer,
  getMarketUnits,
  setSelectedOptions,
  getYearList,
  clearCategoryListAndSelectedOption,
  clearPackageListAndSelectedOption,
  clearMarketUnitandSelectedOption,
  clearScenarioListAndSelectedOption,
  getRegions,
} from "../../reducers/summaryScreenDropDown.js"
import {
  getSequencingTableData,
  getTimePeriodTableData,
  setSequencingTableEmpty
} from "../../reducers/sequencingTableData.js"
import {
  getCategories,
  setTimeFrames,
  setVersion,
  setSelectedVersionTimeFrame,
  setSequencingSelectedOption,
} from "../../reducers/sequencingScreenDropDown.js"
import { useLocation } from "react-router-dom"
import "./Sequencing.css"
import { sideBarOpen } from "../../reducers/sideBarToggle"
import SideBar from "../../components/sidebar/SideBar.jsx"
import Header from "../../components/header/Header.jsx"
import { cloneDeep } from "lodash"
import { getCookie } from "../../helper/getCookie.js"
import { useMsal } from "@azure/msal-react"

const Sequencing = () => {
  const side_bar_open = useSelector((state) => state.sidebar.open)
  const years = useSelector((state) => state.summmaryScreenDropDown.years.data)
  const yearsLoading = useSelector(
    (state) => state.summmaryScreenDropDown.years.status,
  )
  const channels = useSelector(
    (state) => state.summmaryScreenDropDown.channels.data,
  )
  const customers = useSelector(
    (state) => state.summmaryScreenDropDown.customers.data,
  )

  const regions = useSelector(
    (state) => state.summmaryScreenDropDown.regions.data,
  )

  const customerLoading = useSelector(
    (state) => state.summmaryScreenDropDown.customers.status,
  )
  const selectedOptionsList = useSelector(
    (state) => state.summmaryScreenDropDown.selectedOptions,
  )
  const category = useSelector(
    (state) => state.sequencingScreenDropDown.category.data,
  )
  const categoryLoader = useSelector(
    (state) => state.sequencingScreenDropDown.category.status,
  )
  const version = useSelector((state) => state.sequencingScreenDropDown.version)
  const timeframe = useSelector(
    (state) => state.sequencingScreenDropDown.timeFrame,
  )
  const selectedOptions = useSelector(
    (state) => state.sequencingScreenDropDown.sequencingSelectedOptions,
  )
  const sequencingTableData = useSelector(
    (state) => state.sequencingTable.sequencingTableData.data,
  )
  const timePeriodTableData = useSelector(
    (state) => state.sequencingTable.timePeriodTableData.data,
  )
  const sequencingTableDataLoader = useSelector(
    (state) => state.sequencingTable.sequencingTableData.status,
  )

  const marketUnits = useSelector(
    (state) => state.summmaryScreenDropDown.marketUnits.data,
  )
  const marketUnitsLoading = useSelector(
    (state) => state.summmaryScreenDropDown.marketUnits.status,
  )
  const retailPlanMetaData = useSelector(
    (state) => state.summarytable.retailPlanMetaPlan.data,
  )

  const previousCustomer = useSelector(
    (state) => state.summmaryScreenDropDown.previousCustomer,
  )
  const { accounts } = useMsal()
  const [updateLoding, setUpdateLoading] = useState(false)
  const [createLoding, setCreateLoading] = useState(false)
  const [sequencingTableAvailable, setSequencingTableAvailable] =
    useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const table1Ref = useRef(null)
  const table2Ref = useRef(null)
  const [initial, setInitial] = useState(0)
  const dispatch = useDispatch()
  let payload = {}
  const location = useLocation()
  const versionInQuery =
    location.search.length !== 0
      ? location.search.split("?")[1].split("=")[1]
      : ""
  const handleScroll = (position) => {
    console.log("position",position)
    if (table1Ref.current && table2Ref.current) {
      table1Ref.current.scrollLeft = position
      table2Ref.current.scrollLeft = position
    }
  }

  const loading =
    sequencingTableDataLoader !== "fulfilled" ||
    customerLoading !== "fulfilled" ||
    marketUnitsLoading !== "fulfilled" ||
    yearsLoading !== "fulfilled" ||
    categoryLoader !== "fulfilled"
  updateLoding || createLoding

  useEffect(() => {
    if (!selectedOptionsList.Year) {
      dispatch(getYearList(payload))
    }
    setInitial(1)
  }, [])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    temp_payload.year = selectedOptionsList.Year
    if (selectedOptionsList.Year && initial !== 0 && accounts) {
      dispatch(getChannelList(temp_payload))
      dispatch(setSequencingTableEmpty())
    }
  }, [selectedOptionsList.Year])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    temp_payload.year = selectedOptionsList.Year
    temp_payload.channel = selectedOptionsList.Channel
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Channel &&
      initial !== 0
    ) {
      dispatch(getCustomersList(temp_payload))
      dispatch(setSequencingTableEmpty())
    }
    dispatch(setSelectedVersionTimeFrame())
    if (
      versionInQuery == "Promotion" ||
      versionInQuery == "Volume" ||
      versionInQuery == "RetailPrice"
    ) {
      dispatch(
        setSequencingSelectedOption({
          label: "Version",
          option: versionInQuery,
        }),
      )
    }
  }, [selectedOptionsList.Channel])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      initial !== 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      dispatch(getRegions(temp_payload))
      dispatch(setSequencingTableEmpty())
    }
  }, [customers, selectedOptionsList.Customer])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      initial !== 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      dispatch(getMarketUnits(temp_payload))
      dispatch(setSequencingTableEmpty())
    }
  }, [regions, selectedOptionsList.Region])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"]
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      dispatch(getCategories(temp_payload))
      if (initial !== 0) {
        dispatch(setSelectedOptions({ label: "Category", option: null }))
        dispatch(clearPackageListAndSelectedOption())
        dispatch(setSequencingTableEmpty())
      }
    }
  }, [marketUnits, selectedOptionsList["Market Unit"]])

  useEffect(() => {
    if (selectedOptionsList.Category) {
      dispatch(
        setSequencingSelectedOption({
          label: "Category",
          option: selectedOptionsList.Category,
        }),
      )
    }
    return () => {
      // dispatch(clearPackageListAndSelectedOption())
      dispatch(clearScenarioListAndSelectedOption())
    }
  }, [category])

  useEffect(() => {
    if (selectedOptions.Category) {
      dispatch(setVersion())
      dispatch(
        setSequencingSelectedOption({ label: "Version", option: "Promotion" }),
      )
    }
  }, [category, selectedOptions.Category])

  useEffect(() => {
    setSequencingTableAvailable(true)
  }, [])

  useEffect(() => {
    if (sequencingTableData && sequencingTableData.period) {
      let periods = sequencingTableData.period
      dispatch(setTimeFrames(periods))
    }
  }, [sequencingTableData])
  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList["Customer"] &&
      selectedOptionsList.Region &&
      selectedOptions["Category"] &&
      selectedOptionsList["Market Unit"] 
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList["Customer"]
      temp_payload.region = selectedOptionsList.Region
      temp_payload.category = selectedOptions["Category"]
      temp_payload["market_unit"] = selectedOptionsList["Market Unit"]
      temp_payload.grid_type = selectedOptions["Version"]
      if (sequencingTableData && sequencingTableData.active_scenario) {
        temp_payload.active_scenario = sequencingTableData.active_scenario
       }
      dispatch(getTimePeriodTableData(temp_payload))
      dispatch(getSequencingTableData(temp_payload))
    }
  }, [version, selectedOptions["Version"]])

  useEffect(() => {
    if (loading) {
      dispatch(sideBarOpen(false))
    }
  }, [loading])

  return (
    <>
      <Header />
      <SideBar />
      <Spin
        className={loading ? "loading" : "not_loading"}
        spinning={loading}
        size="large"
      >
        <div
          style={{
            width: side_bar_open ? "72%" : "93.5%",
            left: side_bar_open ? "12%" : "0",
            position: "relative",
            margin: "auto",
            paddingTop: "16vh",
            marginBottom: isExpanded ? "0" : "6vh",
          }}
        >
          {!isExpanded && (
            <div className="menu_box_container">
              {channels && (
                <MenuBox
                  label="Channel"
                  options={channels}
                  screen="summary"
                ></MenuBox>
              )}
              {customers && (
                <MenuBox
                  label="Customer"
                  options={customers}
                  screen="sequencing"
                />
              )}
              {regions && (
                <MenuBox
                  label="Region"
                  options={regions}
                  screen="summary"
                ></MenuBox>
              )}
              {marketUnits && (
                <MenuBox
                  label="Market Unit"
                  options={marketUnits}
                  screen="sequencing"
                />
              )}
              {category.length > 0 && (
                <MenuBox
                  label="Category"
                  options={category}
                  screen="sequencing"
                />
              )}
              <MenuBox label="Version" options={version} screen="sequencing" />
              <MenuBox
                label="Time Frame"
                mode="multiple"
                options={timeframe}
                screen="sequencing"
              />
              {years && Object.keys(years).length > 0 && (
                <MenuBox
                  label="Year"
                  options={Object.keys(years)}
                  years={years}
                  screen="summary"
                />
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: isExpanded ? "-10px" : "40px",
            }}
          >
            {!isExpanded &&  timePeriodTableData &&
              Object.keys(timePeriodTableData).length > 0 && (
              <TimePeriodTable
                data={timePeriodTableData.holidays}
                versionName={selectedOptions["Version"]}
                timeFrame={selectedOptions["Time Frame"]}
                handleScroll={handleScroll}
                ref={table1Ref}
              ></TimePeriodTable>
            )}
            {sequencingTableAvailable &&
              sequencingTableData &&
              Object.values(sequencingTableData).length > 0 &&
              timePeriodTableData &&
              Object.keys(timePeriodTableData).length > 0 && (
                <SequencingTable
                  versionName={selectedOptions["Version"]}
                  timeFrame={selectedOptions["Time Frame"]}
                  handleScroll={handleScroll}
                  data={sequencingTableData}
                  current_week={timePeriodTableData.current_week}
                  ref={table2Ref}
                  setUpdateLoading={setUpdateLoading}
                  setCreateLoading={setCreateLoading}
                  setIsExpanded={setIsExpanded}
                  isExpanded={isExpanded}
                ></SequencingTable>
              )}
          </div>
        </div>
      </Spin>
    </>
  )
}

export default Sequencing
