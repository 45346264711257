import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import { cloneDeep } from "lodash"
import { sortedRows } from "../helper/static"
import Swal from "sweetalert2"

const initialState = {
  waterfallData: { data: [], status: "", error: null },
  accrualsData: { data: [], status: "", error: null },
  accuralsWithPromoData: { data: [], status: "", error: null },
  checkbookData: { data: [], status: "", error: null },
  retailPlanData: { data: [], status: "", error: null },
  retailPlanMetaPlan: { data: {}, status: "", error: null },
  kpiData: { data: [], status: "", error: null },
  optimizationStatus:{status:"",error:null}
}
export const getRetailPlanTableData = createAsyncThunk(
  "fetchRetailPlanTableData",
  async (payload) => callApi("api/v1/summary/get_retail_plan_data", payload),
)

export const getRetailPlanTableMetaData = createAsyncThunk(
  "getRetailPlanTableMetaData",
  (payload) => callApi("api/v1/summary/get_retail_plan_meta", payload),
)

export const getAccrualsTableData = createAsyncThunk(
  "getAccrualsTableData",
  async (payload) => callApi("api/v1/summary/get_accruals_data", payload),
)
export const getAccrualsWithPromoTableData = createAsyncThunk(
  "getAccrualsWithPromoTableData",
  async (payload) => callApi("api/v1/summary/get_accruals_with_promo", payload),
)
export const getCheckbookTableData = createAsyncThunk(
  "getCheckbookTableData",
  async (payload) => callApi("api/v1/summary/get_checkbook_data", payload),
)
export const getWaterfallTableData = createAsyncThunk(
  "getWaterfallTableData",
  (payload) => callApi("api/v1/summary/get_pricing_waterfall_data", payload),
)

export const getKpiData = createAsyncThunk("getKpiData", (payload) =>
  callApi("api/v1/summary/get_kpi_data", payload),
)

export const optimize_scenario = createAsyncThunk("optimize_scenario", (payload) =>
  callApi("api/v1/scenario/optimize_scenario", payload),
)

const summaryTableDataUpdate = createSlice({
  name: "summaryScreenTableData",
  initialState,
  reducers: {
    updateWaterfall: (state, action) => {
      state.waterfallData.data = action.payload
    },
    updateAccruals: (state, action) => {
      state.accrualsData.data = action.payload
    },
    updateRetailPlan: (state, action) => {
      state.retailPlanData.data = action.payload
    },
    updateKpiData: (state, action) => {
      state.kpiData.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRetailPlanTableData.pending, (state) => {
        state.retailPlanData.status = "pending"
      })
      .addCase(getRetailPlanTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.retailPlanData.status = "fulfilled"
          let temp_data = cloneDeep(action.payload.data.data)
          temp_data = temp_data.filter((v) => v.Category !== "New")
          temp_data.sort(
            (a, b) =>
              sortedRows.indexOf(b.Category) - sortedRows.indexOf(a.Category),
          )
          state.retailPlanData.data = temp_data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured while getting retail plan data",
          })
        }
      })
      .addCase(getRetailPlanTableData.rejected, (state, action) => {
        state.retailPlanData.status = "rejected"
        state.retailPlanData.error = action.error.message
      })
      .addCase(getWaterfallTableData.pending, (state) => {
        state.waterfallData.status = "pending"
      })
      .addCase(getWaterfallTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.waterfallData.status = "fulfilled"
          state.waterfallData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured while getting pricing waterfall data",
          })
        }
      })
      .addCase(getWaterfallTableData.rejected, (state, action) => {
        state.waterfallData.status = "rejected"
        state.waterfallData.error = action.error.message
      })
      .addCase(getAccrualsTableData.pending, (state) => {
        state.accrualsData.status = "pending"
      })
      .addCase(getAccrualsTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.accrualsData.status = "fulfilled"
          state.accrualsData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured while getting accruals table data",
          })
        }
      })
      .addCase(getAccrualsTableData.rejected, (state, action) => {
        state.accrualsData.status = "rejected"
        state.accrualsData.error = action.error.message
      })
      .addCase(getAccrualsWithPromoTableData.pending, (state) => {
        state.accuralsWithPromoData.status = "pending"
      })
      .addCase(getAccrualsWithPromoTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.accuralsWithPromoData.status = "fulfilled"
          state.accuralsWithPromoData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured while getting accruals with promo data",
          })
        }
      })
      .addCase(getAccrualsWithPromoTableData.rejected, (state, action) => {
        state.accuralsWithPromoData.status = "rejected"
        state.accuralsWithPromoData.error = action.error.message
      })
      .addCase(getCheckbookTableData.pending, (state) => {
        state.checkbookData.status = "pending"
      })
      .addCase(getCheckbookTableData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.checkbookData.status = "fulfilled"
          state.checkbookData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting checkbook data",
          })
        }
      })
      .addCase(getCheckbookTableData.rejected, (state, action) => {
        state.checkbookData.status = "rejected"
        state.checkbookData.error = action.error.message
      })
      .addCase(getKpiData.pending, (state) => {
        state.kpiData.status = "pending"
      })
      .addCase(getKpiData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.kpiData.status = "fulfilled"
          state.kpiData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting cards data",
          })
        }
      })
      .addCase(getKpiData.rejected, (state, action) => {
        state.kpiData.status = "rejected"
        state.kpiData.error = action.error.message
      })
      .addCase(getRetailPlanTableMetaData.pending, (state) => {
        state.retailPlanMetaPlan.status = "pending"
      })
      .addCase(getRetailPlanTableMetaData.fulfilled, (state, action) => {
        let status=action.payload.data.status
        if(status===200){
        state.retailPlanMetaPlan.status = "fulfilled"
        state.retailPlanMetaPlan.data = action.payload.data.data
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting retail plan meta data",
          })
      }
      })
      .addCase(getRetailPlanTableMetaData.rejected, (state, action) => {
        state.retailPlanMetaPlan.status = "rejected"
        state.retailPlanMetaPlan.error = action.error.message
      })
      .addCase(optimize_scenario.pending, (state) => {
        state.optimizationStatus.status = "pending"
      })
      .addCase(optimize_scenario.fulfilled, (state, action) => {
        let status=action.payload.data.status
        if(status===200){
          state.optimizationStatus.status="fulfilled"
            Swal.fire({
              icon: "success",
              title: "Success",
              confirmButtonColor: "#E21824",
              text: "Scenario succesfully optimized",
            })
        }
        else{
          state.optimizationStatus.status="fulfilled"
          state.optimizationStatus.error="Error Occured while optimizing the sceanrio"
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while optimizing the scenario",
          })
      }
      })
      .addCase(optimize_scenario.rejected, (state, action) => {
        state.optimizationStatus.status="rejected"
        state.optimizationStatus.error="Error Occured while optimizing the sceanrio"
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: "Error Occured while optimizing the sceanrio",
        })
      })
  },
})

export const {
  updateWaterfall,
  updateAccruals,
  updateRetailPlan,
  updateKpiData,
} = summaryTableDataUpdate.actions
export default summaryTableDataUpdate.reducer
