import React from "react"
import { Select } from "antd"
import "./MenuBox.css"
import { useSelector, useDispatch } from "react-redux"
import { setSelectedOptions } from "../../reducers/summaryScreenDropDown"
import { setSequencingSelectedOption } from "../../reducers/sequencingScreenDropDown"
import { setDataQualitySelectedOption } from "../../reducers/dataQuality"
import { setAdminSelectedOptions } from "../../reducers/comparisionScreen"
import { Tooltip } from "antd"

const MenuBox = ({ label, options, screen, years, mode }) => {
  const selectedOptions = useSelector(
    (state) => state.summmaryScreenDropDown.selectedOptions,
  )
  const sequencingSelectedOptions = useSelector(
    (state) => state.sequencingScreenDropDown.sequencingSelectedOptions,
  )
  const dataQualitySelectedOptions = useSelector(
    (state) => state.dataQuality.dataQualitySelectedOptions,
  )
  const comparisionScreenSelectedOptions = useSelector(
    (state) => state.comparisionScreen.selectedOptions,
  )
  const dispatch = useDispatch()
  const menuOptions = options.map((v) => {
    let obj = {}
    obj.value = v
    obj.label =
      label == "Customer" || label == "Package" || label == "Market Unit" || label == "Region"
        ? v.replace(/_/g, " ").toUpperCase()
        : v
    return obj
  })
  const onSelectionChange = (e) => {
    if (
      (label == "Customer" || screen == "summary" || label == "Market Unit") &&
      screen !== "Data Quality" && screen !== "Comparison"
    ) {
      dispatch(setSelectedOptions({ option: e, label: label }))
    } else if (screen == "sequencing") {
      dispatch(setSequencingSelectedOption({ option: e, label: label }))
    } else if (screen == "Data Quality") {
      dispatch(setDataQualitySelectedOption({ option: e, label: label }))
    } else if (screen == "Comparison") {
      dispatch(setAdminSelectedOptions({ option: e, label: label }))
    }
  }

  const setLabelToolTipTitle = () => {
    if (years && selectedOptions.Year !== null) {
      let selectedYearValuesyears = years[selectedOptions.Year]
      return (
        <div style={{ color: "#E81C0E", fontSize: "11px" }}>
          <li>
            Current :{" "}
            <span style={{ color: "blue" }}>
              {selectedYearValuesyears["current"]}
            </span>
          </li>
          <li>
            New :{" "}
            <span
              style={{
                color:
                  selectedYearValuesyears["next_available"] == 1
                    ? "blue"
                    : "grey",
              }}
            >
              {selectedYearValuesyears["next"]}
            </span>
          </li>
        </div>
      )
    }
    return ""
  }
  return (
    <div
      className="menu"
      style={{
        width:
          screen == "Data Quality"
            ? "30vw"
            : screen == "Admin Panel"
            ? "35vw"
            : "22.3vw",
      }}
    >
      <div
        style={{ marginBottom: "5px", fontSize: "1.1vw", fontWeight: "200" }}
      >
        { screen !== "Comparison" && <Tooltip title={setLabelToolTipTitle()}>{label}</Tooltip>}
        { screen == "Comparison" && <div>{label}</div>}
      </div>
      <Select
        className="menu_box"
        mode={mode ? mode : "single"}
        options={menuOptions}
        popupMatchSelectWidth={false}
        showSearch={true}
        value={
          (label == "Customer" &&
            screen !== "Data Quality" &&
            screen !== "Comparison") ||
          (label == "Market Unit" &&
            screen !== "Data Quality" &&
            screen !== "Comparison")
            ? selectedOptions[label]
            : screen == "summary"
            ? selectedOptions[label]
            : screen == "Data Quality"
            ? dataQualitySelectedOptions[label]
            : screen == "Comparison"
            ? comparisionScreenSelectedOptions[label]
            : sequencingSelectedOptions[label]
        }
        onChange={(e) => onSelectionChange(e)}
      ></Select>
    </div>
  )
}

export default MenuBox
