import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import waterfallReducer from "../reducers/summaryTableDataUpdate.js"
import sidebarReducer from "../reducers/sideBarToggle.js"
import summaryScreenDropDownReducer from "../reducers/summaryScreenDropDown.js"
import sequencingTableReducer from "../reducers/sequencingTableData.js"
import sequencingDropDownReducer from "../reducers/sequencingScreenDropDown.js"
import dataQualityReducer from "../reducers/dataQuality.js"
import adminScreenReducer from "../reducers/adminScreen.js"
import comparisionScreenReducer from "../reducers/comparisionScreen"

export const store = configureStore({
  reducer: {
    summarytable:waterfallReducer,
    sidebar:sidebarReducer,
    summmaryScreenDropDown:summaryScreenDropDownReducer,
    sequencingTable:sequencingTableReducer,
    sequencingScreenDropDown:sequencingDropDownReducer,
    dataQuality:dataQualityReducer,
    adminScreen:adminScreenReducer,
    comparisionScreen:comparisionScreenReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
