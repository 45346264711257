export const retail_plan_data = {
  shape: {
    width: "100%",
    height: "300px",
  },
  price_multiple: [
    "",
    "10 for",
    "15 for",
    "2 for",
    "3 for",
    "4 for",
    "5 for",
    "6 for",
    "7 for",
    "B1G1",
    "B2G1",
    "B2G2",
    "B2G3",
    "B2S2",
    "B3G2",
    "B3S2",
    "B3S3",
    "B5S5",
    "ea. wyb 2",
    "ea. wyb 3",
    "ea. wyb 4",
    "ea. wyb 5",
  ],
  invoice_prices: { 0: 26.88, 1: 27.88 },
  data: [
    {
      Category: "White Tag",
      edv: 2.99,
      P1_A: 2.99,
      P1_B: 2.99,
      P1_C: 2.99,
      P1_D: 2.99,
      P2_A: 2.99,
      P2_B: 2.99,
      P2_C: 2.99,
      P2_D: 2.99,
      Holiday: 2.99,
      Total: "12",
    },
    {
      Category: "Retail Multiple",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: "",
    },
    {
      Category: "Price",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: "",
    },
    {
      Category: "Multiple",
      edv: "",
      P1_A: "",
      P1_B: "",
      P1_C: "",
      P1_D: "",
      P2_A: "",
      P2_B: "",
      P2_C: "",
      P2_D: "",
      Holiday: "",
      Total: "55",
    },
    {
      Category: "Take Rate",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: "",
    },
    {
      Category: "Discount vs EDV",
      edv: "",
      P1_A: "0.00%",
      P1_B: "0.00%",
      P1_C: "0.00%",
      P1_D: "0.00%",
      P2_A: "0.00%",
      P2_B: "0.00%",
      P2_C: "0.00%",
      P2_D: "0.00%",
      Holiday: "0.00%",
      Total: "",
    },
    {
      Category: "Effective Retail",
      edv: 2.99,
      P1_A: 2.99,
      P1_B: 2.99,
      P1_C: 2.99,
      P1_D: 2.99,
      P2_A: 2.99,
      P2_B: 2.99,
      P2_C: 2.99,
      P2_D: 2.99,
      Holiday: 2.99,
      Total: 0,
    },
    {
      Category: "Volume / Event (Cases)",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: 0,
    },
    {
      Category: "Event Frequency (Wks)",
      edv: 52,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: 52,
    },
    {
      Category: "Invoice",
      edv: 23.0,
      P1_A: 23.0,
      P1_B: 23.0,
      P1_C: 23.0,
      P1_D: 23.0,
      P2_A: 23.0,
      P2_B: 23.0,
      P2_C: 23.0,
      P2_D: 23.0,
      Holiday: 23.0,
      Total: "",
    },
    {
      Category: "Allowances (All/Promo)",
      edv: "All",
      P1_A: "All",
      P1_B: "All",
      P1_C: "All",
      P1_D: "All",
      P2_A: "All",
      P2_B: "All",
      P2_C: "All",
      P2_D: "All",
      Holiday: "A11",
      Total: "",
    },
    {
      Category: "Trade Allowance per case",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: 0,
    },
    {
      Category: "Other",
      edv: 0,
      P1_A: 0,
      P1_B: 0,
      P1_C: 0,
      P1_D: 0,
      P2_A: 0,
      P2_B: 0,
      P2_C: 0,
      P2_D: 0,
      Holiday: 0,
      Total: 0,
    },
    {
      Category: "EDV",
      edv: "false",
      P1_A: "false",
      P1_B: "false",
      P1_C: "false",
      P1_D: "false",
      P2_A: "false",
      P2_B: "false",
      P2_C: "false",
      P2_D: "false",
      Holiday: "false",
      Total: "",
    },
    {
      Category: "Net Invoice Cost",
      edv: 23.0,
      P1_A: 23.0,
      P1_B: 23.0,
      P1_C: 23.0,
      P1_D: 23.0,
      P2_A: 23.0,
      P2_B: 23.0,
      P2_C: 23.0,
      P2_D: 23.0,
      Holiday: 23.0,
      Total: 0,
    },
    {
      Category: "Net Cost (Unit)",
      edv: 1.9166666666666667,
      P1_A: 1.9166666666666667,
      P1_B: 1.9166666666666667,
      P1_C: 1.9166666666666667,
      P1_D: 1.9166666666666667,
      P2_A: 1.9166666666666667,
      P2_B: 1.9166666666666667,
      P2_C: 1.9166666666666667,
      P2_D: 1.9166666666666667,
      Holiday: 1.9166666666666667,
      Total: 0,
    },
    {
      Category: "Invoice Cost @ 100% Take Rate",
      edv: 23.0,
      P1_A: 23.0,
      P1_B: 23.0,
      P1_C: 23.0,
      P1_D: 23.0,
      P2_A: 23.0,
      P2_B: 23.0,
      P2_C: 23.0,
      P2_D: 23.0,
      Holiday: 23.0,
      Total: 0,
    },
    {
      Category: "Customer Margin ($/Unit)",
      edv: 1.0733333333333335,
      P1_A: 1.0733333333333335,
      P1_B: 1.0733333333333335,
      P1_C: 1.0733333333333335,
      P1_D: 1.0733333333333335,
      P2_A: 1.0733333333333335,
      P2_B: 1.0733333333333335,
      P2_C: 1.0733333333333335,
      P2_D: 1.0733333333333335,
      Holiday: 1.0733333333333335,
      Total: "",
    },
    {
      Category: "Customer Margin %",
      edv: "35.90%",
      P1_A: "35.90%",
      P1_B: "35.90%",
      P1_C: "35.90%",
      P1_D: "35.90%",
      P2_A: "35.90%",
      P2_B: "35.90%",
      P2_C: "35.90%",
      P2_D: "35.90%",
      Holiday: "35.90%",
      Total: "0.00%",
    },
    {
      Category: "Current",
      edv: "false",
      P1_A: "false",
      P1_B: "false",
      P1_C: "false",
      P1_D: "false",
      P2_A: "false",
      P2_B: "false",
      P2_C: "false",
      P2_D: "false",
      Holiday: "false",
      Total: "",
    },
    {
      Category: "New",
      edv: "false",
      P1_A: "false",
      P1_B: "false",
      P1_C: "false",
      P1_D: "false",
      P2_A: "false",
      P2_B: "false",
      P2_C: "false",
      P2_D: "true",
      Holiday: "false",
      Total: "",
    },
  ],
  py_guardrail: { py_margin: 0.33, Guadrail: 5.4 },
}

export const summary_table_accurals = {
  Accruals: ["CCF Regional Accrual", "CTM Local Accrual", "Flex", "DNNSI"],
  P1_A: [1, 0, 0, 0],
  "New Pricing": [0, 2, 0, 0],
  "Change $": [0, 0, 4, 0],
  "Change%": [0, 0, 9, 0],
}

export const summary_table_waterfall = {
  "Pricing Waterfall": [
    "CCF Regional Accrual",
    "CTM Local Accrual",
    "Flex",
    "DNNSI",
  ],
  P1_A: [1, 0, 0, 0],
  "New Pricing": [0, 2, 0, 0],
  "Change $": [0, 0, 4, 0],
  "Change%": [0, 0, 9, 0],
}

export const summary_table_accrual_type = {
  Accruals: ["CCF Regional Accrual", "CTM Local Accrual", "Flex", "DNNSI"],
  P1_A: [1, 0, 0, 0],
  "New Pricing": [0, 2, 0, 0],
  "Change $": [0, 0, 4, 0],
  "Change%": [0, 0, 9, 0],
}

export const summary_table_checkbook = {
  Checkbook: ["CCF Regional Accrual", "CTM Local Accrual", "Flex", "DNNSI"],
  P1_A: [1, 0, 0, 0],
  "New Pricing": [0, 2, 0, 0],
  "Change $": [0, 0, 4, 0],
  "Change%": [0, 0, 9, 0],
}

export const card_component_data = {
  shape: {
    width: "100%",
    height: "60px",
    is_horizontal: "false",
  },
  data: [
    "4.56",
    "12.34",
    "8.98",
    "7.67",
    "4.56",
    "12.34",
    "8.98",
    "7.67",
    "4.56",
    "12.34",
  ],
  label: [
    "Current FY DNNSI",
    "Rate Needed",
    "FY New Target",
    "Planned to Target Difference",
    "Current FY Customer Margin",
    "Predicted Customer Margin",
    "Current FY Volume",
    "Predicted Volume",
    "Current FY Volume(Unit)",
    "Predicted Volume(Unit)",
  ],
}

export const waterfallEditableCells = {
  "New Pricing": { 1: true, 3: true },
  "Current(Enterable)": { 0: true, 1: true, 3: true },
}

export const accrualsEditableCells = {
  "New Pricing": { 0: true, 1: true, 2: true, 3: true, 4: true },
  "Current(Enterable)": { 0: true, 1: true, 2: true, 3: true, 4: true },
}

export const cardComponentDataHeaders = [
  "Current FY DNNSI",
  "Current FY DNNSI(Enterable)",
  "Rate Needed",
  "FY New Target",
  "New Pricing DNNSI",
  "Planned to Target Difference",
  "Current FY Customer Margin",
  "Predicted Customer Margin",
  "Change customer margin",
  "Current FY Volume",
  "Predicted Volume",
  "% Change volume",
  "Current FY Volume(Unit)",
  "Predicted Volume(Unit)",
  "% Change volume(unit)",
]

export const sortedRows = ["New", "Current", "EDV"]

export const sequencing_table_one_data = {
  data: {
    week: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    ],
    holiday: [
      "",
      "",
      "",
      "",
      "",
      "superbowl",
      "",
      "",
      "",
      "",
      "",
      "",
      "easter",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "memorial_day",
      "",
      "",
      "",
      "",
      "independence_day",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "labor_day",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "thanksgiving",
      "",
      "",
      "",
      "christmas",
      "",
    ],
    swire_week: [
      "2024-01-05",
      "2024-01-12",
      "2024-01-19",
      "2024-01-26",
      "2024-02-02",
      "2024-02-09",
      "2024-02-16",
      "2024-02-23",
      "2024-03-01",
      "2024-03-08",
      "2024-03-15",
      "2024-03-22",
      "2024-03-29",
      "2024-04-05",
      "2024-04-12",
      "2024-04-19",
      "2024-04-26",
      "2024-05-03",
      "2024-05-10",
      "2024-05-17",
      "2024-05-24",
      "2024-05-31",
      "2024-06-07",
      "2024-06-14",
      "2024-06-21",
      "2024-06-28",
      "2024-07-05",
      "2024-07-12",
      "2024-07-19",
      "2024-07-26",
      "2024-08-02",
      "2024-08-09",
      "2024-08-16",
      "2024-08-23",
      "2024-08-30",
      "2024-09-06",
      "2024-09-13",
      "2024-09-20",
      "2024-09-27",
      "2024-10-04",
      "2024-10-11",
      "2024-10-18",
      "2024-10-25",
      "2024-11-01",
      "2024-11-08",
      "2024-11-15",
      "2024-11-22",
      "2024-11-29",
      "2024-12-06",
      "2024-12-13",
      "2024-12-20",
      "2024-12-31",
    ],
    add_break: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    month: [
      "Jan",
      "Jan",
      "Jan",
      "Jan",
      "Feb",
      "Feb",
      "Feb",
      "Feb",
      "Mar",
      "Mar",
      "Mar",
      "Mar",
      "Mar",
      "Apr",
      "Apr",
      "Apr",
      "Apr",
      "May",
      "May",
      "May",
      "May",
      "Jun",
      "Jun",
      "Jun",
      "Jun",
      "Jun",
      "Jul",
      "Jul",
      "Jul",
      "Jul",
      "Aug",
      "Aug",
      "Aug",
      "Aug",
      "Sep",
      "Sep",
      "Sep",
      "Sep",
      "Sep",
      "Oct",
      "Oct",
      "Oct",
      "Oct",
      "Nov",
      "Nov",
      "Nov",
      "Nov",
      "Dec",
      "Dec",
      "Dec",
      "Dec",
      "Dec",
    ],
    retailer: [
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
      "bashas",
    ],
  },
  editable_cell: {
    "New Pricing": [0, 1, 2, 3],
  },
  shape: {
    no_rows: 5,
    no_cols: 5,
    width: "100%",
    height: "200px",
    landscape: "true",
  },
  timeframe: "Full Year",
}

export const sequencing_table_data = {
  package_list: {
    "ssd_cans_7.5z_10pk_3ct": [
      "optimization1",
      "optimization2",
      "optimization3",
    ],
    "ssd_cans_7.5z_6pk_4ct": [
      "optimization1",
      "optimization2",
      "optimization3",
    ],
    ssd_cans_core_12z_12pk_2ct: ["optimization1"],
    "ssd_nr_.5l_6pk_4ct": ["optimization1", "optimization2", "optimization3"],
  },
  data: {
    Period: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    ],
    "ssd_cans_7.5z_10pk_3ct": [
      "edv",
      "edv",
      "p1",
      "p1",
      "p1",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
    ],
    "ssd_cans_7.5z_10pk_3ct_multiple": [
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
      "{'edv': '', 'p1': '2 for'}",
    ],
    "ssd_cans_7.5z_10pk_3ct_count": [
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 42, 'p1': 10, 'label': 'Event Frequency (Wks)'}]",
    ],
    month: [
      "Jan",
      "Jan",
      "Jan",
      "Jan",
      "Feb",
      "Feb",
      "Feb",
      "Feb",
      "Mar",
      "Mar",
      "Mar",
      "Mar",
      "Mar",
      "Apr",
      "Apr",
      "Apr",
      "Apr",
      "May",
      "May",
      "May",
      "May",
      "Jun",
      "Jun",
      "Jun",
      "Jun",
      "Jun",
      "Jul",
      "Jul",
      "Jul",
      "Jul",
      "Aug",
      "Aug",
      "Aug",
      "Aug",
      "Sep",
      "Sep",
      "Sep",
      "Sep",
      "Sep",
      "Oct",
      "Oct",
      "Oct",
      "Oct",
      "Nov",
      "Nov",
      "Nov",
      "Nov",
      "Dec",
      "Dec",
      "Dec",
      "Dec",
      "Dec",
    ],
    "ssd_cans_7.5z_6pk_4ct": [
      "edv",
      "p2",
      "p1",
      "p1",
      "p1",
      "p1",
      "p2",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p2",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p2",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p2",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p1",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p1",
      "edv",
      "edv",
      "p1",
      "edv",
      "edv",
      "p1",
      "p1",
      "edv",
      "edv",
      "p1",
    ],
    "ssd_cans_7.5z_6pk_4ct_multiple": [
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
      "{'edv': '', 'p1': '2 for', 'p2': 'B2G1'}",
    ],
    "ssd_cans_7.5z_6pk_4ct_count": [
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 27, 'p1': 20, 'p2': 5, 'label': 'Event Frequency (Wks)'}]",
    ],
    ssd_cans_core_12z_12pk_2ct: [
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
      "p1",
      "p2",
      "p1",
    ],
    ssd_cans_core_12z_12pk_2ct_multiple: [
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
      "{'p1': 'WYB3', 'p2': 'WYB4'}",
    ],
    ssd_cans_core_12z_12pk_2ct_count: [
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'p1': 17, 'label': 'Event Frequency (Wks)'}]",
    ],
    "ssd_nr_.5l_6pk_4ct": [
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p1",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
      "p2",
    ],
    "ssd_nr_.5l_6pk_4ct_multiple": [
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
      "{'p1': '3 for', 'p2': '4 for'}",
    ],
    "ssd_nr_.5l_6pk_4ct_count": [
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
      "[{'index': 'event_freq', 'edv': 35, 'shoulder': 9, 'holiday': 8, 'label': 'Event Frequency (Wks)'}]",
    ],
  },
  active_scenario: {
    "ssd_cans_7.5z_10pk_3ct": "optimization1",
    "ssd_cans_7.5z_6pk_4ct": "optimization3",
    ssd_cans_core_12z_12pk_2ct: "optimization1",
    "ssd_nr_.5l_6pk_4ct": "optimization3",
  },
}

export const visibleCategoryEvenWhenCollapsed = [
  "White Tag",
  "Retail Multiple",
  "Multiple",
  "Volume / Event (Cases)",
  "Event Frequency (Wks)",
  "Customer Margin %",
]

export const retailPlanToolTipTitles = {
  Price: "Retail Multiple ($) * Multiple",
  "Discount vs EDV": `0 for EDV \n 1 - Retail multiple (Promo)/Retail multiple (EDV) based on current or new`,
  "Effective Retail": "White Tag*(1-Take rate)+Retail Multiple*(Take Rate)",
  "Pricing Waterfall":
    "Pricing Waterfall based on current or new (whatever is checked)",
  "Net Invoice Cost": `(Allowance = All, or Promo)\n if allowance = all, NIC = invoice - trade allowance - other \nelse NIC = invoice - (trade allowance + other)*take rate`,
  "Net Unit Cost": "Net invoice cost / units per event",
  "Customer Margin ($/Unit)": "effective retail - net unit cost",
  "Customer Margin %": "cust margin unit / effective retail",
  Invoice: "if EDV is checked, current [PW] \nelse New [PW]",
}

export const accrualsToolTipTitles = {
  Accruals:
    "If EDV or new, populates the new enterable accruals from the left \nif current, populates the current enterable accruals from the left",

  DNNSI:
    "Checkbook = Invoice price - accruals\nNon checkbook = net invoice cost - accruals",
}

export const accruals1ToolTipTitles = {
  "Trade Spend Plug":
    "Current: Invoice price - DNNSI - SUM(all accruals) New: \nif checkbook, 0 \nelse Invoice price - DNNSI",
}

export const checkbookToolTipTitles = {
  "CCF Accrual": "CCF Regional accrual * Volume * Event frequency",
  Spend:
    "if allowance = All, then spend = -1 *(Trade allowance)  *(Volume)  *(event frequency) \nelse -1*(trade allowance)*(volume)*(event frequency)*(Take rate)",
  Balance: "CCF Accrual - Spend",
}

export const kpiDataToolTipTitles = {
  "FY New Target": "Current FY DNNSI - Rate Needed",
  "Planned to Target Difference": "New Pricing DNNSI - FY New Target",
}

export const waterfallToolTipTitles = {
  Discount: "List price - JBP allowance - Invoice Price",
}

export const dataQualitycolumns = [
  { title: "Customer", key: "Customer", dataIndex: "Customer" },
  { title: "Market Unit", key: "Market Unit", dataIndex: "Market Unit" },
  { title: "Package", key: "Package", dataIndex: "Package" },
  { title: "DATA_SOURCE", key: "DATA_SOURCE", dataIndex: "DATA_SOURCE" },
  { title: "COLUMN", key: "COLUMN", dataIndex: "COLUMN" },
  { title: "AVAILABLE", key: "AVAILABLE", dataIndex: "AVAILABLE" },
]

export const comparisionDataColumns=[
  { title: "Matrices", key: "Labels", dataIndex: "Labels" },
  { title: "Scenario1 Values", key: "Scenario1 Value", dataIndex: "Scenario1 Value" },
  { title: "Scenario2 Values", key: "Scenario2 Value", dataIndex: "Scenario2 Value" },
  { title: "Difference", key: "Difference", dataIndex: "Difference" },
  // { title: "Conclusion", key: "Conclusion", dataIndex: "Conclusion" },
]


export const formatString=(str)=>{
  let underscoreRemovedStr=str.split("_").map(v=>v[0].toUpperCase()+v.slice(1).toLowerCase())
  return underscoreRemovedStr.join(" ")
}