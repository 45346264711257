import React, { useState, useEffect } from "react"
import MenuBox from "../../components/menubox/MenuBox"
import { useSelector, useDispatch } from "react-redux"
import SideBar from "../../components/sidebar/SideBar"
import Header from "../../components/header/Header"
import { cloneDeep } from "lodash"
import { getCookie } from "../../helper/getCookie"
import { useMsal } from "@azure/msal-react"
import {
  compareScenarios,
  admin_getKpiData,
  admin_getYearList,
  admin_getCategoryList,
  admin_getCustomersList,
  admin_getChannelList,
  admin_getMarketUnits,
  admin_getPackageList,
  admin_getScenarioList,
  compare_getRegions
} from "../../reducers/comparisionScreen"
import { Button, Table, Spin } from "antd"
import { comparisionDataColumns } from "../../helper/static"
import "./ComparisionScreen.css"
import Swal from "sweetalert2"
import InfoIcon from "@mui/icons-material/Info"
import { Tooltip } from "@mui/material"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

const Comparision = () => {
  const side_bar_open = useSelector((state) => state.sidebar.open)
  const [scenarioOne, setScenarioOne] = useState({})
  const [scenarioTwo, setScenarioTwo] = useState({})
  const [isScenarioOneSelected, setIsSenarioOneSelected] = useState(false)
  const [isScenarioTwoSelected, setIsSenarioTwoSelected] = useState(false)
  const dispatch = useDispatch()
  const channelList = useSelector(
    (state) => state.comparisionScreen.channels.data,
  )
  const customersList = useSelector(
    (state) => state.comparisionScreen.customers.data,
  )
  const regions = useSelector(
    (state) => state.comparisionScreen.regions.data,
  )
  const marketUnits = useSelector(
    (state) => state.comparisionScreen.marketUnits.data,
  )

  const category = useSelector((state) => state.comparisionScreen.category.data)
  const years = useSelector((state) => state.comparisionScreen.years.data)

  const packagesList = useSelector(
    (state) => state.comparisionScreen.packages.data,
  )
  const scenariosList = useSelector(
    (state) => state.comparisionScreen.scenarios.data,
  )

  const checkbookOptions = useSelector(
    (state) => state.comparisionScreen.checkbook.data,
  )

  const selectedOptionsList = useSelector(
    (state) => state.comparisionScreen.selectedOptions,
  )
  const kpiData = useSelector((state) => state.comparisionScreen.kpiData.data)
  const comparisionData = useSelector(
    (state) => state.comparisionScreen.comparisionData.data,
  )

  const comparisionPromotionData = useSelector(
    (state) => state.comparisionScreen.comparisionData.promotionData,
  )
  const comparisionPromotionColumns = useSelector(
    (state) => state.comparisionScreen.comparisionData.promotionColumns,
  )

  const channelListStatus = useSelector(
    (state) => state.comparisionScreen.channels.status,
  )
  const customersListStatus = useSelector(
    (state) => state.comparisionScreen.customers.status,
  )
  const marketUnitsDStatus = useSelector(
    (state) => state.comparisionScreen.marketUnits.status,
  )

  const categoryStatus = useSelector(
    (state) => state.comparisionScreen.category.status,
  )
  const yearsStatus = useSelector(
    (state) => state.comparisionScreen.years.status,
  )

  const packagesListStatus = useSelector(
    (state) => state.comparisionScreen.packages.status,
  )
  const scenariosListStatus = useSelector(
    (state) => state.comparisionScreen.scenarios.status,
  )

  const kpiDataStatus = useSelector(
    (state) => state.comparisionScreen.kpiData.status,
  )
  const comparisionDataStatus = useSelector(
    (state) => state.comparisionScreen.comparisionData.status,
  )

  const loading =
    channelListStatus !== "fulfilled" ||
    kpiDataStatus !== "fulfilled" ||
    scenariosListStatus !== "fulfilled" ||
    packagesListStatus !== "fulfilled" ||
    yearsStatus !== "fulfilled" ||
    categoryStatus !== "fulfilled" ||
    marketUnitsDStatus !== "fulfilled" ||
    customersListStatus !== "fulfilled"

  const comparisionDataTableLoading = comparisionDataStatus !== "fulfilled"

  let payload = {}
  const { accounts } = useMsal()

  const compare_scenarios = () => {
    if (
      scenarioOne.scenarioName &&
      scenarioTwo.scenarioName &&
      scenarioOne.scenarioName.length > 0 &&
      scenarioTwo.scenarioName.length > 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload["scenario1"] = scenarioOne.kpiData
      temp_payload["scenario1_info"] = scenarioOne.scenario1_info
      temp_payload["scenario2"] = scenarioTwo.kpiData
      temp_payload["scenario2_info"] = scenarioTwo.scenario2_info
      dispatch(compareScenarios(temp_payload))
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#E21824",
        text: "Please select scenarios",
      })
    }
  }

  const setSelectedScenarioOne = () => {
    let value = {}
    value.customer = selectedOptionsList.Customer
    value.scenarioName = selectedOptionsList.Scenario
    ;(value.scenario1_info = {
      checkbook: selectedOptionsList.Checkbook,
      customer: selectedOptionsList.Customer,
      market_unit: selectedOptionsList["Market Unit"],
      package: selectedOptionsList.Package,
      scenario: selectedOptionsList.Scenario,
      year: selectedOptionsList.Year,
      region:selectedOptionsList.Region
    }),
      (value.kpiData = kpiData)
    console.log("scenarionOne", value)
    setScenarioOne(value)
    setIsSenarioOneSelected(true)
  }

  const filterFields=(obj)=>{
    let temp_obj=cloneDeep(obj)
   delete temp_obj.year
   delete temp_obj.checkbook
   return temp_obj
  }

  const onDeselectingScenarioOne = () => {
    setScenarioOne({})
    setIsSenarioOneSelected(false)
  }

  const setSelectedScenarioTwo = () => {
    let value = {}
    value.customer = selectedOptionsList.Customer
    value.scenarioName = selectedOptionsList.Scenario
    ;(value.scenario2_info = {
      checkbook: selectedOptionsList.Checkbook,
      customer: selectedOptionsList.Customer,
      market_unit: selectedOptionsList["Market Unit"],
      package: selectedOptionsList.Package,
      scenario: selectedOptionsList.Scenario,
      year: selectedOptionsList.Year,
      region:selectedOptionsList.Region
    }),
      (value.kpiData = kpiData)
    setScenarioTwo(value)
    setIsSenarioTwoSelected(true)
  }

  const onDeselectingScenarioTwo = () => {
    setScenarioTwo({})
    setIsSenarioTwoSelected(false)
  }

  useEffect(() => {
    if (!selectedOptionsList.Year) {
      dispatch(admin_getYearList(payload))
    }
    // setInitial(1)
    console.log("years", Object.keys(years))
  }, [])

  useEffect(() => {
    if (selectedOptionsList.Year && accounts) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      dispatch(admin_getChannelList(temp_payload))
      //   setInitial(1)
    }
  }, [selectedOptionsList.Year])

  useEffect(() => {
    if (selectedOptionsList.Year && selectedOptionsList.Channel) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.channel = selectedOptionsList.Channel
      dispatch(admin_getCustomersList(temp_payload))
    }
  }, [selectedOptionsList.Channel])

  useEffect(() => {
    if (selectedOptionsList.Year && selectedOptionsList.Customer) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      dispatch(compare_getRegions(temp_payload))
    }
  }, [customersList, selectedOptionsList.Customer])

  useEffect(() => {
    if (selectedOptionsList.Year && selectedOptionsList.Customer && selectedOptionsList.Region) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region=selectedOptionsList.Region
      dispatch(admin_getMarketUnits(temp_payload))
    }
  }, [regions ,selectedOptionsList.Region])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"]
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region=selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      dispatch(admin_getCategoryList(temp_payload))
    }
  }, [marketUnits, selectedOptionsList["Market Unit"]])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Category
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region=selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.category = selectedOptionsList.Category
      dispatch(admin_getPackageList(temp_payload))
    }
  }, [category, selectedOptionsList.Category])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList.Package &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Category
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region=selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      dispatch(admin_getScenarioList(temp_payload))
    }
  }, [packagesList, selectedOptionsList.Package])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Package &&
      selectedOptionsList.Scenario
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region=selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      temp_payload.scenario = selectedOptionsList.Scenario
      temp_payload.checkbook = "yes"

      dispatch(admin_getKpiData(temp_payload))
    }
  }, [scenariosList, selectedOptionsList.Scenario])

  return (
    <div>
      <Header></Header>
      <SideBar></SideBar>
      <div
        style={{
          width: side_bar_open ? "72%" : "93.5%",
          left: side_bar_open ? "12%" : "0",
          position: "relative",
          margin: "auto",
          paddingTop: "15vh",
          marginBottom: "5vh",
        }}
      >
        <Spin spinning={loading}>
          <div className="menu_box_container">
            {channelList && (
              <MenuBox
                label="Channel"
                options={channelList}
                screen="Comparison"
              ></MenuBox>
            )}
            {customersList && (
              <MenuBox
                label="Customer"
                options={customersList}
                screen="Comparison"
              ></MenuBox>
            )}
            {regions && (
              <MenuBox
                label="Region"
                options={regions}
                screen="Comparison"
              ></MenuBox>
            )}
            {marketUnits && (
              <MenuBox
                label="Market Unit"
                options={marketUnits}
                screen="Comparison"
              ></MenuBox>
            )}
            {category && (
              <MenuBox
                label="Category"
                options={category}
                screen="Comparison"
              ></MenuBox>
            )}
            {packagesList && (
              <MenuBox
                label="Package"
                options={packagesList}
                screen="Comparison"
              ></MenuBox>
            )}
            {scenariosList && (
              <MenuBox
                label="Scenario"
                options={scenariosList}
                screen="Comparison"
              ></MenuBox>
            )}
            <MenuBox
              label="Year"
              options={Object.keys(years)}
              years={years}
              screen="Comparison"
            ></MenuBox>
            <MenuBox
              label="Checkbook"
              options={checkbookOptions}
              screen="Comparison"
            ></MenuBox>
          </div>
          <div className="scenario_selection_container">
            <div className="button_info_container">
              <Button
                className={`scenario_selection ${
                  scenarioOne.scenarioName ? "selected" : "not-selected"
                }`}
                onClick={setSelectedScenarioOne}
              >
                  { <div>
                    {scenarioOne.scenarioName && scenarioOne.scenarioName.length > 0
                && scenarioOne.scenario1_info?
                      Object.entries(filterFields(scenarioOne.scenario1_info)).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          ),
                        )
                     :"Add Scenario"}
                  </div>}
              </Button>
              {isScenarioOneSelected && (
                <div className="delete_overlay">
                  <DeleteOutlineIcon
                    style={{ color: "#E81C0E" }}
                    onClick={onDeselectingScenarioOne}
                  ></DeleteOutlineIcon>
                </div>
              )}
              {/* <Tooltip
                title={
                  <div>
                    {scenarioOne.scenario1_info
                      ? Object.entries(scenarioOne.scenario1_info).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          ),
                        )
                      : ""}
                  </div>
                }
              >
                <InfoIcon style={{ color: "#E81C0E" }}></InfoIcon>
              </Tooltip> */}
            </div>
            <div className="button_info_container">
              <Button
                className={`scenario_selection ${
                  scenarioTwo.scenarioName ? "selected" : "not-selected"
                }`}
                onClick={setSelectedScenarioTwo}
              >
                {scenarioTwo.scenarioName && scenarioTwo.scenarioName.length > 0 && scenarioTwo.scenario2_info
                  ? <div>
                  {
                     Object.entries(filterFields(scenarioTwo.scenario2_info)).map(
                        ([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value}
                          </div>
                        ),
                      )
                  }
                </div>
                  : "Add Scenario2"}
              </Button>
              {isScenarioTwoSelected && (
                <div className="delete_overlay">
                  <DeleteOutlineIcon
                    style={{ color: "#E81C0E" }}
                    onClick={onDeselectingScenarioTwo}
                  ></DeleteOutlineIcon>
                </div>
              )}
              {/* <Tooltip
                title={
                  <div>
                    {scenarioTwo.scenario2_info
                      ? Object.entries(scenarioTwo.scenario2_info).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          ),
                        )
                      : ""}
                  </div>
                }
              >
                <InfoIcon style={{ color: "#E81C0E" }}></InfoIcon>
              </Tooltip> */}
            </div>
            <Button className="compare" onClick={compare_scenarios}>
              Compare
            </Button>
          </div>
          <div className="comparision_table">
            {/* <Spin spinning={comparisionDataTableLoading}> */}
            {comparisionPromotionData &&
              comparisionPromotionData.length > 0 && (
                <Table
                  className="table1"
                  columns={comparisionPromotionColumns}
                  dataSource={comparisionPromotionData}
                  pagination={false}
                ></Table>
              )}
            <Table
              className="table2"
              columns={comparisionDataColumns.map((v, i) => {
                console.log("v==>", v)
                if (
                  v.dataIndex == "Scenario1 Value"
                ) {
                  console.log("v", v["Scenario1 Value"])
                  // Add the custom render property only for certain columns
                  return {
                    ...v,
                    render: (text, record, index) => (
                      <div
                        className={
                          record["Scenario1 Value"] > record["Scenario2 Value"]
                            ? "green"
                            : ""
                        }
                      >
                        {text}
                      </div>
                    ),
                  }
                }
                else   if (
                  v.dataIndex == "Scenario2 Value"
                ) {
                  console.log("v", v["Scenario1 Value"])
                  // Add the custom render property only for certain columns
                  return {
                    ...v,
                    render: (text, record, index) => (
                      <div
                        className={
                          record["Scenario2 Value"] > record["Scenario1 Value"]
                            ? "green"
                            : ""
                        }
                      >
                        {text}
                      </div>
                    ),
                  }
                }
               
                return v
              })}
              dataSource={comparisionData}
              pagination={false}
              // columnClassName={(record, index) => (console.log("record",record))}
            ></Table>
            {/* </Spin> */}
          </div>
        </Spin>
        {comparisionData.length == 0 && (
          <div className="note">
            * Please select scenarios to view data in the table
          </div>
        )}
      </div>
    </div>
  )
}

export default Comparision
