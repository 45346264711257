import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"
import { formatString } from "../helper/static"
import { cloneDeep } from "lodash"
import { getRegions } from "./summaryScreenDropDown"

const initialState = {
  channels: { data: [], status: "", error: null },
  customers: { data: [], status: "", error: null },
  marketUnits: { data: [], status: "", error: null },
  category: { data: [], status: "", error: null },
  packages: { data: [], status: "", error: null },
  scenarios: { data: [], status: "", error: null },
  versions: { data: ["Swire", "Customer"] },
  checkbook: { data: ["Yes", "No"] },
  regions: { data: [], status: "", error: null },
  years: { data: [], status: "", error: null },
  kpiData: { data: [], status: "", error: null },
  comparisionData: {
    data: [],
    promotionData: [],
    promotionColumns: [],
    promotionColumnKeys:[],
    status: "",
    error: null,
  },
  selectedOptions: {
    Year: null,
    Region:null,
    Channel: null,
    Customer: null,
    "Market Unit": null,
    Category: null,
    Package: null,
    Scenario: null,
    Version: null,
    Checkbook: "Yes",
  },
}

export const admin_getChannelList = createAsyncThunk(
  "admin_getChannelList",
  async (payload) => callApi("api/v1/summary/get_super_channel", payload),
)

export const admin_getCustomersList = createAsyncThunk(
  "admin_getCustomersList",
  async (payload) => callApi("api/v1/summary/get_customers", payload),
)

export const admin_getMarketUnits = createAsyncThunk(
  "admin_getMarketUnits",
  async (payload) => callApi("api/v1/summary/get_market_units", payload),
)

export const compare_getRegions = createAsyncThunk(
  "admin_getRegions",
  async (payload) => callApi("api/v1/summary/get_customer_regions", payload),
)


export const admin_getPackageList = createAsyncThunk(
  "admin_getPackageList",
  async (payload) => callApi("api/v1/summary/get_packages", payload),
)

export const admin_getScenarioList = createAsyncThunk(
  "admin_getScenarioList",
  async (payload) => callApi("api/v1/summary/get_scenario", payload),
)

export const admin_getCategoryList = createAsyncThunk(
  "admin_getCategoryList",
  async (payload) => callApi("api/v1/summary/get_catagories", payload),
)

export const admin_getYearList = createAsyncThunk(
  "admin_getYearList",
  async (payload) => callApi("api/v1/scenario/get_year", payload),
)

export const admin_getKpiData = createAsyncThunk(
  "admin_getKpiData",
  (payload) => callApi("api/v1/summary/get_kpi_data", payload),
)

export const compareScenarios = createAsyncThunk(
  "compareScenarios",
  (payload) => callApi("api/v1/scenario/compare_scenarios", payload),
)

const comaprisionScreen = createSlice({
  name: "retailplan",
  initialState,
  reducers: {
    setAdminSelectedOptions: (state, action) => {
      state.selectedOptions[action.payload.label] = action.payload.option
      if (action.payload.label === "Customer") {
        state.previousCustomer = action.payload.option
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(admin_getCustomersList.pending, (state) => {
        state.customers.loading = "pending"
      })
      .addCase(admin_getCustomersList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        //state.customers.status = "fulfilled"
        // state.customers.data = action.payload.data.data
        // state.selectedOptions.Customer = action.payload.data.data[0]
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          return {
            ...state,
            customers: {
              status: "fulfilled",
              data: filteredData,
            },
            selectedOptions: {
              ...state.selectedOptions,
              Customer: action.payload.data.data[0],
            },
            previousCustomer: state.selectedOptions.Customer,
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting customer list",
          })
        }
      })
      .addCase(admin_getCustomersList.rejected, (state, action) => {
        state.customers.status = "rejected"
        state.customers.error = action.error.message
      })
      .addCase(admin_getPackageList.pending, (state) => {
        state.packages.status = "pending"
      })
      .addCase(admin_getPackageList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let packages_array = action.payload.data.data
          if (packages_array.length > 0) {
            let filteredData = action.payload.data.data.filter(
              (v) => v.length !== 0,
            )
            state.packages.status = "fulfilled"
            state.packages.data = filteredData
            state.selectedOptions.Package = filteredData[0]
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "There are no package for the selected market unit and category combination",
            })
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting package list",
          })
        }
      })
      .addCase(admin_getPackageList.rejected, (state, action) => {
        state.packages.status = "rejected"
        state.packages.error = action.error.message
      })
      .addCase(admin_getScenarioList.pending, (state) => {
        state.scenarios.status = "pending"
      })
      .addCase(admin_getScenarioList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          state.scenarios.status = "fulfilled"
          state.scenarios.data = filteredData
          state.selectedOptions.Scenario =
            state.selectedOptions.Scenario !== null &&
            action.payload.data.data.includes(state.selectedOptions.Scenario)
              ? state.selectedOptions.Scenario
              : filteredData[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting scenario list",
          })
        }
      })
      .addCase(admin_getScenarioList.rejected, (state, action) => {
        state.scenarios.status = "rejected"
        state.scenarios.error = action.error.message
      })
      .addCase(admin_getMarketUnits.pending, (state) => {
        state.marketUnits.status = "pending"
      })
      .addCase(admin_getMarketUnits.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.filter(
            (v) => v.length !== 0,
          )
          state.marketUnits.status = "fulfilled"
          state.marketUnits.data = filteredData
          state.selectedOptions["Market Unit"] = filteredData[0]
          state.previousMarketUnit = state.selectedOptions["Market Unit"]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting scenario list",
          })
        }
      })
      .addCase(admin_getMarketUnits.rejected, (state, action) => {
        state.scenarios.status = "rejected"
        state.scenarios.error = action.error.message
      })
      .addCase(admin_getCategoryList.pending, (state) => {
        state.category.status = "pending"
      })
      .addCase(admin_getCategoryList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          let filteredData = action.payload.data.data.categories.filter(
            (v) => v.length !== 0,
          )
          state.category.status = "fulfilled"
          state.category.data = filteredData
          state.selectedOptions.Category = filteredData[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(admin_getCategoryList.rejected, (state, action) => {
        state.category.status = "rejected"
        state.category.error = action.error.message
      })
      .addCase(admin_getYearList.pending, (state) => {
        state.years.status = "pending"
      })
      .addCase(admin_getYearList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.years.status = "fulfilled"
          state.years.data = action.payload.data.data
          let keys = Object.keys(action.payload.data.data)
          state.selectedOptions.Year = keys[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(admin_getYearList.rejected, (state, action) => {
        state.years.status = "rejected"
        state.years.error = action.error.message
      })
      .addCase(admin_getChannelList.pending, (state) => {
        state.channels.status = "pending"
      })
      .addCase(admin_getChannelList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.channels.status = "fulfilled"
          state.channels.data = action.payload.data.data.channels
          state.selectedOptions.Channel = action.payload.data.data.channels[2]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error occured when getting category list",
          })
        }
      })
      .addCase(admin_getChannelList.rejected, (state, action) => {
        state.channels.status = "rejected"
        state.channels.error = action.error.message
      })
      .addCase(admin_getKpiData.pending, (state) => {
        state.kpiData.status = "pending"
      })
      .addCase(admin_getKpiData.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.kpiData.status = "fulfilled"
          state.kpiData.data = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting kpi data",
          })
        }
      })
      .addCase(admin_getKpiData.rejected, (state, action) => {
        state.kpiData.status = "rejected"
        state.kpiData.error = action.error.message
      })
      .addCase(compareScenarios.pending, (state) => {
        state.comparisionData.status = "pending"
      })
      .addCase(compareScenarios.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.comparisionData.status = "fulfilled"
          let labels = action.payload.data.data["Labels"]
          let temp_data = action.payload.data.data
          let scenario1_promo = action.payload.data.data.scenario1_promotion
          let scenario2_promo = action.payload.data.data.scenario2_promotion
          scenario1_promo.name = "scenario1"
          scenario2_promo.name = "scenario2"
          for (let key in scenario1_promo) {
            if (!state.comparisionData.promotionColumnKeys.includes(key)) {
              state.comparisionData.promotionColumnKeys.push(key)
            }
          }
          for (let key in scenario2_promo) {
            if (!state.comparisionData.promotionColumnKeys.includes(key)) {
              state.comparisionData.promotionColumnKeys.push(key)
            }
          }
          let promotionColumnsKeys = cloneDeep(state.comparisionData.promotionColumnKeys)
          let sortingPriority = ["name", "customer_margin", "total_volume"]
          let priorityColumns = sortingPriority.map((v) => {
            let obj = {}
            obj.title = formatString(v)
            obj.dataIndex = v
            obj.key = v
            return obj
          })
          let remainingColumns = promotionColumnsKeys
            .filter((v) => !sortingPriority.includes(v))
            .map((v) => {
              if (
                v !== "customer_margin" &&
                v !== "total_volume" &&
                v !== "name"
              ) {
                let obj = {}
                obj.title = v
                obj.dataIndex = v
                obj.children = [
                  {
                    title: "Effective Retail",
                    dataIndex: [v, "effective_retail_data"],
                    key: "effective_retail_data",
                  },
                  {
                    title: "Event Frequency",
                    dataIndex: [v, "event_frequency_data"],
                    key: "event_frequency_data",
                  },
                  {
                    title: "Volume",
                    dataIndex: [v, "volume_event_cases"],
                    key: "volume_event_cases",
                  },
                ]
                console.log("obj", obj)
                return obj
              }
            })

          state.comparisionData.promotionColumns = [... priorityColumns,...remainingColumns]
          state.comparisionData.promotionData = [
            scenario1_promo,
            scenario2_promo,
          ]
          let dataArray = labels.map((l, i) => {
            let obj = {}
            obj["Scenario1 Value"] = temp_data["Scenario1 Value"][i]
            obj["Scenario2 Value"] = temp_data["Scenario2 Value"][i]
            obj["Labels"] = temp_data["Labels"][i]
            obj["Difference"] = temp_data["Difference"][i]
            obj["Conclusion"] = temp_data["Conclusion"][i]
            return obj
          })
          state.comparisionData.data = dataArray
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while comparing scenarios",
          })
        }
      })
      .addCase(compareScenarios.rejected, (state, action) => {
        state.comparisionData.status = "rejected"
        state.comparisionData.error = action.error.message
      })
      .addCase(compare_getRegions.pending, (state) => {
        state.regions.status = "pending"
      })
      .addCase(compare_getRegions.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status === 200) {
          state.regions.status = "fulfilled"
          state.regions.data=action.payload.data.data
          state.selectedOptions.Region=action.payload.data.data[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting regions",
          })
        }
      })
      .addCase(compare_getRegions.rejected, (state, action) => {
        state.regions.status = "rejected"
        state.regions.error = action.error.message
      })
  },
})

export const { setAdminSelectedOptions } = comaprisionScreen.actions
export default comaprisionScreen.reducer
