import React, { useState, useEffect, useMemo } from "react"
import RetailPlanTable from "../../components/retail_plan/RetailPlanTable.jsx"
import SummaryTable from "../../components/summary_table/SummaryTable.jsx"
import MenuBox from "../../components/menubox/MenuBox.jsx"
import CardComponent from "../../components/cardcomponent/CardComponent.jsx"
import "./Summary.css"
import { callApi } from "../../dataService.js"
import { Button, Spin, Input, Tooltip } from "antd"
import {
  waterfallEditableCells,
  cardComponentDataHeaders,
  accrualsEditableCells,
} from "../../helper/static"
import update_icon from "../../assets/images/update_icon.png"
import save_as_new_icon from "../../assets/images/save_as_new_icon.png"
import optimize_icon from "../../assets/images/optimize_icon.png"
import SaveScenarioModal from "../../components/modal/SaveScenarioModal.jsx"
import Swal from "sweetalert2"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  getChannelList,
  getCustomersList,
  getRegions,
  getMarketUnits,
  getPackageList,
  getScenarioList,
  getCategoryList,
  setSelectedOptions,
  setSelectedVersionAndCheckbook,
  getYearList,
  clearPackageListAndSelectedOption,
  clearCategoryListAndSelectedOption,
  setPreviousMarketUnit,
  clearMarketUnitandSelectedOption,
} from "../../reducers/summaryScreenDropDown.js"
import {
  getWaterfallTableData,
  getRetailPlanTableData,
  getAccrualsTableData,
  getAccrualsWithPromoTableData,
  getCheckbookTableData,
  getRetailPlanTableMetaData,
  getKpiData,
  optimize_scenario,
} from "../../reducers/summaryTableDataUpdate"
import { sideBarOpen } from "../../reducers/sideBarToggle"
import SideBar from "../../components/sidebar/SideBar.jsx"
import Header from "../../components/header/Header.jsx"
import { cloneDeep } from "lodash"
import { setSequencingSelectedOption } from "../../reducers/sequencingScreenDropDown.js"
import {
  setUpdatedPackage,
  setSequencingTableEmpty,
} from "../../reducers/sequencingTableData.js"
import {
  retailPlanToolTipTitles,
  accrualsToolTipTitles,
  accruals1ToolTipTitles,
  checkbookToolTipTitles,
  waterfallToolTipTitles,
} from "../../helper/static.js"
import { getCookie } from "../../helper/getCookie.js"
import { useMsal } from "@azure/msal-react"

const Summary = () => {
  const [saveScenarioModalOpen, setSaveScenarioModalOpen] = useState(false)
  const [saveScenarioPayload, setSaveScenarioPayload] = useState({})
  const [updateScenarioPayload, setUpdateScenarioPayload] = useState({})
  const [reloadScenario, setReloadScenario] = useState(false)
  const [initial, setInitial] = useState(0)
  const dispatch = useDispatch()
  const { accounts } = useMsal()

  const channelList = useSelector(
    (state) => state.summmaryScreenDropDown.channels.data,
  )
  const customersList = useSelector(
    (state) => state.summmaryScreenDropDown.customers.data,
  )

  const regions = useSelector(
    (state) => state.summmaryScreenDropDown.regions.data,
  )

  const marketUnits = useSelector(
    (state) => state.summmaryScreenDropDown.marketUnits.data,
  )

  const category = useSelector(
    (state) => state.summmaryScreenDropDown.category.data,
  )

  const years = useSelector((state) => state.summmaryScreenDropDown.years.data)

  const packagesList = useSelector(
    (state) => state.summmaryScreenDropDown.packages.data,
  )
  const scenariosList = useSelector(
    (state) => state.summmaryScreenDropDown.scenarios.data,
  )
  const versionsList = useSelector(
    (state) => state.summmaryScreenDropDown.versions.data,
  )
  const checkbookOptions = useSelector(
    (state) => state.summmaryScreenDropDown.checkbook.data,
  )
  const selectedOptionsList = useSelector(
    (state) => state.summmaryScreenDropDown.selectedOptions,
  )

  const sequencingOptionList = useSelector(
    (state) => state.sequencingScreenDropDown.sequencingSelectedOptions,
  )
  const retailPlanData = useSelector(
    (state) => state.summarytable.retailPlanData.data,
  )
  const retailPlanMetaData = useSelector(
    (state) => state.summarytable.retailPlanMetaPlan.data,
  )
  const waterfallData = useSelector(
    (state) => state.summarytable.waterfallData.data,
  )
  const accrualsData = useSelector(
    (state) => state.summarytable.accrualsData.data,
  )
  const accuralsWithPromoData = useSelector(
    (state) => state.summarytable.accuralsWithPromoData.data,
  )
  const checkbookData = useSelector(
    (state) => state.summarytable.checkbookData.data,
  )

  const optimzationStatus = useSelector(
    (state) => state.summarytable.optimizationStatus.status,
  )
  const kpiData = useSelector((state) => state.summarytable.kpiData.data)
  const side_bar_open = useSelector((state) => state.sidebar.open)
  const location = useLocation()
  const [storesValue, setStoresValue] = useState("")
  const versionInQuery =
    location.search.length !== 0
      ? location.search.split("?")[1].split("=")[1]
      : ""
  let payload = {
    // token: getCookie("swire_access_token"),
  }
  const customerLoading = useSelector(
    (state) => state.summmaryScreenDropDown.customers.status,
  )

  const marketUnitLoading = useSelector(
    (state) => state.summmaryScreenDropDown.marketUnits.status,
  )

  const categoryLoading = useSelector(
    (state) => state.summmaryScreenDropDown.category.status,
  )

  const packageLoading = useSelector(
    (state) => state.summmaryScreenDropDown.customers.status,
  )
  const scenarioLoading = useSelector(
    (state) => state.summmaryScreenDropDown.customers.status,
  )
  const waterfallLoading = useSelector(
    (state) => state.summarytable.waterfallData.status,
  )
  const accuralsLoading = useSelector(
    (state) => state.summarytable.accrualsData.status,
  )
  const accuralWithPromoLoading = useSelector(
    (state) => state.summarytable.accuralsWithPromoData.status,
  )
  const retailPlanLoading = useSelector(
    (state) => state.summarytable.retailPlanData.status,
  )
  const checkbookLoading = useSelector(
    (state) => state.summarytable.checkbookData.status,
  )
  const retailPlanMetaLoading = useSelector(
    (state) => state.summarytable.retailPlanMetaPlan.status,
  )
  const kpiDataLoading = useSelector(
    (state) => state.summarytable.kpiData.status,
  )

  const sequencingUpdatedPackage = useSelector(
    (state) => state.sequencingTable.updatedPackage,
  )

  const [updateLoading, setUpdateLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [saveType, setSaveType] = useState("")

  const loading =
    customerLoading !== "fulfilled" ||
    marketUnitLoading !== "fulfilled" ||
    categoryLoading !== "fulfilled" ||
    packageLoading !== "fulfilled" ||
    scenarioLoading !== "fulfilled" ||
    waterfallLoading !== "fulfilled" ||
    accuralsLoading !== "fulfilled" ||
    accuralWithPromoLoading !== "fulfilled" ||
    retailPlanLoading !== "fulfilled" ||
    checkbookLoading != "fulfilled" ||
    kpiDataLoading !== "fulfilled" ||
    retailPlanMetaLoading !== "fulfilled" ||
    updateLoading ||
    createLoading

  const accrualsTable1Headers = [
    "Accruals",
    "Current",
    "Current(Enterable)",
    "New Pricing",
    "Chg $",
    "% Chg",
  ]
  const waterfallTable1Headers = [
    "Pricing Waterfall",
    "Current",
    "Current(Enterable)",
    "New Pricing",
    "Chg $",
    "% Chg",
  ]
  const accrualsTable2Headers = [
    "Accrual Type",
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
    "Total",
  ]
  const checkbookHeaders = [
    "Checkbook",
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
    "Total",
  ]
  const promosCheckedInitial = {
    P1_A: true,
    P1_B: true,
    P1_C: true,
    P1_D: true,
    P2_A: true,
    P2_B: true,
    P2_C: true,
    P2_D: true,
    Holiday: true,
  }

  const promos = [
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
  ]

  const [promosChecked, setPromosChecked] = useState(promosCheckedInitial)

  const changePromosVisibility = (e, promo) => {
    let checked = e.target.checked
    let temp_promo_checked = cloneDeep(promosChecked)
    temp_promo_checked[promo] = checked
    setPromosChecked(temp_promo_checked)
  }
  const showHiddenPromos = () => {
    let temp_promo_checked = cloneDeep(promosChecked)
    for (let key in temp_promo_checked) {
      temp_promo_checked[key] = true
    }
    setPromosChecked(temp_promo_checked)
  }

  const checkForRetailMultipleForFilledWeeks = () => {
    let week_frequency = retailPlanData.find(
      (v) => v.Category === "Event Frequency (Wks)",
    )
    let retailMultiple = retailPlanData.find(
      (v) => v.Category === "Retail Multiple",
    )
    let returnBoolean = true
    promos.forEach((promo) => {
      if (week_frequency[promo] !== 0) {
        retailMultiple[promo] !== 0
          ? (returnBoolean = true)
          : (returnBoolean = false)
      }
    })
    return returnBoolean
  }

  const optimizeScenario = () => {
    let weeksFrequencyInRetailPlan = cloneDeep(
      retailPlanData.find((row) => row.Category === "Event Frequency (Wks)"),
    )
    delete weeksFrequencyInRetailPlan.Category
    delete weeksFrequencyInRetailPlan.Total
    let totalWeeks = Object.values(weeksFrequencyInRetailPlan)
      .map((v) => Number(v))
      .reduce((a, b) => a + b)
    let actualWeeks = remainingWeeks()
    let checkRetailMultipleValidation = checkForRetailMultipleForFilledWeeks()
    console.log("checkRetailMultipleValidation", checkRetailMultipleValidation)
    if (totalWeeks !== actualWeeks) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#E21824",
        text: "Week frequency sum should be equal to " + actualWeeks,
      })
    } else if (!checkRetailMultipleValidation) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#E21824",
        text: "Retail multiple should not be zero for which week frequencies are non zeros",
      })
    } else {
      let temp_payload = {}
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.checkbook = selectedOptionsList.Checkbook.toLowerCase()
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      temp_payload.scenario = selectedOptionsList.Scenario
      setSaveScenarioPayload(temp_payload)
      let update_payload = cloneDeep(temp_payload)
      update_payload.pricing_waterfall = waterfallData
      update_payload.retail_plan_data = retailPlanData
      update_payload.accruals_data = accrualsData
      update_payload.update_type = "summary"
      update_payload.active_stores = storesValue
      update_payload.current_fy_dnnsi_editable =
        kpiData["Current FY DNNSI(Enterable)"]
      setSaveType("optimize")
      setUpdateScenarioPayload(update_payload)
      setSaveScenarioModalOpen(true)
      //dispatch(optimize_scenario(temp_payload))
    }
  }

  const remainingWeeks = () => {
    let currentWeek = retailPlanMetaData.current_week
    let value = 52 - currentWeek + 1
    return value
  }
  const updateScenario = () => {
    let weeksFrequencyInRetailPlan = cloneDeep(
      retailPlanData.find((row) => row.Category === "Event Frequency (Wks)"),
    )
    delete weeksFrequencyInRetailPlan.Category
    delete weeksFrequencyInRetailPlan.Total
    let totalWeeks = Object.values(weeksFrequencyInRetailPlan)
      .map((v) => Number(v))
      .reduce((a, b) => a + b)
    let actualWeeks = remainingWeeks()
    if (totalWeeks == actualWeeks) {
      let temp_payload = {}
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      temp_payload.scenario = selectedOptionsList.Scenario
      temp_payload.checkbook = selectedOptionsList.Checkbook.toLowerCase()
      temp_payload.pricing_waterfall = waterfallData
      temp_payload.retail_plan_data = retailPlanData
      temp_payload.accruals_data = accrualsData
      temp_payload.update_type = "summary"
      temp_payload.active_stores = storesValue
      temp_payload.current_fy_dnnsi_editable =
        kpiData["Current FY DNNSI(Enterable)"]
      setUpdateScenarioPayload(temp_payload)
      setUpdateLoading(true)
      callApi("api/v1/scenario/update_scenario", temp_payload)
        .then((res) => {
          console.log("sucessfull updated", res)
          if (res.data.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Scenario is successfully updated",
            })
            setReloadScenario(true)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
        })
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#E21824",
        text: "Week frequency sum should be equal to " + actualWeeks,
      })
    }
    setUpdateLoading(false)
  }

  const saveScenario = () => {
    let weeksFrequencyInRetailPlan = cloneDeep(
      retailPlanData.find((row) => row.Category === "Event Frequency (Wks)"),
    )
    delete weeksFrequencyInRetailPlan.Category
    delete weeksFrequencyInRetailPlan.Total
    let totalWeeks = Object.values(weeksFrequencyInRetailPlan)
      .map((v) => Number(v))
      .reduce((a, b) => a + b)
    let actualWeeks = remainingWeeks()
    if (totalWeeks == actualWeeks) {
      setSaveType("save")
      setSaveScenarioModalOpen(true)
      let temp_payload = {}
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      temp_payload.scenario = selectedOptionsList.Scenario
      temp_payload.checkbook = selectedOptionsList.Checkbook.toLowerCase()
      temp_payload.pricing_waterfall = waterfallData
      temp_payload.retail_plan_data = retailPlanData
      temp_payload.accruals_data = accrualsData
      temp_payload.create_type = "summary"
      temp_payload.active_stores = storesValue
      temp_payload.current_fy_dnnsi_editable =
        kpiData["Current FY DNNSI(Enterable)"]
      setSaveScenarioPayload(temp_payload)
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#E21824",
        text: "Week frequency sum should be equal to " + actualWeeks,
      })
    }
  }

  const modelHoverText = () => {
    return (
      <div>
        <li>
          Current Week:{" "}
          <span style={{ color: "blue" }}>
            {retailPlanMetaData.current_week}
          </span>
        </li>
        <li>
          Intercept:{" "}
          <span style={{ color: "blue" }}> {retailPlanMetaData.intercept}</span>
        </li>
        <li>
        Coeff Effective Unit Price:{" "}
          <span style={{ color: "blue" }}> {retailPlanMetaData.coeff_effective_unit_price}</span>
        </li>
        {/* <li>
        Coeff Number Of Stores:{" "}
          <span style={{ color: "blue" }}> {retailPlanMetaData.coeff_number_of_stores}</span>
        </li> */}
        <li>
          Mult Factor Upper Cap:{" "}
          <span style={{ color: "blue" }}>
            {retailPlanMetaData.mult_factor_upper_cap}
          </span>
        </li>
        <li>
          Mult Factor Lower Cap:{" "}
          <span style={{ color: "blue" }}>
            {retailPlanMetaData.mult_factor_lower_cap}
          </span>
        </li>
      </div>
    )
  }
  const onstoreInputChange = (e) => {
    let value = e.target.value
    setStoresValue(value)
  }

  useEffect(() => {
    setStoresValue(retailPlanMetaData.active_stores)
  }, [retailPlanMetaData])

  useEffect(() => {
    if (!selectedOptionsList.Year) {
      dispatch(getYearList(payload))
    }
    dispatch(setSequencingTableEmpty())
    setInitial(1)
    console.log("years", Object.keys(years))
  }, [])

  useEffect(() => {
    if (selectedOptionsList.Year && initial !== 0 && accounts) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      dispatch(getChannelList(temp_payload))
      setInitial(1)
    }
  }, [selectedOptionsList.Year])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Channel &&
      initial !== 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.channel = selectedOptionsList.Channel
      dispatch(getCustomersList(temp_payload))
      setInitial(1)
    }
    dispatch(setSelectedVersionAndCheckbook())
    if (versionInQuery == "Swire" || versionInQuery == "Customer") {
      dispatch(setSelectedOptions({ label: "Version", option: versionInQuery }))
    }
  }, [selectedOptionsList.Channel])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      initial !== 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer

      dispatch(getRegions(temp_payload))
      setInitial(1)
    }
  }, [customersList, selectedOptionsList.Customer])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      initial !== 0
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      dispatch(getMarketUnits(temp_payload))
      setInitial(1)
    }
  }, [regions, selectedOptionsList.Region])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"] &&
      (initial !== 0 || category.length == 0)
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      dispatch(getCategoryList(temp_payload))
      setInitial(1)
      if (initial !== 0) {
        dispatch(
          setSequencingSelectedOption({ label: "Category", option: null }),
        )
      }
    }
  }, [marketUnits, selectedOptionsList["Market Unit"]])

  useEffect(() => {
    if (sequencingOptionList.Category) {
      dispatch(
        setSelectedOptions({
          label: "Category",
          option: sequencingOptionList.Category,
        }),
      )
    }
  }, [category])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Category &&
      (initial !== 0 || packagesList.length == 0)
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.category = selectedOptionsList.Category
      dispatch(getPackageList(temp_payload))
      setInitial(1)
    }
  }, [category, selectedOptionsList.Category])

  useEffect(() => {
    if (
      packagesList.length > 0 &&
      sequencingUpdatedPackage.length > 0 &&
      packagesList.includes(sequencingUpdatedPackage)
    ) {
      dispatch(
        setSelectedOptions({
          label: "Package",
          option: sequencingUpdatedPackage,
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList.Package &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Category
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      dispatch(getScenarioList(temp_payload))
    }
  }, [packagesList, selectedOptionsList.Package])

  useEffect(() => {
    if (reloadScenario || optimzationStatus == "fulfilled") {
      if (
        selectedOptionsList.Year &&
        selectedOptionsList.Customer &&
        selectedOptionsList.Region &&
        selectedOptionsList.Package &&
        selectedOptionsList["Market Unit"] &&
        selectedOptionsList.Category
      ) {
        let temp_payload = cloneDeep(payload)
        temp_payload.year = selectedOptionsList.Year
        temp_payload.customer = selectedOptionsList.Customer
        temp_payload.region = selectedOptionsList.Region
        temp_payload.market_unit = selectedOptionsList["Market Unit"]
        temp_payload.package = selectedOptionsList.Package
        dispatch(getScenarioList(temp_payload))
      }
      setReloadScenario(false)
    }
  }, [reloadScenario, optimzationStatus])

  // useEffect(() => {
  //   if (reloadScenario) {
  //     let temp_payload = cloneDeep(payload)
  //     temp_payload.year = selectedOptionsList.Year
  //     temp_payload.customer = selectedOptionsList.Customer
  //     temp_payload.region = selectedOptionsList.Region
  //     temp_payload.package = selectedOptionsList.Package
  //     temp_payload.market_unit = selectedOptionsList["Market Unit"]
  //     dispatch(getScenarioList(temp_payload))
  //     temp_payload.scenario = selectedOptionsList.Scenario
  //     temp_payload.checkbook = selectedOptionsList.Checkbook.toLowerCase()
  //     if (selectedOptionsList.Scenario) {
  //       dispatch(getWaterfallTableData(temp_payload))
  //       dispatch(getAccrualsTableData(temp_payload))
  //       dispatch(getRetailPlanTableMetaData(temp_payload))
  //       dispatch(getRetailPlanTableData(temp_payload))
  //       dispatch(getAccrualsWithPromoTableData(temp_payload))
  //       dispatch(getCheckbookTableData(temp_payload))
  //       dispatch(getKpiData(temp_payload))
  //     }
  //     setReloadScenario(false)
  //   }
  // }, [reloadScenario])

  useEffect(() => {
    if (
      selectedOptionsList.Year &&
      selectedOptionsList.Customer &&
      selectedOptionsList.Region &&
      selectedOptionsList["Market Unit"] &&
      selectedOptionsList.Package &&
      selectedOptionsList.Scenario
    ) {
      let temp_payload = cloneDeep(payload)
      temp_payload.year = selectedOptionsList.Year
      temp_payload.customer = selectedOptionsList.Customer
      temp_payload.region = selectedOptionsList.Region
      temp_payload.market_unit = selectedOptionsList["Market Unit"]
      temp_payload.package = selectedOptionsList.Package
      temp_payload.scenario = selectedOptionsList.Scenario
      temp_payload.checkbook = selectedOptionsList.Checkbook.toLowerCase()
      dispatch(getWaterfallTableData(temp_payload))
      dispatch(getAccrualsTableData(temp_payload))
      dispatch(getRetailPlanTableMetaData(temp_payload))
      dispatch(getRetailPlanTableData(temp_payload))
      dispatch(getAccrualsWithPromoTableData(temp_payload))
      dispatch(getCheckbookTableData(temp_payload))
      dispatch(getKpiData(temp_payload))
    }
  }, [
    scenariosList,
    selectedOptionsList.Scenario,
    selectedOptionsList.Checkbook,
  ])

  useEffect(() => {
    if (loading) {
      dispatch(sideBarOpen(false))
    }
  }, [loading])
  return (
    <>
      <Header />
      <SideBar />
      <Spin
        className={loading ? "loading" : "not_loading"}
        spinning={loading}
        size="large"
      >
        <div
          style={{
            width: side_bar_open ? "72%" : "93.5%",
            left: side_bar_open ? "13%" : "0",
            position: "relative",
            margin: "auto",
            paddingTop: "16vh",
            marginBottom: "6vh",
          }}
        >
          <div>
            <div className="menu_box_container">
              {channelList && (
                <MenuBox
                  label="Channel"
                  options={channelList}
                  screen="summary"
                ></MenuBox>
              )}
              {customersList && (
                <MenuBox
                  label="Customer"
                  options={customersList}
                  screen="summary"
                ></MenuBox>
              )}
              {customersList && (
                <MenuBox
                  label="Region"
                  options={regions}
                  screen="summary"
                ></MenuBox>
              )}
              {marketUnits && (
                <MenuBox
                  label="Market Unit"
                  options={marketUnits}
                  screen="summary"
                ></MenuBox>
              )}
              {category && (
                <MenuBox
                  label="Category"
                  options={category}
                  screen="summary"
                ></MenuBox>
              )}
              {packagesList && (
                <MenuBox
                  label="Package"
                  options={packagesList}
                  screen="summary"
                ></MenuBox>
              )}
              {scenariosList && (
                <MenuBox
                  label="Scenario"
                  options={scenariosList}
                  screen="summary"
                ></MenuBox>
              )}
              <MenuBox
                label="Version"
                options={versionsList}
                screen="summary"
              ></MenuBox>
              <MenuBox
                label="Checkbook"
                options={checkbookOptions}
                screen="summary"
              ></MenuBox>
              <MenuBox
                label="Year"
                options={Object.keys(years)}
                years={years}
                screen="summary"
              ></MenuBox>

              <div className="stores_input_container">
                <div
                  style={{
                    marginBottom: "5px",
                    fontSize: "1vw",
                    fontWeight: "200",
                  }}
                >
                  <Tooltip
                    overlayStyle={{ fontSize: "11px" }}
                    title="Number of stores active in last 26 weeks"
                  >
                    Stores:
                  </Tooltip>
                </div>
                <Input
                  className="no_of_stores_input"
                  value={storesValue}
                  style={{ fontWeight: "600" }}
                  disabled="true"
                  onChange={(e) => {
                    onstoreInputChange(e)
                  }}
                ></Input>
              </div>
              <div className="stores_input_container">
                <div
                  style={{
                    marginBottom: "5px",
                    fontSize: "1vw",
                    fontWeight: "200",
                  }}
                >
                  Maximum Duration Per Promo:
                </div>
                <Input
                  className="maximum_duration_input"
                  style={{ fontWeight: "600" }}
                  disabled="true"
                ></Input>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div className="summary_buttons_container">
                <Button onClick={updateScenario} className="summary_button">
                  <img
                    className="button_icon"
                    src={update_icon}
                    alt="update"
                  ></img>
                  Update Scenario
                </Button>
                <Button onClick={saveScenario} className="summary_button">
                  <img
                    className="button_icon"
                    src={save_as_new_icon}
                    alt="save"
                  ></img>
                  Save as new
                </Button>
                <Button onClick={optimizeScenario} className="summary_button">
                  <img
                    className="button_icon"
                    src={optimize_icon}
                    alt="optimize"
                  ></img>
                  Optimize
                </Button>
                {/* <Button
                  style={{ backgroundColor: "white", color: "#E81C0E" }}
                  disabled={true}
                  className="summary_button"
                >
                  <img
                    className="button_icon"
                    src={download_icon}
                    alt="download"
                  ></img>
                  Download
                </Button> */}
              </div>
              <div
                style={{
                  marginBottom: "4vh",
                  color: "#E81C0E",
                  display: "flex",
                  gap: "5.5vw",
                  fontSize: "0.85vw",
                  fontWeight: "600",
                }}
              >
                <div>Scenario Last updated: {retailPlanMetaData.last_modified}</div>
                <Tooltip
                  overlayStyle={{ maxWidth: "25vw", fontSize: "11px" }}
                  title={retailPlanMetaData.model !== 0 ? modelHoverText() : ""}
                >
                  <div>
                    *
                    {retailPlanMetaData.model == 0
                      ? "Model Not Available"
                      : "Model Available"}
                  </div>
                </Tooltip>
              </div>
            </div>
            <SaveScenarioModal
              saveScenarioModalOpen={saveScenarioModalOpen}
              setSaveScenarioModalOpen={setSaveScenarioModalOpen}
              saveScenarioPayload={saveScenarioPayload}
              setReloadScenario={setReloadScenario}
              setCreateLoading={setCreateLoading}
              customers={customersList}
              regions={regions}
              marketUnits={marketUnits}
              category={category}
              packages={packagesList}
              scenarios={scenariosList}
              saveType={saveType}
              updateScenarioPayload={updateScenarioPayload}
            ></SaveScenarioModal>
            <div className="summary-container">
              <div className="left_section">
                {waterfallData && Object.keys(waterfallData).length > 0 && (
                  <SummaryTable
                    label="Pricing Waterfall"
                    headers={waterfallTable1Headers}
                    editableCells={waterfallEditableCells}
                    toolTipLabels={waterfallToolTipTitles}
                    tableData={waterfallData}
                    version={selectedOptionsList.Version}
                    unit_per_case={retailPlanMetaData.unit_per_case}
                  ></SummaryTable>
                )}
                {selectedOptionsList.Version !== "Customer" &&
                  accrualsData &&
                  Object.keys(accrualsData).length > 0 && (
                    <SummaryTable
                      label="Accruals"
                      headers={accrualsTable1Headers}
                      editableCells={accrualsEditableCells}
                      tableData={accrualsData}
                      toolTipLabels={accruals1ToolTipTitles}
                      version={selectedOptionsList.Version}
                    ></SummaryTable>
                  )}
                {kpiData && Object.keys(kpiData).length > 0 && (
                  <CardComponent
                    headers={cardComponentDataHeaders}
                    data={kpiData}
                    horizontal={false}
                    version={selectedOptionsList.Version}
                  ></CardComponent>
                )}
              </div>
              <div className="right_section">
                {retailPlanData &&
                  retailPlanMetaData &&
                  Object.keys(retailPlanMetaData).length > 0 &&
                  retailPlanData.length > 0 && (
                    <RetailPlanTable
                      version={selectedOptionsList.Version}
                      promosChecked={promosChecked}
                      setPromosChecked={setPromosChecked}
                      retailPlanToolTipTitles={retailPlanToolTipTitles}
                      changePromosVisibility={changePromosVisibility}
                      showHiddenPromos={showHiddenPromos}
                    ></RetailPlanTable>
                  )}
                {selectedOptionsList.Version !== "Customer" &&
                  accuralsWithPromoData &&
                  Object.keys(accuralsWithPromoData).length > 0 && (
                    <SummaryTable
                      label="Accruals2"
                      headers={accrualsTable2Headers}
                      editableCells={{}}
                      toolTipLabels={accrualsToolTipTitles}
                      tableData={accuralsWithPromoData}
                      version={selectedOptionsList.Version}
                      promosChecked={promosChecked}
                    ></SummaryTable>
                  )}
                {selectedOptionsList.Version !== "Customer" &&
                  selectedOptionsList.Checkbook !== "No" &&
                  checkbookData &&
                  Object.keys(checkbookData).length > 0 && (
                    <SummaryTable
                      label="Checkbook"
                      headers={checkbookHeaders}
                      editableCells={{}}
                      toolTipLabels={checkbookToolTipTitles}
                      tableData={checkbookData}
                      version={selectedOptionsList.Version}
                      promosChecked={promosChecked}
                    ></SummaryTable>
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="loader">
        <Spin size="large"></Spin>
      </div> */}
      </Spin>
    </>
  )
}

export default Summary
