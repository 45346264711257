import React, { useState } from "react"
import { Input, Modal, Button, Radio } from "antd"
import { cloneDeep } from "lodash"
import {
  setSequencingTableData,
  setDynamicCountOfValues,
  setSequencingTableIsUpdated,
} from "../../reducers/sequencingTableData.js"
import { useDispatch } from "react-redux"
import "./DataFillPopUp.css"

const DataFillPopUp = ({
  dataFillPopUpOpen,
  setDataFillPopUpOpen,
  fullData,
  packageName,
  current_week
}) => {
  const [startIndex, setStartIndex] = useState(null)
  const [endIndex, setEndIndex] = useState(null)
  const [weeks, setWeeks] = useState(null)
  const [weeksInputValue, setWeeksInputValue] = useState(null)
  const [selectedType, setSelectedType] = useState("range")
  const [rangeSelection, setRangeSelection] = useState()
  const [weekSelection, setWeekSelection] = useState()
  const [value, setValue] = useState(null)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSave = () => {
     let error_present=false
    if (selectedType == "range") {
      if (
        Number(startIndex) &&
        startIndex >= 1 &&
        startIndex <= 52 &&
        Number(endIndex) &&
        endIndex >= 1 &&
        endIndex <= 52 &&
        value &&
        value.length > 0 && startIndex >current_week-1 && endIndex>current_week-1
      ) {
        error_present=false
        let temp_data = cloneDeep(fullData)
        temp_data[packageName] = temp_data[packageName].map((val, i) =>
          i >= startIndex - 1 && i <= endIndex - 1 ? value.toUpperCase() : val,
        )
        dispatch(setSequencingTableIsUpdated(false))
        dispatch(setSequencingTableData(temp_data))
        dispatch(setDynamicCountOfValues(packageName))
        onCancel("range")
      }
      else error_present=true
    } else if (selectedType == "weeks") {
      let provided_weeks = weeks.split(",").map((w) => Number(w))
      console.log("provided_weeks",provided_weeks)
      provided_weeks.forEach((w) => {
        console.log("w<current_week-1",w<current_week-1)
        if (w< 1 || w > 52 || w<current_week-1) {
          error_present=true
          return 
        }
      })
      if(!error_present){
        error_present=false
      let temp_data = cloneDeep(fullData)
      temp_data[packageName] = temp_data[packageName].map((val, i) =>
        provided_weeks.includes(i + 1) ? weeksInputValue.toUpperCase() : val,
      )
      dispatch(setSequencingTableIsUpdated(false))
      dispatch(setSequencingTableData(temp_data))
      dispatch(setDynamicCountOfValues(packageName))
      onCancel("weeks")
      }
    } else {
      error_present=true
    }
    setError(error_present)
  }
  const startInputChange = (e) => {
    let index = e.target.value
    setStartIndex(index)
  }
  const endInputChange = (e) => {
    let index = e.target.value
    setEndIndex(index)
  }
  const onValueChange = (e) => {
    let value = e.target.value
    setValue(value)
  }

  const changeSelection = (e, type) => {
    setSelectedType(type)
  }

  const weeksInputChange = (e) => {
    let value = e.target.value
    setWeeks(value)
  }

  const weeksValueInputChange = (e) => {
    let value = e.target.value
    setWeeksInputValue(value)
  }

  const onCancel = (type) => {
    setError(false)
    if(type=="range"){
    setStartIndex(null)
    setEndIndex(null)
    setValue("")
    }
    else if(type=="weeks"){
      setWeeks(null)
      setWeeksInputValue(null)
    }
    setDataFillPopUpOpen(false)
    setSelectedType("range")
  }
  return (
    <Modal
      className="data_fill"
      title={`Enter data for ${packageName}`}
      centered
      open={dataFillPopUpOpen}
      onOk={onSave}
      onCancel={onCancel}
      width={"50%"}
      height={200}
    >
      <div style={{display:"flex",justifyContent:"space-around"}}>
      <div className="radio-section">
        <Radio
          checked={selectedType == "range"}
          onChange={(e) => changeSelection(e, "range")}
        >
          Range Selection
        </Radio>
        <div className="week_set_container">
          <div className="input_container">
            Start week:{" "}
            <Input
              name="start"
              disabled={selectedType !== "range" && selectedType.length !== 0}
              style={{ width: "100px", display: "block" }}
              value={startIndex}
              onChange={(e) => startInputChange(e)}
            ></Input>
          </div>
          <div className="input_container">
            End week:{" "}
            <Input
              name="end"
              disabled={selectedType !== "range" && selectedType.length !== 0}
              style={{ width: "100px", display: "block" }}
              value={endIndex}
              onChange={(e) => endInputChange(e)}
            ></Input>
          </div>
        </div>
        <div className="input_container">
          Label:{" "}
          <Input
            style={{ width: "100px", display: "block" }}
            name="value"
            disabled={selectedType !== "range" && selectedType.length !== 0}
            value={value}
            onChange={(e) => {
              onValueChange(e)
            }}
          ></Input>
        </div>
      </div>
      <div className="divider"></div>
      <div className="radio-section">
        <Radio
          checked={selectedType == "weeks" && selectedType.length !== 0}
          onChange={(e) => changeSelection(e, "weeks")}
        >
          Week Selection
        </Radio>
        <div className="week_set_container">
          <div className="input_container">
            Weeks:{" "}
            <Input
              name="start"
              disabled={selectedType !== "weeks" && selectedType.length !== 0}
              style={{ width: "100px", display: "block" }}
              value={weeks}
              onChange={(e) => weeksInputChange(e)}
            ></Input>
          </div>
        </div>
        <div className="input_container">
          Label:{" "}
          <Input
            style={{ width: "100px", display: "block" }}
            name="value"
            value={weeksInputValue}
            disabled={selectedType !== "weeks" && selectedType.length !== 0}
            onChange={(e) => {
              weeksValueInputChange(e)
            }}
          ></Input>
        </div>
      </div>
      </div>
      {error && <p style={{ color: "#E81C0E",textAlign:"center",fontSize:"16px",fontWeight:"500" }}>Not a valid Input</p>}

     
    </Modal>
  )
}

export default DataFillPopUp
