import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"

const initialState = {
  admin: { data: [], status: "", error: null },
  users: { data: [], status: "", error: null },
  userPermissions: { data: [], status: "", error: null },
  years: { data: [], status: "", error: null },
}

export const getadminList = createAsyncThunk("getadminList", async (payload) =>
  callApi("api/v1/admin_dashboard/get_admin_list", payload),
)

export const getUserList = createAsyncThunk("getUserList", async (payload) =>
  callApi("api/v1/admin_dashboard/get_user_list", payload),
)

export const viewUserPermission = createAsyncThunk(
  "viewUserPermission",
  async (payload) =>
    callApi("api/v1/admin_dashboard/view_user_permissions", payload),
)

export const updateUserPermission = createAsyncThunk(
  "updateUserPermission",
  async (payload) =>
    callApi("api/v1/admin_dashboard/update_user_permissions", payload),
)

export const getYearList = createAsyncThunk("getYearList", async (payload) =>
  callApi("api/v1/scenario/get_year", payload),
)

const adminScreen = createSlice({
  name: "retailplan",
  initialState,
  reducers: {
    setUserPermission: (state, action) => {
      let permissions = state.userPermissions.data
      let checked = action.payload.checked
      let value= action.payload.value
      let channel = value.channel
      let customer=value.customer
      permissions[channel][customer] = checked == false ? "false" : "true"
      state.userPermissions.data = permissions
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getadminList.pending, (state) => {
        state.admin.status = "pending"
      })
      .addCase(getadminList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.admin.status = "fulfilled"
          let adminEmails = action.payload.data.data.map((v) => v.email)
          state.admin.data = adminEmails
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting admin list",
          })
        }
      })
      .addCase(getadminList.rejected, (state, action) => {
        state.admin.status = "rejected"
        state.admin.error = action.error.message
      })
      .addCase(getUserList.pending, (state) => {
        state.users.status = "pending"
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.users.status = "fulfilled"
          state.users.data = action.payload.data.data
          //   state.users["Market Unit"] = action.payload.data.data
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting customers",
          })
        }
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.users.status = "rejected"
        state.users.error = action.error.message
      })
      .addCase(viewUserPermission.pending, (state) => {
        state.userPermissions.status = "pending"
      })
      .addCase(viewUserPermission.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.userPermissions.status = "fulfilled"
          state.userPermissions.data = action.payload.data.data
          //state.dataQualitySelectedOptions.Year = keys[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting user permissions",
          })
        }
      })
      .addCase(viewUserPermission.rejected, (state, action) => {
        state.userPermissions.status = "rejected"
        state.userPermissions.error = action.error.message
      })
      .addCase(updateUserPermission.pending, (state) => {
        //state.tableData.status = "pending"
      })
      .addCase(updateUserPermission.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            confirmButtonColor: "#E21824",
            text: "Permissions Successfully Updated",
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while updating permissions",
          })
        }
      })
      .addCase(updateUserPermission.rejected, (state, action) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: "Error Occured while updating permissions",
        })
      })
      .addCase(getYearList.pending, (state) => {
        state.years.status = "pending"
      })
      .addCase(getYearList.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.years.status = "fulfilled"
          let keys = Object.keys(action.payload.data.data)
          state.years.data = keys[keys.length - 1]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting year list",
          })
        }
      })
      .addCase(getYearList.rejected, (state, action) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: "Error Occured while getting year list",
        })
      })
  },
})

export const { setUserPermission } = adminScreen.actions
export default adminScreen.reducer
