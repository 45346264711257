import React, { useState, useEffect } from "react"
import "./CardComponent.css"
import dnnsi from "../../assets/images/dnnsi.png"
import rate_needed from "../../assets/images/rate_needed.png"
import fy_new_target from "../../assets/images/fyi_new_target.png"
import planned_to_target_difference from "../../assets/images/planned_to_target_difference.png"
import predicted_volume from "../../assets/images/predicted_volume.png"
import predicted_volume_unit from "../../assets/images//predicted_volume.png"
import predicted_customer_margin from "../../assets/images/predicted_customer_margin.png"
import current_fy_volume from "../../assets/images/current_fy_volume.png"
import current_fy_volume_unit from "../../assets/images/current_fy_volume.png"
import current_fy_customer_margin from "./../../assets/images/current_fy_customer_margin.png"
import change_icon from "./../../assets/images/change_icon.png"
import change_percentage_icon from "./../../assets/images/change_percentage_icon.png"
import { useSelector, useDispatch } from "react-redux"
import { updateKpiData } from "../../reducers/summaryTableDataUpdate"
import { cloneDeep } from "lodash"
import { kpiDataToolTipTitles } from "../../helper/static"
import { Tooltip } from "antd"

const CardComponent = ({ headers, horizontal, data, version }) => {
  const dispatch = useDispatch()
  const accrualsData = useSelector(
    (state) => state.summarytable.accrualsData.data,
  )
  const DNNSIValueFromAccruals = accrualsData.find(
    (v) => v["Accruals"] == "DNNSI",
  )["New Pricing"]
  const [dnnsiValue, setDnssiValue] = useState(
    data["Current FY DNNSI(Enterable)"],
  )
  const labelImageMapping = {
    "current fy dnnsi": dnnsi,
    "current fy dnnsi(enterable)": dnnsi,
    "rate needed": rate_needed,
    "fy new target": fy_new_target,
    "new pricing dnnsi": dnnsi,
    "planned to target difference": planned_to_target_difference,
    "predicted volume": predicted_volume,
    "predicted volume(unit)": predicted_volume_unit,
    "predicted customer margin": predicted_customer_margin,
    "current fy volume": current_fy_volume,
    "current fy volume(unit)": current_fy_volume_unit,
    "current fy customer margin": current_fy_customer_margin,
    "change customer margin": change_icon,
    "% change volume": change_percentage_icon,
    "% change volume(unit)": change_percentage_icon,
  }
  const labelSumbolMapping = {
    "current fy dnnsi": "$",
    "rate needed": "$",
    "fy new target": "$",
    "planned to target difference": "$",
    "new pricing dnnsi": "$",
  }

  const isPositiveValue = (val) => {
    let num = Number(val)
    return Math.abs(num) == num
  }

  const headersHiddenWhenVersionIsCustomer = [
    "Current FY DNNSI",
    "Current FY DNNSI(Enterable)",
    "Rate Needed",
    "FY New Target",
    "Planned to Target Difference",
    "New Pricing DNNSI",
  ]

  const cardsToBeCalculated = [
    "Change customer margin",
    "% Change volume",
    "% Change volume(unit)",
  ]
  const cardsToBeCalculatedHeaders = {
    "Change customer margin": "Change",
    "% Change volume": "% Change",
    "% Change volume(unit)": "% Change",
  }
  const onInputChange = (event) => {
    let value = event.target.value
    let temp_data = cloneDeep(data)
    let fy_new_target = calculateNewFyNewTarget(value)
    let planned_target_difference =
      calculateNewPlannedTargetDifference(fy_new_target)
    temp_data["Current FY DNNSI(Enterable)"] = value
    temp_data["FY New Target"] = fy_new_target
    temp_data["Planned to Target Difference"] = planned_target_difference
    dispatch(updateKpiData(temp_data))
  }

  const calculateNewFyNewTarget = (val) => {
    let fy_value = (Number(val) + Number(data["Rate Needed"])).toFixed(2)
    return fy_value
  }

  const calculateNewPlannedTargetDifference = (val) => {
    let planned_value = (DNNSIValueFromAccruals - val).toFixed(2)
    return planned_value
  }

  const calculatedValues = {
    "Change customer margin":
      (
        Number(data["Predicted Customer Margin"].replace("%", "")) -
        Number(data["Current FY Customer Margin"].replace("%", ""))
      ).toFixed(1) + "%",
    "% Change volume":
      Number(
        ((data["Predicted Volume"] - data["Current FY Volume"]) /
          data["Current FY Volume"]) *
          100,
      ).toFixed(1) + "%",
    "% Change volume(unit)":
      Number(
        ((data["Predicted Volume(Unit)"] - data["Current FY Volume(Unit)"]) /
          data["Current FY Volume(Unit)"]) *
          100,
      ).toFixed(1) + "%",
  }

  useEffect(() => {
    setDnssiValue(data["Current FY DNNSI"])
  }, [data["Current FY DNNSI(Enterable)"]])

  return (
    <div
      className={`card-containers ${
        horizontal ? "horizontal_cards" : "vertical_cards"
      }`}
    >
      {(version === "Customer"
        ? headers.filter((h) => !headersHiddenWhenVersionIsCustomer.includes(h))
        : headers
      ).map((h, i) => (
        <div
          key={h + i}
          className={`card-container ${
            horizontal ? "box-border" : i % 3 == 0 ? "left" : "right"
          }`}
        >
          {h.length > 0 && (
            <Tooltip
              overlayStyle={{ fontSize: "11px" }}
              title={kpiDataToolTipTitles[h]}
            >
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignSelf: "flex-start",
                  alignItems: "center",
                  height: "40%",
                }}
              >
                {labelImageMapping[h.toLowerCase()] && (
                  <img
                    style={{ width: "17px", height: "17px" }}
                    alt="img"
                    src={labelImageMapping[h.toLowerCase()]}
                  ></img>
                )}

                <span style={{ overflowWrap: "anywhere" }}>
                  {cardsToBeCalculatedHeaders[h]
                    ? cardsToBeCalculatedHeaders[h]
                    : h}
                </span>
              </div>
            </Tooltip>
          )}
          {h !== "Current FY DNNSI(Enterable)" &&
            !cardsToBeCalculated.includes(h) && (
              <div
                style={{
                  color:
                    h === "Planned to Target Difference" &&
                    !isPositiveValue(data[h])
                      ? "#E81C0E"
                      : "",
                }}
                className={horizontal ? "horizontal_value" : "vertical_value"}
              >
                {labelSumbolMapping[h.toLowerCase()] &&
                isPositiveValue(
                  h == "New Pricing DNNSI" ? DNNSIValueFromAccruals : data[h],
                )
                  ? labelSumbolMapping[h.toLowerCase()]
                  : labelSumbolMapping[h.toLowerCase()]
                  ? "-" + labelSumbolMapping[h.toLowerCase()]
                  : ""}
                {Number(data[h])
                  ? // ? h.toLowerCase().includes("volume")
                    //   ? Math.abs(Number(data[h])).toLocaleString("en-US")
                    //   : Math.abs(data[h])
                    // : h.toLowerCase().includes("volume")
                    // ? Number(data[h]).toLocaleString("en-US")
                    // : h == "New Pricing DNNSI" ? DNNSIValueFromAccruals :data[h]
                    Math.abs(Number(data[h])).toLocaleString("en-US")
                  : h == "New Pricing DNNSI"
                  ? Math.abs(DNNSIValueFromAccruals)
                  : Number(data[h].replace("%", "")).toLocaleString("en-US", {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }) + "%"}
              </div>
            )}
          {h === "Current FY DNNSI(Enterable)" && (
            <input
              className="input_dnssi vertical_value"
              value={data["Current FY DNNSI(Enterable)"]}
              onChange={(e) => onInputChange(e)}
            ></input>
          )}
          {cardsToBeCalculated.includes(h) && (
            <div className="vertical_value">
              {cardsToBeCalculated.includes(h) &&
                calculatedValues[h].toLocaleString("en-US")}
            </div>
          )}
          {}
        </div>
      ))}
    </div>
  )
}

export default CardComponent
