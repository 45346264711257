import React, { useEffect, useState } from "react"
import "./SummaryTable.css"
import waterfall from "../../assets/images/waterfall.png"
import accruals from "../../assets/images/accural.png"
import checkbook from "../../assets/images/checkbook.png"
import { Tooltip } from "antd"
import DownloadIcon from "@mui/icons-material/Download"
import { cloneDeep } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import {
  updateWaterfall,
  updateAccruals,
} from "../../reducers/summaryTableDataUpdate"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

function SummaryTable({
  label,
  headers,
  editableCells,
  tableData,
  version,
  toolTipLabels,
  unit_per_case,
  promosChecked,
}) {
  const [data, setData] = useState(tableData)
  const [isExpanded, setIsExpanded] = useState(true)
  const planned_target_difference = useSelector(
    (state) => state.summarytable.kpiData.data,
  )["Planned to Target Difference"]
  const retailMetaPlan = useSelector(
    (state) => state.summarytable.retailPlanMetaPlan.data,
  )
  const dispatch = useDispatch()
  const legendMapping = [
    "P1_A",
    "P1_B",
    "P1_C",
    "P1_D",
    "P2_A",
    "P2_B",
    "P2_C",
    "P2_D",
    "Holiday",
    "Total",
  ]
  const backgroundColorsForLegends = {
    P1_A: "#27AF8D",
    P1_B: "#27AF8D",
    P1_C: "#27AF8D",
    P1_D: "#27AF8D",
    P2_A: "#494949",
    P2_B: "#494949",
    P2_C: "#494949",
    P2_D: "#494949",
  }
  const tableHeadingLabel = {
    accruals: "Accruals",
    checkbook: "Checkbook",
    accruals2: "Accruals",
    "pricing waterfall": "Pricing Waterfall",
  }

  const labelMapping = {
    "pricing waterfall": waterfall,
    accruals: accruals,
    accruals2: accruals,
    checkbook: checkbook,
  }
  const onChangeHandle = (event, i, h) => {
    let value = event.target.value
    let clonedData = cloneDeep(data)
    const regex = /^-?\d*\.?\d*$/
    clonedData[i][h] =
      value == "" ? 0 : regex.test(value) ? value.replace(/^0+(?=\d)/, "") : 0
    setData(clonedData)
  }

  const setExpandandCollapse = () => {
    let bool = isExpanded
    setIsExpanded(!bool)
  }
  useEffect(() => {
    if (label == "Pricing Waterfall") {
      dispatch(updateWaterfall(data))
    } else if (label == "Accruals") {
      dispatch(updateAccruals(data))
    }
  }, [data])

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
    var str = ""

    for (var i = 0; i < array.length; i++) {
      var line = ""
      for (var index in array[i]) {
        if (line !== "") line += ","

        line += array[i][index]
      }

      str += line + "\r\n"
    }
    console.log("str", str)
    return str
  }

  const checkIfDivisable = (value) => {
    let remainder = (value * 100) % unit_per_case
    return remainder == 0 ? true : false
  }

  const isPlannedDifferenceInNegative = () => {
    return (
      Number(Math.abs(planned_target_difference)) ===
      Number(planned_target_difference)
    )
  }
  function exportCSVFile(headers, fullData, fileTitle) {
    let arrayForCsv = []
    data.forEach((row) => {
      arrayForCsv.push(Object.values(row))
    })
    arrayForCsv.unshift(headers)
    console.log("arrayForCsv", arrayForCsv)
    var jsonObject = JSON.stringify(arrayForCsv)

    var csv = convertToCSV(jsonObject)

    var exportedFilenmae = fileTitle + ".csv" || "export.csv"

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      var link = document.createElement("a")
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilenmae)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const AccrualsConditionForChangeColumn = (row, h) => {
    if (
      row["Accruals"] == "DNNSI" &&
      h.toLowerCase() !== "% chg" &&
      h.toLowerCase() !== "change%"
    ) {
      return true
    }
    if (row["Accruals"] !== "DNNSI") {
      return true
    } else {
      false
    }
  }

  const conditionForCheckBookTable = (value, h) => {
    if (h !== "Checkbook") {
      return Math.round(value)
    }
    return (
      <Tooltip
        overlayStyle={{
          fontSize: "11px",
          whiteSpace: "pre-line",
          maxWidth: "450px",
        }}
        title={toolTipLabels[value]}
      >
        {value}
      </Tooltip>
    )
  }

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  return (
    <div
      style={{
        borderRadius: "4px",
        backgroundColor: "white",
        padding: "0 1vw 2vh 1vw",
        boxSizing: "border-box",
      }}
    >
      <div
        className="summary_table_container"
        style={{
          marginBottom: isExpanded ? "1vh" : "0px",
          marginTop: isExpanded ? "0.5vh" : "0px",
        }}
      >
        <img
          className="summary_table_header_image"
          src={labelMapping[label.toLowerCase()]}
          alt=""
        />
        {tableHeadingLabel[label.toLowerCase()]}
        <div style={{ marginLeft: "auto" }}>
          <Tooltip title="Download">
            <DownloadIcon
              onClick={() => exportCSVFile(headers, data, label)}
              style={{
                color: "#E81C0E",
                marginLeft: "auto",
                fontSize: "3.9vh",
              }}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
            {isExpanded ? (
              <ExpandLessIcon
                onClick={setExpandandCollapse}
                style={{
                  color: "#E81C0E",
                  marginLeft: "0.5vw",
                  fontSize: "3.9vh",
                }}
              ></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon
                onClick={setExpandandCollapse}
                style={{
                  color: "#E81C0E",
                  marginLeft: "0.5vw",
                  fontSize: "3.9vh",
                }}
              ></ExpandMoreIcon>
            )}
          </Tooltip>
        </div>
      </div>
      {isExpanded && (
        <div
          style={{
            overflow: "auto",
          }}
        >
          <table
            className="summary_table"
            style={{
              width:
                label.toLowerCase() === "checkbook"
                  ? "calc(100% - 8.2%)"
                  : "100%",
              height: "100%",
            }}
          >
            <thead>
              <tr>
                {headers.map((head, i) =>
                  (promosChecked
                    ? head == "Accrual Type" ||
                      head == "Checkbook" ||
                      head == "Total" ||
                      promosChecked[head]
                    : true) && (
                        <th
                          style={{
                            width:
                              (label.toLowerCase() === "accruals2" ||
                                label.toLowerCase() === "checkbook") &&
                              i === 0
                                ? "85px"
                                : (label.toLowerCase() === "accruals2" ||
                                    label.toLowerCase() === "checkbook") &&
                                  i !== 0
                                ? "45px"
                                : i === 0
                                ? "70px"
                                : "45px",
                          }}
                          key={head + i}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {legendMapping.includes(head.toUpperCase()) && (
                              <div
                                style={{
                                  width: "28px",
                                  height: "0.8vh",
                                  borderRadius: "2px",
                                  backgroundColor:
                                    backgroundColorsForLegends[
                                      head.toUpperCase()
                                    ],
                                }}
                              ></div>
                            )}
                            {head.includes("(")
                              ? head.replace(/\(/g, " (")
                              : head}
                          </div>
                        </th>
                      ),
                )}
                {label == "Accruals2" && (
                  <th style={{ width: "48px" }}>Guardrail</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <tr
                  style={{
                    backgroundColor:
                      i === data.length - 1 ? "#C2D5FE" : "white",
                    fontWeight: i == data.length - 1 ? "600" : "",
                    color: i === data.length - 1 ? "blue" : "",
                    borderBottom:
                      i === data.length - 1 ? "4px solid #9DB3FF" : "",
                  }}
                  key={i}
                >
                  {headers.map((h, j) =>
                    editableCells[h] && editableCells[h][i] ? (
                      (promosChecked
                        ? h == "Accrual Type" ||
                          h == "Checkbook" ||
                          h == "Total" ||
                          promosChecked[h]
                        : true) && <td
                        style={{
                          backgroundColor:
                            i === data.length - 1 ? "#9DB3FF" : "#F4F4F4",
                          width: "fit-content",
                        }}
                        key={i + j}
                      >
                        <input
                          className="input-field"
                          type="text"
                          style={{
                            backgroundColor: "white",
                            color:
                              label == "Pricing Waterfall" &&
                              row["Pricing Waterfall"] == "Invoice Price" &&
                              h == "New Pricing" &&
                              !checkIfDivisable(data[i][h])
                                ? "#E81C0E"
                                : "blue",
                            borderRadius: "5px",
                            fontWeight: i === data.length - 1 ? "600" : "400",
                          }}
                          value={data[i][h]}
                          onChange={(e) => onChangeHandle(e, i, h)}
                        ></input>
                      </td>
                    ) : (
                      (promosChecked
                        ? h == "Accrual Type" ||
                          h == "Checkbook" ||
                          h == "Total" ||
                          promosChecked[h]
                        : true) &&<td
                        style={{
                          color:
                            label == "Accruals" &&
                            row["Accruals"] == "DNNSI" &&
                            h == "New Pricing" &&
                            !isPlannedDifferenceInNegative()
                              ? "#E81C0E"
                              : i == data.length - 1
                              ? label == "Accruals2" &&
                                data[i][h] < retailMetaPlan.dnnsi_guardrail
                                ? "#E81C0E"
                                : "blue"
                              : "",
                        }}
                        key={i + j}
                      >
                        {(h.toLowerCase() === "change%" ||
                          h.toLowerCase() === "% chg") &&
                          (label == "Accruals" && row["Accruals"] == "DNNSI"
                            ? true
                            : label == "Accruals" && row["Accruals"] !== "DNNSI"
                            ? false
                            : true) && (
                            <div
                              style={{
                                width: "95%",
                                height: "4.5vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                  Number(row[h].replace("%", "")) >= 0
                                    ? "#27AF8D"
                                    : "#E81C0E",
                                borderRadius: "1.5vw",
                                color: "white",
                                margin: "auto",
                                fontWeight:
                                  i === data.length - 1 ? "600" : "400",
                              }}
                            >
                              {row[h]}
                            </div>
                          )}
                        {label == "Accruals"
                          ? AccrualsConditionForChangeColumn(row, h) && (
                              <Tooltip
                                overlayStyle={{
                                  fontSize: "11px",
                                  whiteSpace: "pre-line",
                                  maxWidth: "450px",
                                }}
                                title={
                                  toolTipLabels ? toolTipLabels[row[h]] : ""
                                }
                              >
                                {row[h]}
                              </Tooltip>
                            )
                          : label == "Checkbook"
                          ? conditionForCheckBookTable(row[h], h)
                          : // ? row["Accruals"] == "DNNSI"
                            //   ? h.toLowerCase() !== "% chg" && row[h]
                            //   : row[h]
                            h.toLowerCase() !== "change%" &&
                            h.toLowerCase() !== "% chg" &&
                            (h == "Accrual Type" || h == "Pricing Waterfall" ? (
                              <Tooltip
                                overlayStyle={{
                                  fontSize: "11px",
                                  whiteSpace: "pre-line",
                                  maxWidth: "450px",
                                }}
                                title={
                                  toolTipLabels ? toolTipLabels[row[h]] : ""
                                }
                              >
                                {row[h]}
                              </Tooltip>
                            ) : (
                              row[h]
                            ))}

                        {h.toLowerCase() !== "change%" &&
                          h.toLowerCase() !== "% chg" &&
                          j === 0 &&
                          "($)"}
                      </td>
                    ),
                  )}
                  {label == "Accruals2" && (
                    <td style={{ width: "48px" }}>
                      {i == data.length - 1
                        ? retailMetaPlan.dnnsi_guardrail
                        : ""}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
export default SummaryTable
