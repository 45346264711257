import React, { useEffect, useState } from "react"
import SideBar from "../../components/sidebar/SideBar.jsx"
import Header from "../../components/header/Header.jsx"
import { Pagination, Table, List, Collapse } from "antd"
import { useSelector, useDispatch } from "react-redux"
import "./DataQuality.css"
import MenuBox from "../../components/menubox/MenuBox.jsx"
import {
  getCustomers,
  getDQMarketUnits,
  getDQYearsList,
  getDataQualityData,
  getDQRegions,
} from "../../reducers/dataQuality.js"
import { cloneDeep } from "lodash"
import { dataQualitycolumns } from "../../helper/static.js"
import { Spin } from "antd"
import { getCookie } from "../../helper/getCookie.js"

const DataQuality = () => {
  const customersList = useSelector((state) => state.dataQuality.customers.data)
  const regions = useSelector((state) => state.dataQuality.regions.data)
  const marketUnits = useSelector(
    (state) => state.dataQuality.dQMarketUnits.data,
  )
  const years = useSelector((state) => state.dataQuality.years.data)
  // const available = useSelector((state) => state.dataQuality.available)
  const selectedOptions = useSelector(
    (state) => state.dataQuality.dataQualitySelectedOptions,
  )
  const tableData = useSelector((state) => state.dataQuality.tableData.data)
  const dispatch = useDispatch()
  const side_bar_open = useSelector((state) => state.sidebar.open)
  // const [currentPage, setCurrentPage] = useState(1)
  // const [startIndex, setStartIndex] = useState(0)
  // const [pageSize, setPageSize] = useState(25)
  // const [endIndex, setEndIndex] = useState(pageSize)
  const TableDataLoader = useSelector(
    (state) => state.dataQuality.tableData.status,
  )
  const loading = TableDataLoader !== "fulfilled"
  // const changePage = (page) => {
  //   setCurrentPage(page)
  //   let start = (page-1) * pageSize
  //   setStartIndex(start)
  //   let end = (page-1) * pageSize + pageSize
  //   setEndIndex(end)
  // }
  let payload = {}
  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (!selectedOptions.Year) {
      dispatch(getDQYearsList(temp_payload))
    }
  }, [])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (selectedOptions.Year) {
      temp_payload.year = selectedOptions.Year
      dispatch(getCustomers(temp_payload))
    }
  }, [selectedOptions.Year])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (selectedOptions.Year && selectedOptions.Customer) {
      temp_payload.year = selectedOptions.Year
      temp_payload.customer = selectedOptions.Customer
      dispatch(getDQRegions(temp_payload))
    }
  }, [customersList, selectedOptions.Customer])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (
      selectedOptions.Year &&
      selectedOptions.Customer &&
      selectedOptions.Region
    ) {
      temp_payload.year = selectedOptions.Year
      temp_payload.customer = selectedOptions.Customer
      temp_payload.region = selectedOptions.Region
      dispatch(getDQMarketUnits(temp_payload))
    }
  }, [regions, selectedOptions.Region])

  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (
      selectedOptions.Year &&
      selectedOptions.Customer &&
      selectedOptions.Region &&
      selectedOptions["Market Unit"]
    ) {
      temp_payload.year = selectedOptions.Year
      temp_payload.customer = selectedOptions.Customer
      temp_payload.region = selectedOptions.Region
      temp_payload.market_unit = selectedOptions["Market Unit"]
      // temp_payload.available = selectedOptions.Available
      dispatch(getDataQualityData(temp_payload))
      // setCurrentPage(1)
      // setStartIndex(0)
      // setEndIndex(pageSize)
    }
  }, [marketUnits, selectedOptions["Market Unit"], selectedOptions.Available])

  return (
    <div>
      <Header />
      <SideBar />
      <div
        className="data_quality"
        style={{
          width: side_bar_open ? "72%" : "93.5%",
          left: side_bar_open ? "12%" : "0",
          position: "relative",
          margin: "auto",
          paddingTop: "15vh",
          marginBottom: "5vh",
        }}
      >
        <div style={{ display: "flex", gap: "40px" }}>
          {customersList.length > 0 && (
            <MenuBox
              label="Customer"
              options={customersList}
              screen="Data Quality"
            ></MenuBox>
          )}
          {regions && Object.keys(regions).length > 0 && (
            <MenuBox
              label="Region"
              options={regions}
              screen="Data Quality"
            ></MenuBox>
          )}
          {marketUnits && marketUnits.length > 0 && (
            <MenuBox
              label="Market Unit"
              options={marketUnits}
              screen="Data Quality"
            ></MenuBox>
          )}
          {years && Object.keys(years).length > 0 && (
            <MenuBox
              label="Year"
              options={Object.keys(years)}
              years={years}
              screen="Data Quality"
            ></MenuBox>
          )}
          {/* <MenuBox
            label="Available"
            options={available}
            screen="Data Quality"
          ></MenuBox> */}
        </div>
        <Spin spinning={loading}>
          <div className="data_quality_card_container">
            <div className="data_quality_card">
              <div>Total Packages</div>
              <div className="card_content">{tableData.total_packages}</div>
            </div>
            <div className="data_quality_card">
              <div>Number of Packages Updated</div>
              <div className="card_content">{tableData.updated_packages}</div>
            </div>
            <div className="data_quality_card">
              <div>Number of Packages Not Updated</div>
              <div className="card_content not_updated">
                {tableData.not_updated_packages}
              </div>
            </div>
          </div>
          <div className="packages_container">
            <div className="packages_updated card">
              <div className="header">Packages updated</div>
              <div className="content">
                <List
                  size="small"
                  dataSource={tableData.updated_package_names}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                ></List>
              </div>
            </div>
            <div className="packages_not_updated card">
              <div className="header">Packages not updated</div>
              <div className="content">
                <Collapse size="small">
                  {tableData &&
                    tableData.not_updated_package_names &&
                    tableData.not_updated_package_names.map((item, index) => (
                      <Collapse.Panel header={item} key={index}>
                        <p>
                          <List
                            size="small"
                            dataSource={
                              tableData.packages_missing_columns[item]
                            }
                            renderItem={(it) => <List.Item>{it}</List.Item>}
                          ></List>
                          {/* {tableData.packages_missing_columns[item]} */}
                        </p>
                      </Collapse.Panel>
                    ))}
                </Collapse>
              </div>
            </div>
          </div>
        </Spin>
        {/* <Spin spinning={loading}>
          <Table
            className="data_quality_table"
            columns={dataQualitycolumns}
            dataSource={tableData.slice(startIndex, endIndex)}
            //   pagination={{ pageSize: 25 }}
            // scroll={{ y: "440px" }}
            pagination={false}
            current={currentPage}
            rowClassName={() => "custom-row"}
          ></Table>
          <Pagination
            total={tableData.length}
            onChange={changePage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            //   defaultPageSize={pageSize}
            pageSize={pageSize}
            showSizeChanger={false}
            defaultCurrent={1}
            showQuickJumper={true}
            current={currentPage}
            position="right"
          />
        </Spin> */}
      </div>
    </div>
  )
}

export default DataQuality
