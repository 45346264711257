import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../dataService"
import Swal from "sweetalert2"

const initialState = {
  category: { data: [], status: "", error: null },
  version: ["Promotion", "Volume", "RetailPrice"],
  timeFrame: [],
  sequencingSelectedOptions: {
    Category: null,
    Version: null,
    "Time Frame": null,
  },
}

export const getCategories = createAsyncThunk(
  "getCategories",
  async (payload) => callApi("api/v1/sequencing/get_catagories", payload),
)

const sequencingScreenDropDown = createSlice({
  name: "retailplan",
  initialState,
  reducers: {
    setTimeFrames: (state, action) => {
      let existing_time_frames = ["Full Year", "Q1", "Q2", "Q3", "Q4"]
      let new_periods = [...new Set(action.payload)]
      new_periods.unshift(...existing_time_frames)
      state.timeFrame = new_periods
      state.sequencingSelectedOptions["Time Frame"] = !state
        .sequencingSelectedOptions["Time Frame"]
        ? new_periods[0]
        : state.sequencingSelectedOptions["Time Frame"]
    },
    setSequencingSelectedOption: (state, action) => {
      state.sequencingSelectedOptions[action.payload.label] =
        action.payload.option
    },
    setSelectedVersionTimeFrame: (state) => {
      // state.sequencingSelectedOptions.Version = state.version[0]
      state.sequencingSelectedOptions["Time Frame"] = ["Full Year"]
    },

    setVersion: (state) => {
      state.version = ["Promotion", "Volume", "RetailPrice"]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.category.status = "pending"
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        let status = action.payload.data.status
        if (status == 200) {
          state.category.status = "fulfilled"
          state.category.data = action.payload.data.data.categories
          state.sequencingSelectedOptions.Category =
            action.payload.data.data.categories[0]
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured while getting categories",
          })
        }
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.category.status = "rejected"
        state.category.error = action.error.message
      })
  },
})

export const {
  setSequencingSelectedOption,
  setSelectedVersionTimeFrame,
  setTimeFrames,
  setVersion
} = sequencingScreenDropDown.actions
export default sequencingScreenDropDown.reducer
