import React, { useEffect, useState } from "react"
import { Modal, Input, Button, Checkbox, Select } from "antd"
import "./SaveScenarioModal.css"
import cancel_icon from "../../assets/images/cancel_icon.png"
import save_icon from "../../assets/images/save_as_new_icon.png"
import Swal from "sweetalert2"
import { callApi } from "../../dataService"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"
import { getCookie } from "../../helper/getCookie"
import { optimize_scenario } from "../../reducers/summaryTableDataUpdate"

const SaveScenarioModal = ({
  saveScenarioPayload,
  saveScenarioModalOpen,
  setSaveScenarioModalOpen,
  setReloadScenario,
  setCreateLoading,
  saveType,
  customers,
  regions,
  marketUnits,
  category,
  packages,
  scenarios,
  updateScenarioPayload,
}) => {
  const selectedOptionList = cloneDeep(
    useSelector((state) => state.summmaryScreenDropDown.selectedOptions),
  )
  const customersList = cloneDeep(
    customers.map((c) => {
      let obj = {}
      obj.value = c
      obj.label = c.replace(/_/g, " ").toUpperCase()
      return obj
    }),
  )

  const [scenarioList,setScenarioList]=useState(scenarios)

  const regionsList = cloneDeep(
    regions.map((c) => {
      let obj = {}
      obj.value = c
      obj.label = c.replace(/_/g, " ").toUpperCase()
      return obj
    }),
  )

  const categoryList = cloneDeep(
    category.map((c) => {
      let obj = {}
      obj.value = c
      obj.label = c.replace(/_/g, " ").toUpperCase()
      return obj
    }),
  )

  const marketUnitsList = cloneDeep(
    marketUnits.map((c) => {
      let obj = {}
      obj.value = c
      obj.label = c.replace(/_/g, " ").toUpperCase()
      return obj
    }),
  )

  const packagesList = cloneDeep(
    packages.map((c) => {
      let obj = {}
      obj.value = c
      obj.label = c.replace(/_/g, " ").toUpperCase()
      return obj
    }),
  )
  const [scenarioName, setScenarioName] = useState("")
  const [marketunitOptions, setMarketUnitOptions] = useState(marketUnitsList)
  const [categoryOptions, setCategoryOptions] = useState(categoryList)
  const [regionOptions, setRegionOptions] = useState(regionsList)
  const [packageOptions, setPackagesOptions] = useState(packagesList)
  const [selectedCustomer, setSelectedCustomer] = useState(customersList[0])
  const [selectedRegion, setSelectedRegion] = useState(customersList[0])
  const [selectedMarketUnit, setSelectedMarketUnit] = useState(
    marketunitOptions[0],
  )
  const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0])
  const [selectedPackage, setSelectedPackage] = useState(packageOptions[0])
  const [copyChecked, setCopyChecked] = useState(false)
  const [isDuplicateName, setIsDuplicateName] = useState(false)
  const [initial, setInitial] = useState(0)
  const dispatch = useDispatch()

  const onCustomerChange = (e) => {
    setSelectedCustomer(e)
    setInitial(1)
  }

  const onRegionChange = (e) => {
    setSelectedRegion(e)
    setInitial(1)
  }

  const onPackageChange = (e) => {
    setSelectedPackage(e)
  }

  const onCategoryChange = (e) => {
    setSelectedCategory(e)
    setInitial(1)
  }

  const onMarketUnitChange = (e) => {
    setSelectedMarketUnit(e)
    setInitial(1)
  }

  const checkboxToggle = (e) => {
    let checked = e.target.checked
    setCopyChecked(checked)
  }

  const onScenarioNameChange = (e) => {
    let value = e.target.value
    setScenarioName(value)
    console.log("scenarioName", scenarioName)
  }

  useEffect(() => {
    if (customers.length > 0 && saveScenarioModalOpen) {
      let temp_customers = cloneDeep(customersList)
      setSelectedCustomer(temp_customers[0].value)
    }
  }, [customers, saveScenarioModalOpen])

  useEffect(() => {
    if (regions.length > 0 && saveScenarioModalOpen) {
      let temp_regions = cloneDeep(regionsList)
      setSelectedRegion(temp_regions[0].value)
    }
  }, [regions, saveScenarioModalOpen])

  useEffect(() => {
    if (marketUnits.length > 0 && saveScenarioModalOpen) {
      let temp_market_units = cloneDeep(marketUnitsList)
      setMarketUnitOptions(temp_market_units)
      setSelectedMarketUnit(temp_market_units[0].value)
    }
  }, [marketUnits, saveScenarioModalOpen])

  useEffect(() => {
    if (category.length > 0 && saveScenarioModalOpen) {
      let temp_categoryList = cloneDeep(categoryList)
      setCategoryOptions(temp_categoryList)
      setSelectedCategory(temp_categoryList[0].value)
    }
  }, [category, saveScenarioModalOpen])

  useEffect(() => {
    if (packages.length > 0 && saveScenarioModalOpen) {
      let temp_packages = cloneDeep(packagesList)
      setPackagesOptions(temp_packages)
      setSelectedPackage(temp_packages[0].value)
    }
  }, [packages, saveScenarioModalOpen])

  useEffect(() => {
    if (scenarios.length > 0 && saveScenarioModalOpen) {
      let temp_scenarios = cloneDeep(scenarios)
      setScenarioList(temp_scenarios)
    }
  }, [scenarios, saveScenarioModalOpen])

  const closedModal = () => {
    setSaveScenarioModalOpen(false)
    setScenarioName("")
    setIsDuplicateName(false)
    setCopyChecked(false)
    setSelectedCustomer(null)
    setSelectedRegion(null)
    setSelectedMarketUnit(null)
    setSelectedCategory(null)
    setSelectedPackage(null)
    setInitial(0)
  }

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedOptionList.Year &&
      saveScenarioModalOpen &&
      initial !== 0
    ) {
      let temp_payload = {}
      temp_payload.year = selectedOptionList.Year
      temp_payload.customer = selectedCustomer
      callApi("api/v1/summary/get_customer_regions", temp_payload)
        .then((res) => {
          if (res.data.status == 200) {
            let options = res.data.data
            options = options.map((c) => {
              let obj = {}
              obj.value = c
              obj.label = c.replace(/_/g, " ").toUpperCase()
              return obj
            })
            setRegionOptions(options)
            setSelectedRegion(options[0].value)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
          console.log("error", err.message)
        })
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedRegion &&
      selectedOptionList.Year &&
      saveScenarioModalOpen &&
      initial !== 0
    ) {
      let temp_payload = {}
      temp_payload.year = selectedOptionList.Year
      temp_payload.customer = selectedCustomer
      temp_payload.region = selectedRegion
      callApi("api/v1/summary/get_market_units", temp_payload)
        .then((res) => {
          if (res.data.status == 200) {
            let options = res.data.data
            console.log("options=====>", options)
            options = options.map((c) => {
              let obj = {}
              obj.value = c
              obj.label = c.replace(/_/g, " ").toUpperCase()
              return obj
            })
            setMarketUnitOptions(options)
            setSelectedMarketUnit(options[0].value)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
        })
    }
  }, [regionOptions, selectedRegion])

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedRegion &&
      selectedOptionList.Year &&
      selectedMarketUnit &&
      saveScenarioModalOpen &&
      initial !== 0
    ) {
      let temp_payload = {}
      temp_payload.year = selectedOptionList.Year
      temp_payload.customer = selectedCustomer
      temp_payload.region = selectedRegion
      temp_payload.market_unit = selectedMarketUnit
      callApi("api/v1/summary/get_catagories", temp_payload)
        .then((res) => {
          if (res.data.status == 200) {
            let options = res.data.data.categories
            options = options.map((c) => {
              let obj = {}
              obj.value = c
              obj.label = c.replace(/_/g, " ").toUpperCase()
              return obj
            })
            setCategoryOptions(options)
            setSelectedCategory(options[0].value)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
          console.log("error", err.message)
        })
    }
  }, [marketunitOptions, selectedMarketUnit])

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedRegion &&
      selectedOptionList.Year &&
      selectedMarketUnit &&
      selectedCategory &&
      saveScenarioModalOpen &&
      initial !== 0
    ) {
      let temp_payload = {}
      temp_payload.year = selectedOptionList.Year
      temp_payload.customer = selectedCustomer
      temp_payload.region = selectedRegion
      temp_payload.market_unit = selectedMarketUnit
      temp_payload.category = selectedCategory
      callApi("api/v1/summary/get_packages", temp_payload)
        .then((res) => {
          if (res.data.status == 200) {
            let options = res.data.data
            options = options.map((c) => {
              let obj = {}
              obj.value = c
              obj.label = c.replace(/_/g, " ").toUpperCase()
              return obj
            })
            setPackagesOptions(options)
            setSelectedPackage(options[0].value)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
          console.log("error", err.message)
        })
    }
  }, [categoryOptions, selectedCategory])


  useEffect(() => {
    if (
      selectedCustomer &&
      selectedRegion &&
      selectedOptionList.Year &&
      selectedMarketUnit &&
      selectedCategory && 
      selectedPackage &&
      saveScenarioModalOpen &&
      initial !== 0
    ) {
      let temp_payload = {}
      temp_payload.year = selectedOptionList.Year
      temp_payload.customer = selectedCustomer
      temp_payload.region = selectedRegion
      temp_payload.market_unit = selectedMarketUnit
      temp_payload.category = selectedCategory
      temp_payload.package=selectedPackage
      callApi("api/v1/summary/get_scenario", temp_payload)
        .then((res) => {
          if (res.data.status == 200) {
            console.log("res---",res.data.data)
           setScenarioList(res.data.data)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              confirmButtonColor: "#E21824",
              text: "Error Occured!",
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "#E21824",
            text: "Error Occured!",
          })
          console.log("error", err.message)
        })
    }
  }, [packageOptions, selectedPackage])

  const save = () => {
    let scenariosInLowerCase = scenarioList.map((scenario) =>
      scenario.toLowerCase(),
    )
    if (!scenariosInLowerCase.includes(scenarioName.toLowerCase())) {
      setIsDuplicateName(false)
      if (scenarioName && scenarioName.length > 0) {
        let payload = saveScenarioPayload
        payload.new_scenario_name = scenarioName
        if (saveType === "save") {
          payload.is_copying = copyChecked ? "true" : "false"
          if (copyChecked) {
            payload.new_customer = selectedCustomer
              ? selectedCustomer
              : customersList[0].value
            payload.new_region = selectedRegion
              ? selectedRegion
              : regionsList[0].value
            payload.new_market_unit = selectedMarketUnit
              ? selectedMarketUnit
              : marketunitOptions[0].value
            payload.new_category = selectedCategory
              ? selectedCategory
              : categoryOptions[0].value
            payload.new_package = selectedPackage
              ? selectedPackage
              : packageOptions[0].value
          }
          setCreateLoading(true)
          callApi("api/v1/scenario/create_scenario", payload)
            .then((res) => {
              console.log("sucessfull updated", res)
              if (res.data.status == 200) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Scenario is successfully created",
                })
                setReloadScenario(true)
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  confirmButtonColor: "#E21824",
                  text: "Error Occured!",
                })
              }
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#E21824",
                text: "Error Occured!",
              })
            })
        } else if (saveType === "optimize") {
          callApi("api/v1/scenario/update_scenario", updateScenarioPayload)
            .then((res) => {
              console.log("sucessfull updated", res)
              if (res.data.status == 200) {
                dispatch(optimize_scenario(payload))
                //setReloadScenario(true)
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  confirmButtonColor: "#E21824",
                  text: "Error Occured!",
                })
              }
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#E21824",
                text: "Error Occured!",
              })
            })
        }
        setSaveScenarioModalOpen(false)
        setScenarioName("")
        setCopyChecked(false)
        setCreateLoading(false)
        setSelectedCustomer(null)
        setSelectedMarketUnit(null)
        setSelectedCategory(null)
        setSelectedPackage(null)
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#E21824",
          text: "Scenario name not valid",
        })
      }
    } else {
      setIsDuplicateName(true)
    }
  }

  return (
    <div className="save_scenario">
      <Modal
        className="save_scenario_modal"
        title={saveType == "save" ? "Save Scenario" : "Optimize Scenario"}
        open={saveScenarioModalOpen}
        onOk={() => {
          setSaveScenarioModalOpen(false)
        }}
        onCancel={() => {
          setSaveScenarioModalOpen(false)
          setInitial(0)
          setScenarioName("")
          setIsDuplicateName(false)
          setCopyChecked(false)
        }}
        footer={false}
      >
        <p>Please Enter The Scenario Name:</p>
        {isDuplicateName && <p className="duplicate_name_error">Scenario name already exists ,please enter unique scenario name</p>}
        <Input
          value={scenarioName}
          onChange={(e) => onScenarioNameChange(e)}
        ></Input>
        {saveType !== "optimize" && (
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              checked={copyChecked}
              onChange={(e) => checkboxToggle(e)}
            ></Checkbox>
            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              Copy to another
            </div>
            <Select
              disabled={!copyChecked}
              className="modal-select"
              showSearch={true}
              options={customersList}
              value={selectedCustomer ? selectedCustomer : customersList[0]}
              onChange={(e) => onCustomerChange(e)}
            ></Select>
            <Select
              disabled={!copyChecked}
              className="modal-select"
              showSearch={true}
              options={regionsList}
              onChange={(e) => onRegionChange(e)}
              value={selectedRegion ? selectedRegion : regionsList[0]}
            ></Select>
            <Select
              disabled={!copyChecked}
              className="modal-select"
              showSearch={true}
              options={marketunitOptions}
              onChange={(e) => onMarketUnitChange(e)}
              value={
                selectedMarketUnit ? selectedMarketUnit : marketUnitsList[0]
              }
            ></Select>
            <Select
              disabled={!copyChecked}
              className="modal-select"
              showSearch={true}
              options={categoryOptions}
              onChange={(e) => onCategoryChange(e)}
              value={selectedCategory ? selectedCategory : categoryList[0]}
            ></Select>
            <Select
              disabled={!copyChecked}
              className="modal-select"
              showSearch={true}
              options={packageOptions}
              onChange={(e) => onPackageChange(e)}
              value={selectedPackage ? selectedPackage : packagesList[0]}
            ></Select>
          </div>
        )}
        <div className="modal_button_container">
          <Button className="cancel" onClick={closedModal}>
            <img style={{ marginRight: "8px" }} src={cancel_icon}></img>Cancel
          </Button>
          <Button onClick={save}>
            <img style={{ marginRight: "8px" }} src={save_icon}></img>
            {saveType == "save" ? "Save" : "Optimize"}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default SaveScenarioModal
