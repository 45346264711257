import React, { useEffect, useState } from "react"
import { Modal, Button, Flex } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { viewUserPermission } from "../../reducers/adminScreen"
import { getCookie } from "../../helper/getCookie"
import { Checkbox, Spin } from "antd"
import "./AdminPanelModal.css"
import {
  setUserPermission,
  updateUserPermission,
} from "../../reducers/adminScreen"
import { clone, cloneDeep } from "lodash"
import { Collapse, List } from "antd"

const AdminPanelModal = ({
  selectedUser,
  isModalOpen,
  setIsModalOpen,
  year,
}) => {
  const dispatch = useDispatch()
  const userPermissions = useSelector(
    (state) => state.adminScreen.userPermissions.data,
  )

  const userPermissionsStatus = useSelector(
    (state) => state.adminScreen.userPermissions.status,
  )
  let payload = {
    year: year,
  }
  useEffect(() => {
    let temp_payload = cloneDeep(payload)
    if (selectedUser && year) {
      console.log("selectedUser", selectedUser)
      temp_payload.userpermissions = selectedUser.split("@")[0]
      dispatch(viewUserPermission(temp_payload))
    }
  }, [selectedUser])

  const getkeys = (obj) => {
    let keys = Object.keys(obj)
    return keys
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const checkOrUnCheckPermissions = (e, channel, customer) => {
    let checked = e.target.checked
    console.log("checked", checked)
    dispatch(
      setUserPermission({
        checked: checked,
        value: { channel: channel, customer: customer },
      }),
    )
  }

  const onUpdate = () => {
    let temp_payload = cloneDeep(payload)
    temp_payload.permissions = userPermissions
    temp_payload.year = year ? year : ""
    temp_payload.userpermissions = selectedUser.split("@")[0]
    dispatch(updateUserPermission(temp_payload))
    closeModal()
  }

  return (
    <Modal
      className="admin_panel_modal"
      title={`User Permissions of ${selectedUser}`}
      open={isModalOpen}
      onOk={() => {
        closeModal()
      }}
      onCancel={() => {
        closeModal()
      }}
      footer={false}
      style={{ width: "70%" }}
    >
      <Spin spinning={userPermissionsStatus !== "fulfilled"}>
        <div className="admin_modal">
          {getkeys(userPermissions).map((k) => (
            // <li>
            //   <Checkbox
            //     checked={userPermissions[k] == "true" ? true : false}
            //     onChange={(e) => checkOrUnCheckPermissions(e, k)}
            //   ></Checkbox>
            //   <div>{k}</div>
            // </li>
            <Collapse size="small">
              <Collapse.Panel header={k}>
                <List
                  size="small"
                  dataSource={Object.keys(userPermissions[k])}
                  renderItem={(it) => (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Checkbox
                        checked={
                          userPermissions[k][it] == "true" ? true : false
                        }
                        onChange={(e) => checkOrUnCheckPermissions(e, k, it)}
                      ></Checkbox>{" "}
                      <List.Item>{it}</List.Item>
                    </div>
                  )}
                ></List>
                {/* {tableData.packages_missing_columns[item]} */}
              </Collapse.Panel>
            </Collapse>
          ))}
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            style={{
              border: "none",
              backgroundColor: "#E81C0E",
              height: "30px",
              color: "white",
              borderRadius: "15px",
              marginLeft: "auto",
              marginTop: "10px",
            }}
            onClick={() => onUpdate()}
          >
            Update
          </Button>
        </div>
      </Spin>
    </Modal>
  )
}

export default AdminPanelModal
